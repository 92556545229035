import React, {Suspense} from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../../tools/DefaultLoader";

export default class PlanoAcaoRelatorioTabela extends React.Component {

    toogleSort = (field) => {
        if(this.props.sort.sortField === field && this.props.sort.sort) {
            if(!this.props.sort.sortAsc) {
                this.props.sortCallback({sort: false, sortAsc: true, sortField: ""});
                return;
            }
            this.props.sortCallback({sort: true, sortAsc: !this.props.sort.sortAsc, sortField: field});
        } else {
            this.props.sortCallback({sort: true, sortAsc: true, sortField: field});
        }
    }

    render() {
        const PlanoAcaoRelatorioLinhaTabela = React.lazy(() => import('./PlanoAcaoRelatorioLinhaTabela'));

        var bordersRows = {borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight:`1px solid ${Colors.homePage.lightGrey}`}

        if(this.props.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <DefaultLoader/>
                </div>
            )
        }

        if(!this.props.elements || this.props.elements.length == 0){
            return ( 
                <div style={{...EssentialStyle.columnCenter, height: "30vh", width: "100%", textAlign: "center"}}>
                    <div style={{fontSize: 16, color: Colors.homePage.darkGrey, fontWeight: 500, marginBottom: 10}}>
                        Nenhum item encontrado!
                    </div>
                </div>
            )
        }

        return (
            <div style={{...EssentialStyle.columnStart, maxHeight: "100%", flex: 1, borderRadius: 8, border: `1px solid ${Colors.homePage.lightGrey}`, borderCollapse: "collapse"}}>
                <div style={{...EssentialStyle.columnStart, maxHeight: "100%", flex: 1, fontSize: 14}}>
                    <table style={{ borderRadius: 8, tableLayout: "auto", position: "relative"}}>
                        <thead style={{ height: 30}}>
                            <tr style={{position: "sticky", zIndex: 3, top: 0, background: Colors.homePage.extraLightGrey}}>
                                <th 
                                    className={"sticky-column"} 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("nome")} 
                                    style={{ zIndex: 2, left: 0, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                    >
                                        <div> 
                                            Plano de Ação / Atividade
                                            {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "nome" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                        </div>
                                    </th>
                                <th 
                                    className={"sticky-column"}
                                    style={{ zIndex: 2, left: 300, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}
                                >
                                    Como?
                                </th>
                                <th 
                                    className={"sticky-column"}
                                    style={{ zIndex: 2, left: 600, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}
                                >
                                    Por quê?
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("tipo")} 
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Tipo
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "tipo" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}
                                >
                                    Vínculos
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("status")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Situação
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "status" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("data_inicio")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Início
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "data_inicio" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("data_fim")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Fim
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "data_fim" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("orcamento_previsto")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Orçado (R$)
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "orcamento_realizado" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("orcamento_realizado")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Realizado (R$)
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "orcamento_realizado" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th 
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("responsavel")}
                                    style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default"}}
                                >
                                    <div> 
                                        Responsável
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.props.sort.sortField == "responsavel" ? this.props.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{marginLeft: 5, color: Colors.homePage.darkGrey}}/>}
                                    </div>
                                </th>
                                <th style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}>Avaliador</th>
                                <th style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}>Participantes</th>
                                <th style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}>Prioridade</th>
                                <th style={{padding: "0px 5px", borderBottom: `1px solid ${Colors.homePage.lightGrey}`, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey}}>Comentários</th>
                            </tr>
                        </thead>
                        <Suspense key={"loader"} fallback={<tr style={{...bordersRows, backgroundColor: Colors.homePage.extraLightGrey, height: "35vh"}}><td colSpan={15}><DefaultLoader/></td></tr>}>
                            <tbody style={{backgroundColor: "unset", color: Colors.homePage.darkGrey, fontWeight: 500}}>
                                { this.props.elements && this.props.elements.length > 0 && this.props.elements.map((element, index) => {
                                    return (
                                        
                                            <PlanoAcaoRelatorioLinhaTabela key={element.id} item={element} type={this.props.view === 'etapas' ? 'etapa' : 'plano'} isSmallScreen={this.props.isSmallScreen} expanded/>
                                        
                                    )    
                                })}
                            </tbody>  
                        </Suspense>  
                    </table>
                </div>
            </div>  
        );
    }
}