import React from "react";
import DataHelper from "../../../../../helper/DataHelper";
import Sig from "../../../../../api/Sig";
import DefaultLoader from "../../../../tools/DefaultLoader";
import { toast } from "react-toastify";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { faTrash, faEdit, faTimes, faCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from "../../../../../constants/Colors";
import DefaultButton from "../../../../tools/DefaultButton";
import { ProgressBar } from 'react-bootstrap'
import MapaHelper from "../../../../../helper/pe/MapaHelper";
import CustomTooltip from "../../../../tools/CustomTooltip";

import './ProjetoIniciativaCard.css';
import EllipsisText from "../../../../tools/EllipsisText";



export default class ProjetoIniciativaCard extends React.Component {
    state = {
        loading: true,
        iniciativa: null,
        hoverOn: false,
        blockHoverOn: false,
        color: null,
        progress: 100,
        escala: null,
        starOn: false,

        editing: false,
        nameEditing: '',
    }

    async componentDidMount() {
        await this.loadItem();
    }

    async loadItem() {
        this.setState({ loading: true });

        let color = Colors.projeto.iniciativa.prioritization;
        let { iniciativa } = await Sig.request('POST', `pe/projeto/getIniciativa`, { id: this.props.id });

        if (iniciativa) {
            let starOn = iniciativa.favorito == "1" ? true : false;
            this.setState({ starOn, iniciativa, nameEditing: iniciativa.nome, color });
        } else {
            toast.error('Erro ao carregar iniciativa');
        }

        let { escala } = await Sig.request('GET', `pe/mapa/getMapa?id=${iniciativa.id_pe_mapa}`);
        let progress = await MapaHelper.getGutItemValue(iniciativa.id_pe_mapa, iniciativa.id);

        this.setState({ escala, progress, loading: false });
    }

    editIniciativaOn() {
        this.setState({ editing: true, nameEditing: this.state.iniciativa.nome, blockHoverOn: true });
    }

    cancelEdit() {
        this.setState({ editing: false, blockHoverOn: false, nameEditing: this.state.iniciativa.nome });
    }

    async confirmEdit() {
        this.setState({ loading: true });

        if (this.state.nameEditing.length !== this.state.iniciativa.nome.length) {
            let { iniciativa } = await Sig.request('POST', `pe/projeto/editNomeIniciativa`, { id: this.state.iniciativa.id, nome: this.state.nameEditing });

            if (iniciativa) {
                this.setState({ iniciativa, nameEditing: iniciativa.nome });
            } else {
                toast.error('Erro ao editar iniciativa');
            }
        }

        this.setState({ loading: false, editing: false, blockHoverOn: false });
    }

    async alterStar() {

        if (this.props.edit) {
            let { starOn } = this.state;
            let { iniciativa } = await Sig.request('POST', `pe/projeto/alterStarIniciativa`, { id: this.state.iniciativa.id, star: !starOn });

            if (iniciativa) {
                this.setState({ starOn: !starOn });
            } else {
                toast.error('Erro ao alterar iniciativa');
            }
        }

    }

    hover(status) {
        if (this.props.edit) {
            this.setState({ hoverOn: status });
        }
    }

    renderPrioritization() {

        return (
            <CustomTooltip style={{ height: '100%', width: '100%'}} tooltip={"Priorização"} placement={'top'}>
                <div style={{ height: '100%', width: '100%', backgroundColor: 'white', borderRadius: 5 }} >
                    <ProgressBar
                        min={0}
                        max={this.state.escala}
                        animated={this.state.loading}
                        now={this.state.progress}
                        label={this.state.progress}
                        style={{ height: '100%', borderRadius: 5 }}
                        variant="dark"
                    />
                </div>
            </CustomTooltip>
        )
    }

    renderStar() {
        let colorStar = this.state.starOn ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon
                icon={faStar}
                style={{
                    color: colorStar,
                    fontSize: '1.5em',
                    transition: 'color 0.15s ease-in-out' // Adiciona uma transição de cor
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    this.alterStar();
                }}
            />
        );
    }

    renderopcoes() {

        if (!this.state.editing) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, overflow: 'hidden' }}>
                    <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faTrash} size="2x" onClick={() => { this.props.delete(this.state.iniciativa.id); }} />
                    <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faEdit} size="2x" onClick={() => { this.editIniciativaOn() }} />
                </div>
            )
        } else {
            return (
                <div style={{ ...EssentialStyle.columnCenter }}>
                    <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faTimes} size="2x" onClick={() => { this.cancelEdit() }} />
                    <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faCheck} size="2x" onClick={() => { this.confirmEdit() }} />
                </div>
            )
        }
    }

    renderCard() {
        return (
            <div className={`iniciativaCardFundo`}
                onClick={() => { }}
                style={{
                    ...EssentialStyle.rowFlexEnd,
                    cursor: this.props.selecting ? 'pointer' : 'default',
                    ...(this.props.style ? this.props.style : {})
                }}
                onMouseOver={() => { this.hover(true) }}
                onMouseOut={() => { this.hover(false) }}
            >

                <div
                    style={{
                        transition: 'width 0.3s linear',
                        width: this.state.hoverOn || this.state.blockHoverOn ? '15%' : '0%'
                    }}
                >
                    {this.state.hoverOn || this.state.blockHoverOn ? this.renderopcoes() : null}
                </div>

                <div
                    className={`iniciativaCard ${this.props.selected == this.state.iniciativa.id ? 'iniciativaCardSelected' : 'iniciativaCardNotSelected'}`}
                    style={{
                        ...EssentialStyle.columnCenter,
                        width: (this.state.hoverOn || this.state.blockHoverOn) ? '85%' : '100%',
                        cursor: this.props.selecting ? 'pointer' : 'default',
                    }}
                    onClick={() => { this.props.selecting && this.props.selecting(this.props.selected == this.state.iniciativa.id, this.state.iniciativa.id) }}
                >

                    {this.props.type && <div style={{ height: '1.5em', width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'star' }} >
                        {this.props.type == 'star' ? this.renderStar() : null}
                    </div>}

                    <div style={{ height: `calc(100% - ${this.props.type ? '3em' : 0 })`, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15, width: '100%' }} >
                        {this.state.editing ?
                            <input
                                type="text"
                                maxLength={100}
                                value={this.state.nameEditing}
                                onChange={(e) => { this.setState({ nameEditing: e.target.value }) }}
                                style={{ width: '90%', height: '80%', fontSize: 15, textAlign: 'center', borderRadius: 5, border: 'none' }}
                            />
                            :
                            <span style={{ padding: '0px 5px', textAlign: 'center', height: '100%' }}>
                                {this.props.style && this.props.style.height ? 
                                    <EllipsisText text={this.state.iniciativa.nome} />
                                    :
                                    this.state.iniciativa.nome    
                                }
                            </span>
                        }
                    </div>

                    {this.props.type && <div style={{ height: '1.5em', width: '100%', display: 'flex', justifyContent: 'start', marginTop: 3 }} >
                        {this.props.type == 'prioritization' ? this.renderPrioritization() : null}
                    </div>}
                </div>

            </div>
        );
    }

    render() {

        if (this.state.loading) return <div className={'iniciativaCardFundo'} style={{ paddingTop: 20 }}><DefaultLoader /></div>
        return this.renderCard();
    }
}