import React from "react";

import './ChartBarMeta.css';
import CustomTooltip from "../CustomTooltip";

export default class ChartBarMeta extends React.Component {
    state = {
        
    }

    render() {

        let performance = this.props.performance ? this.props.performance : (
            this.props.realizado && this.props.meta ? (this.props.realizado / this.props.meta) * 100 : 0
        );
        let widthMeta = performance > 100 ? (100 / performance) * 100 : 100;
        let widthRealizado = performance < 100 ? performance : 100;

        return (
            <div key={`key-grafico-resultado-${this.props.key}`} style={{ width: '100%', display: 'flex', minHeight: this.props.minHeight, height: this.props.height, flexDirection: 'column', justifyContent: 'center' }}>
                <CustomTooltip placement={'bottom'} style={{width: '100%', height: "100%"}} tooltip={<span>{"Meta: " + this.props.metaValue} <br /> {"Realizado: " + this.props.realizadoValue}</span>}>
                    <div className="graphic-meta-realizado">
                        <div className="animateWidthMetaRealizado graphic-realizado" style={{ width: `${widthRealizado}%`, backgroundColor: this.props.color, }}/>
                        <div className="animateWidthMetaRealizado graphic-meta" style={{ width: `${widthMeta}%` }}/>
                        <div className="animateWidthMetaRealizado graphic-realizado" style={{ width: `${widthRealizado}%` }}>
                            <span className="realizado-text">{this.props.realizadoValue}</span>
                        </div>
                    </div>
                </CustomTooltip>
                <div className="animateWidthMetaRealizado" style={{ width: '100%', cursor: 'pointer' }}>
                    <div style={{ width: `calc(${widthMeta}% + 4px)`, display: 'flex', justifyContent: 'flex-end', fontSize: 11 }}>M</div>
                </div>
            </div>
        )
    }

}