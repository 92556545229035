import React from "react";

import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faChevronLeft, faTimesCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import Select from "react-select";
import EssentialStyle from "../../../../style/EssentialStyle";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";

import './CriacaoRelatorio.css';
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";
import IdiomaHelper from "../../../../helper/IdiomaHelper";



export default class CriacaoRelatorio extends React.Component {

    state = {
        loading: false,
        addingModelo: false,
        addingGrupoGrafico: false,
        editingModelo: false,

        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        modelos: [],
        idModelo: null,

        newModelo: {
            nome: '',
            graficos: [],
        },

        colunas: [],
        colunaSelected: null,
        nodes: [],
        nodeSelected: [],
        widthSelected: 3,

        lang: this.props.lang,
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        await this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, async () => {
                await this.loadData();
            });
        }

        if (prevProps.lang !== this.props.lang) {
            this.setState({ lang: this.props.lang });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    async loadData() {
        this.setState({ loading: true });

        await this.loadModelos();
        await this.loadColunas();

        this.setState({ loading: false });
    }

    async loadModelos() {
        let modelos = await DiagnosticoHelper.getModelosRelatorio(this.state.idPeriodoAvaliacao);
        this.setState({ modelos });
    }

    async loadColunas() {
        let colunas = await DiagnosticoHelper.getColunasQuestionario(this.state.idPeriodoAvaliacao);
        this.setState({ colunas });
    }

    async setNodeColuna(idColuna) {

        if (this.state.colunaSelected != idColuna) {

            let coluna = this.state.colunas.find((coluna) => coluna.id == idColuna);
            let nodesColuna = [];
            let nodeSelected = [];

            if (coluna.hasPai) {
                nodesColuna = await DiagnosticoHelper.getItensColunaPai(idColuna);
            }

            this.setState({ colunaSelected: idColuna, nodes: nodesColuna, nodeSelected });

        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    cancelEditModelo() {
        this.setState({ editingModelo: false, addingModelo: false, newModelo: { nome: '', graficos: [] } });
    }

    addGrupoGrafico() {
        let message = IdiomaHelper.getStruct({
            'pt': { textColuna: 'Selecione uma coluna', textLargura: 'Selecione a largura do gráfico', textItem: 'Selecione um item desta coluna' },
            'en': { textColuna: 'Select a column', textLargura: 'Select the chart width', textItem: 'Select an item from this column' },
            'es': { textColuna: 'Seleccione una columna', textLargura: 'Seleccione el ancho del gráfico', textItem: 'Seleccione un elemento de esta columna' },
        });

        if (!this.state.colunaSelected) {
            return toast.error(message[this.props.lang].textColuna);
        }

        if (!this.state.widthSelected) {
            return toast.error(message[this.props.lang].textLargura);
        }

        if (this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).hasPai && this.state.nodeSelected.length == 0) {
            return toast.error(message[this.props.lang].textItem);
        }

        let grupoGrafico = {
            id: this.state.newModelo.graficos.length + 1,
            largura: this.state.widthSelected,
            id_diagnostico_periodo_questionario_coluna: this.state.colunaSelected,
            node: this.state.nodeSelected,
            nodes: this.state.nodes,
            edit: true,
            isNew: true,
        }

        this.setState({ newModelo: { ...this.state.newModelo, graficos: [...this.state.newModelo.graficos, grupoGrafico] } }, () => {
            this.setState({ colunaSelected: null, nodes: [], nodeSelected: [], widthSelected: 1, addingGrupoGrafico: false });
        });
    }

    async seveEditModelo() {
        let message = IdiomaHelper.getStruct({
            'pt': { textNome: 'Preencha o nome deste Modelo', textCampos: 'Adicione pelo menos um Grupo de Gráficos' },
            'en': { textNome: 'Fill in the name of this Model', textCampos: 'Add at least one Chart Group' },
            'es': { textNome: 'Complete el nombre de este Modelo', textCampos: 'Agregue al menos un Grupo de Gráficos' },
        });

        if (!this.state.newModelo.nome) {
            return toast.error(message[this.props.lang].textNome);
        }

        if (this.state.newModelo.graficos.length == 0) {
            return toast.error(message[this.props.lang].textCampos);
        }

        let modelo = await DiagnosticoHelper.saveModeloRelatorio(this.state.newModelo, this.state.idPeriodoAvaliacao);

        this.setState({ addingModelo: false, newModelo: { nome: '', graficos: [] } }, async () => {
            await this.loadModelos();
            this.setState({ idModelo: modelo.id });
        });
    }

    async deleteModelo(idModelo) {
        await DiagnosticoHelper.deleteModeloRelatorio(idModelo);

        this.setState({ idModelo: null }, () => { this.loadModelos() });
    }

    async createRelatorio(idModelo) {
        let message = IdiomaHelper.getStruct({
            'pt': { textSuccess: 'Relatório criado com sucesso!', textError: 'Erro ao criar relatório!' },
            'en': { textSuccess: 'Report created successfully!', textError: 'Error creating report!' },
            'es': { textSuccess: 'Informe creado con éxito!', textError: '¡Error al crear informe!' },
        })

        let createRelatorio = await DiagnosticoHelper.createRelatorioPorModelo(idModelo);

        if (createRelatorio) {
            toast.success(message[this.props.lang].textSuccess);
        } else {
            toast.error(message[this.props.lang].textError);
        }
    }

    renderLoad() {

        return (
            <div className="criacaoRelatorio" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader />
            </div>
        )
    }

    renderBody() {

        return (
            <div className="criacaoRelatorio" style={{ boxShadow: Colors.boxShadow }}>
                <div className="top">
                    {this.renderButtomAddModelo()}
                    {this.renderListModels()}
                </div>
                <div className="bottom">
                    {this.renderModel()}
                </div>
            </div>
        )
    }

    renderButtomAddModelo() {
        let message = IdiomaHelper.getStruct({
            'pt': { title: 'Criar Modelo' },
            'en': { title: 'Create Model' },
            'es': { title: 'Crear Modelo' },
        });

        return (
            <DefaultButton
                style={{ height: 38 }}
                color={Colors.success}
                title={message[this.props.lang].title}
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                loading={this.state.loading}
                onClick={() => { this.setState({ addingModelo: !this.state.addingModelo, idModelo: null }) }}
            />
        )
    }

    renderListModels() {
        return (
            <div className="listaModelos">
                {this.state.modelos.map((modelo, index) => {
                    return (
                        <div
                            key={index}
                            className="modelo"
                            style={{ backgroundColor: this.state.idModelo == modelo.id ? SessionHelper.getColor() : '' }}
                            onClick={() => this.setState({ idModelo: modelo.id == this.state.idModelo ? null : modelo.id, addingModelo: false })}
                        >
                            <div className="title">{modelo.nome}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderModel() {

        const addNewModelo = this.state.addingModelo;
        const viewModelo = this.state.idModelo && !addNewModelo;
        const editingModelo = this.state.editingModelo && viewModelo;

        const renderGrupoGraficoInEditing = () => {
            let grupos = this.state.newModelo.graficos;

            let message = IdiomaHelper.getStruct({
                'pt': { textColuna: 'Coluna', textItens: 'Itens', textLargura: 'Largura', selectItens: 'Selecione um item', number: 'Iten(s) Selecionado(s)' },
                'en': { textColuna: 'Column', textItens: 'Items', textLargura: 'Width', selectItens: 'Select an item', number: 'Selected Item(s)' },
                'es': { textColuna: 'Columna', textItens: 'Elementos', textLargura: 'Ancho', selectItens: 'Seleccione un artículo', number: 'Elemento(s) seleccionado(s)' },
            });

            let array = [
                { id: 1, largura: '33%' },
                { id: 2, largura: '50%' },
                { id: 3, largura: '100%' },
            ];

            if (grupos && grupos.length > 0) return (
                <Form style={{ width: "calc(100% - 15px)" }} >
                    {grupos.map((grupo, index) => {
                        return (
                            <Row key={index} className="mb-3 graficoItem">
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>{message[this.props.lang].textColuna}</Form.Label>
                                        <div>{this.state.colunas.find((coluna) => coluna.id == grupo.id_diagnostico_periodo_questionario_coluna).descricao}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>{message[this.props.lang].textItens}</Form.Label>

                                        {grupo.nodes && grupo.nodes.length > 0 ?
                                            <div>{grupo.nodes.find((item) => item.id == grupo.node).descricao}</div>
                                            :
                                            <div>{message[this.props.lang].selectItens}</div>
                                        }

                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>{message[this.props.lang].textLargura}</Form.Label>
                                        <div>{array.find((item) => item.id == grupo.largura).largura}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={2} style={EssentialStyle.columnCenter}>
                                    <div style={EssentialStyle.columnCenter}>
                                        <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            style={{ cursor: 'pointer', marginLeft: 5, display: !grupo.edit ? 'none' : '' }}
                                            onClick={() => {
                                                let graficos = this.state.newModelo.graficos.filter((item) => item.id != grupo.id);
                                                this.setState({ newModelo: { ...this.state.newModelo, graficos } })
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                </Form>
            )
        }

        const renderAddGrupoGrafico = () => {

            let message = IdiomaHelper.getStruct({
                'pt': { title: 'Adicionar Grupo de Gráfico', back: 'Voltar', selectColuna: 'Selecione uma coluna', selectItens: 'Selecione um item', adicionar: 'Adicionar' },
                'en': { title: 'Add Chart Group', back: 'Back', selectColuna: 'Select a column', selectItens: 'Select an item', adicionar: 'Add' },
                'es': { title: 'Agregar Grupo de Gráfico', back: 'Volver', selectColuna: 'Seleccione una columna', selectItens: 'Seleccione un artículo', adicionar: 'Agregar' },
            });

            let coluna = null;
            if (this.state.colunaSelected) {
                coluna = this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected);
            }

            return (
                <div className="addGrupoGrafico">
                    <div className="titulo">{message[this.props.lang].title}</div>
                    <div className="content">
                        <DefaultButton
                            leftIcon={this.state.addingGrupoGrafico ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            tooltip={this.state.addingGrupoGrafico ? message[this.props.lang].back : message[this.props.lang].title}
                            color={Colors.success}
                            style={{ marginLeft: 5 }}
                            onClick={() => { this.setState({ addingGrupoGrafico: this.state.addingGrupoGrafico ? false : true }) }}
                        />

                        {console.log(this.state.colunas)}

                        {this.state.addingGrupoGrafico &&
                            <Select
                                options={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao')}
                                value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                onChange={(e) => { this.setNodeColuna(e.value) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: '10rem', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message[this.props.lang].selectColuna}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }

                        {console.log(this.state.nodes)}

                        {this.state.addingGrupoGrafico && coluna != null && coluna.hasPai &&
                            <div style={{ flexGrow: 1 }}>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.nodes, 'id', 'descricao')}
                                    value={DataHelper.formatSelectData(this.state.nodes, 'id', 'descricao').find((node) => node.value == this.state.nodeSelected) || []}
                                    onChange={(e) => { this.setState({ nodeSelected: e.value }) }}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            minWidth: '35rem', // Define a largura mínima
                                        }),
                                    }}
                                    isClearable
                                    menuPortalTarget={document.body}
                                    placeholder={message[this.props.lang].selectItens}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                            </div>
                        }

                        {this.state.addingGrupoGrafico && coluna != null && !coluna.hasPai &&
                            <div style={{ flexGrow: 1, ...EssentialStyle.columnCenter }}>
                                {message[this.props.lang].selectItens}
                            </div>
                        }

                        {this.state.addingGrupoGrafico && coluna == null &&
                            <div style={{ flexGrow: 1, ...EssentialStyle.columnCenter }} />
                        }

                        {this.state.addingGrupoGrafico && this.renderSelectWidth()}

                        {this.state.addingGrupoGrafico &&
                            <DefaultButton
                                title={message[this.props.lang].adicionar}
                                color={Colors.success}
                                onClick={() => { this.addGrupoGrafico() }}
                            />
                        }

                    </div>
                </div>
            )
        }

        const renderEditingModelo = (idModelo) => {
            let message = IdiomaHelper.getStruct({
                'pt': { title: 'Nome do Modelo', cancelar: 'Cancelar', salvar: 'Salvar' },
                'en': { title: 'Model Name', cancelar: 'Cancel', salvar: 'Save' },
                'es': { title: 'Nombre del Modelo', cancelar: 'Cancelar', salvar: 'Guardar' },
            });


            return (
                <div className="editModel">
                    <Form style={{ width: '100%' }} >
                        <Row className="mb-3">
                            <Form.Group md={12}>
                                <Form.Label>{message[this.props.lang].title}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={message[this.props.lang].title}
                                    value={this.state.newModelo.nome}
                                    onChange={(e) => this.setState({ newModelo: { ...this.state.newModelo, nome: e.target.value } })}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                    <div style={{ height: "calc(100% - 40px)", width: '100%', overflowY: 'auto' }}>
                        {renderGrupoGraficoInEditing()}
                        {renderAddGrupoGrafico()}
                    </div>

                    <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', gap: 5 }}>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faTimes} />}
                            title={message[this.props.lang].cancelar}
                            color={Colors.error}
                            onClick={() => this.cancelEditModelo()}
                        />
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            title={message[this.props.lang].salvar}
                            color={Colors.success}
                            onClick={() => this.seveEditModelo()}
                        />
                    </div>
                </div>
            )
        };

        const renderViewModelo = (idModelo) => {
            let textActions = IdiomaHelper.getStruct({
                'pt': { textEdit: 'Editar Modelo', textDelete: 'Excluir Modelo', textCriaRelatorio: 'Criar Relatório', textCriaRelatorioTooltip: 'Cria um relatório com base neste modelo. Os relatórios criados ficarão na aba "Em Elaboração" até serem concluídos.' },
                'en': { textEdit: 'Edit Model', textDelete: 'Delete Model', textCriaRelatorio: 'Create Report', textCriaRelatorioTooltip: 'Create a report based on this model. The reports created will be in the "In Elaboration" tab until they are completed.' },
                'es': { textEdit: 'Editar Modelo', textDelete: 'Eliminar Modelo', textCriaRelatorio: 'Crear Informe', textCriaRelatorioTooltip: 'Cree un informe basado en este modelo. Los informes creados estarán en la pestaña "En Elaboración" hasta que se completen.' },
            });

            let textLabels = IdiomaHelper.getStruct({
                'pt': { textName: 'Gráficos', textNameGrupo: 'Nome do Gráfio', textColuna: 'Coluna', textItens: 'Itens', textLargura: 'Largura', all: 'Todos Itens Selecionados', number: 'Iten(s) Selecionado(s)' },
                'en': { textName: 'Chart', textNameGrupo: 'Chart Name', textColuna: 'Column', textItens: 'Items', textLargura: 'Width', all: 'All Selected Items', number: 'Selected Item(s)' },
                'es': { textName: 'Gráficos', textNameGrupo: 'Nombre del Gráficos', textColuna: 'Columna', textItens: 'Elementos', textLargura: 'Ancho', all: 'Todos los elementos seleccionados', number: 'Elemento(s) seleccionado(s)' },
            });

            let array = [
                { id: 1, largura: '33%' },
                { id: 2, largura: '50%' },
                { id: 3, largura: '100%' },
            ]

            let modelo = this.state.modelos.find((item) => item.id == this.state.idModelo);
            let grupos = modelo.graficos;

            return (
                <div className="viewModel">
                    <Form style={{ height: "calc(100% - 40px)", width: '100%', padding: '0 15px', overflowY: 'auto' }}>
                        <Row className="mb-3 graficoItem">
                            <Form.Group md={12}>
                                <Form.Label>{textLabels[this.props.lang].textName}</Form.Label>
                                <div>{modelo.nome}</div>
                            </Form.Group>
                        </Row>

                        {
                            grupos.length > 0 &&
                            grupos.map((grupo, index) => {
                                return (
                                    <Row key={grupo.id} className="mb-3 graficoItem">
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{textLabels[this.props.lang].textColuna}</Form.Label>
                                                <div>{this.state.colunas.find((coluna) => coluna.id == grupo.id_diagnostico_periodo_questionario_coluna).descricao || ""}</div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{textLabels[this.props.lang].textItens}</Form.Label>
                                                <div>{grupo.descricaoItens.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.state.lang)).descricao}</div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>{textLabels[this.props.lang].textLargura}</Form.Label>
                                                <div>{array.find((item) => item.id == grupo.largura).largura}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Form>

                    <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', gap: 5 }}>
                        <DefaultButton
                            title={textActions[this.props.lang].textCriaRelatorio}
                            tooltip={textActions[this.props.lang].textCriaRelatorioTooltip}
                            color={Colors.danger}
                            onClick={() => { this.createRelatorio(idModelo) }}
                        />
                        <DefaultButton
                            title={textActions[this.props.lang].textDelete}
                            color={Colors.error}
                            onClick={() => { this.deleteModelo(idModelo) }}
                        />
                    </div>
                </div>
            )
        };

        if (addNewModelo) return renderEditingModelo(this.state.idModelo);
        if (viewModelo && !editingModelo) return renderViewModelo(this.state.idModelo);

        return DiagnosticoHelper.renderNotSelected(
            IdiomaHelper.getStruct({
                'pt': 'Selecione ou Crie um Modelo de Relatório para Continuar.',
                'en': 'Select or Create a Report Model to Continue.',
                'es': 'Seleccione o Cree un Modelo de Informe para Continuar.',
            }),
            this.state.lang);
    }

    renderSelectWidth() {
        let array = [
            { id: 1, largura: '33%' },
            { id: 2, largura: '50%' },
            { id: 3, largura: '100%' },
        ]

        let tooltip = IdiomaHelper.getStruct({
            'pt': 'Largura do Gráfico',
            'en': 'Chart Width',
            'es': 'Ancho del gráfico',
        })

        return (
            <CustomTooltip
                tooltip={tooltip[this.state.lang].text}
                placement="top"
            >
                <div className="selectWidth">
                    {array.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className={`itemWidth ${item.id <= this.state.hoverItemWidth ? 'itemWidth-hover' : ''} ${item.id <= this.state.widthSelected ? 'itemWidth-selected' : ''}`}
                                style={{ backgroundColor: item.id <= this.state.widthSelected ? SessionHelper.getColor() : '' }}
                                onClick={() => this.setState({ widthSelected: item.id })}
                                onMouseEnter={() => { this.setState({ hoverItemWidth: item.id }) }}
                                onMouseLeave={() => { this.setState({ hoverItemWidth: null }) }}
                            >
                                {this.state.widthSelected == item.id && item.largura}
                            </div>
                        )
                    })}
                </div>
            </CustomTooltip>
        )
    }

    render() {
        return this.state.loading ? this.renderLoad() : this.renderBody();
    }
}