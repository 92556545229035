import Sig from "../../api/Sig";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";
import Constants from "../../constants/Api";
import IdiomaHelper from "../IdiomaHelper";
const gateway = Constants.getSigEndPoint();


export default class DiagnosticoHelper {

    // Funções GET

    static getResultadoGrafico = async (idGrafico) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/getResultadoGrafico", { id: idGrafico });
        return response;
    }

    static getPeriodosAvaliacao = async (idProcesso) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getPeriodosAvaliacao", { idProcesso, relations: 1 });
        return response.periodos;
    }
    
    static isEmpresaAvaliadora = async (idProcesso) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getIsEmpresaAvaliadora", { id: idProcesso });
        return response.isEmpresaAvaliadora;
    }

    static getModelosRelatorio = async (idPeriodoAvaliacao, lang = IdiomaHelper.getIdioma()) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getModelosRelatorio", { idPeriodoAvaliacao, lang: IdiomaHelper.getSiglaId(lang) });
        return response.modelos;
    }

    static getColunasQuestionario = async (idPeriodoAvaliacao, lang = IdiomaHelper.getIdioma()) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getColunasQuestionario", { idPeriodoAvaliacao, lang: IdiomaHelper.getSiglaId(lang) });
        return response.colunas;
    }

    static getItensColuna = async (idColuna, lang = IdiomaHelper.getIdioma()) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getItensColuna", { idColuna, lang: IdiomaHelper.getSiglaId(lang) });
        return response.itens;
    }

    static getItensColunaPai = async (idColuna, lang = IdiomaHelper.getIdioma()) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getItensColunaPai", { idColuna, lang: IdiomaHelper.getSiglaId(lang) });
        return response.itens;
    }

    static getRelatorios = async (idPeriodoAvaliacao, status = 1, avaliador, avaliado, relations = 1, lang = 1) => {
        let response = await Sig.request("GET", "/diagnostico/processo/getRelatorios", { idPeriodoAvaliacao, status, avaliador, avaliado, relations, lang: IdiomaHelper.getSiglaId(lang) });
        return response.relatorios;
    }

    static getRelatorio = async (idRelatorio, relations = 1, lang = IdiomaHelper.getIdioma()) => {
        let response = await Sig.request("GET", "/diagnostico/processo/getRelatorio", { idRelatorio, relations, lang: IdiomaHelper.getSiglaId(lang) });
        return response.relatorio;
    }

    static getNumRelatorios = async (idPeriodoAvaliacao, avaliador, avaliado, relations = 1, lang = IdiomaHelper.getIdioma()) => {
        let response = await Sig.request("GET", "/diagnostico/processo/getNumRelatorios", { idPeriodoAvaliacao, avaliador, avaliado, relations, lang: IdiomaHelper.getSiglaId(lang) });
        return response;
    }

    static getHeatmapData = async (idPeriodoAvaliacao, idColuna, lang = IdiomaHelper.getIdioma()) => {
        let response = await Sig.request("GET", "/diagnostico/resultado/getHeatmapData", { idPeriodoAvaliacao, idColuna, lang: IdiomaHelper.getSiglaId(lang) });
        return response;
    }

    static getColorsPeriodoAvaliacao = async (idPeriodoAvaliacao) => {
        let response = await Sig.request("GET", "/diagnostico/processo/getColorsPeriodoAvaliacao", { idPeriodoAvaliacao });
        return response.colors;
    }

    // Funções POST

    static publishRelatorio = async (idRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/processo/publishRelatorio", { id: idRelatorio });
        return response;
    }

    static saveModeloRelatorio = async (modelo, idPeriodoAvaliacao) => {
        const response = await Sig.request("POST", "/diagnostico/processo/saveModeloRelatorio", { modelo: JSON.stringify(modelo), idPeriodoAvaliacao });
        return response.modelo;
    }

    static saveRecomendacao = async (recomendacao) => {
        recomendacao = JSON.stringify(recomendacao);
        const response = await Sig.request("POST", "/diagnostico/processo/saveRecomendacao", { recomendacao });
        return response.recomendacao;
    }

    static deleteModeloRelatorio = async (idModeloRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/processo/deleteModeloRelatorio", { id: idModeloRelatorio });
        return response;
    }

    static deleteRecomendacao = async (idRecomendacao) => {
        const response = await Sig.request("POST", "/diagnostico/processo/deleteRecomendacao", { id: idRecomendacao });
        return response;
    }

    static deleteRelatorio = async (idRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/processo/deleteRelatorio", { id: idRelatorio });
        return response.relatorioExcluido;
    }

    static createRelatorioPorModelo = async (idModeloRelatorio) => {
        let response = await Sig.request("POST", "/diagnostico/processo/createRelatorioPorModelo", { id: idModeloRelatorio });
        return response.mensagem == 'true';
    }

    // Funções de Utilidade

    static getColor = (colors, media) => {
        let color = colors.find(c => c.from <= media && c.to >= media);
        return color ? color.color : 'transparent';
    }

    static renderPeriodoNotSelected = (lang) => {
        let message = IdiomaHelper.getStruct({
            'pt': 'Selecione um Período de Avaliação para Continuar.',
            'en': 'Select an Evaluation Period to Continue.',
            'es': 'Seleccione un Período de Evaluación para Continuar.',
        });

        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: 'calc(100vh - 59px)',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_select.svg`}
                    alt="Uma mulher olhando para um campo de seleção."
                    style={{
                        height: '15rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {message[lang]}
                </span>
            </div>
        );
    }

    static renderNotSelected = (message, lang) => {
        
        if (!message) {
            message = IdiomaHelper.getStruct({
                'pt': 'Selecione para Continuar.',
                'en': 'Select to Continue.',
                'es': 'Seleccione para Continuar.',
            });
        }
        
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_selected_box.svg`}
                    alt="Uma mulher olhando para um campo de seleção."
                    style={{
                        height: '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {message[lang]}
                </span>
            </div>
        );
    }

    static renderEmpty = (text, height = null) => {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_void.svg`}
                    alt="Uma mulher olhando para um campo de seleção."
                    style={{
                        height: height ? height : '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {text}
                </span>
            </div>
        );
    }
}