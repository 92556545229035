import { faChevronDown, faChevronRight, faSitemap, faUnlink, faT, faLeftRight, faUpDown, faSquarePlus, faCropSimple, faAward, faBolt, faBook, faBookmark, faBriefcase, faCamera, faChartSimple, faCheck, faCircleDown, faCircleUp, faCrosshairs, faCrown, faDollarSign, faEarthAmericas, faFlag, faFont, faGift, faHandshake, faHeart, faHistory, faHourglass, faIcons, faLightbulb, faPen, faPlus, faMinus, faRocket, faStar, faTableCells, faThumbsDown, faThumbsUp, faTimes, faTrash, faUsers, faCog, faEye, faArrowLeft, faAd, faAdd, faInfoCircle, faLink, faArrowsAlt, faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Colors from "../../constants/Colors";
import { confirmAlert } from "react-confirm-alert";
import SessionHelper from '../../helper/SessionHelper';
import Draggable from 'react-draggable';
import LoadingPage from "../../pages/LoadingPage";
import DataHelper from "../../helper/DataHelper";
import DefaultButton from './DefaultButton';
import { toast } from "react-toastify";
import domtoimage from 'dom-to-image';
import VisaoHistory from "../modules/pe/visao/VisaoHistory";
import EditVisao from "../forms/pe/edit/EditVisao";
import EditMapa from "../forms/pe/edit/EditMapa";
import TextEditor from './TextEditor/TextEditor';
import Select from 'react-select'
import { Form, Spinner, Collapse } from "react-bootstrap";
import MapaHistory from "../modules/pe/mapa/MapaHistory";
import { TwitterPicker } from 'react-color'
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import ObjetivoEstrategicoMeta from "../modules/pe/mapa/ObjetivoEstrategicoMeta";
import MapaTableView from "../modules/pe/mapa/MapaTableView";
import MapaList from "../forms/pe/list/MapaList";
import Sig from "../../api/Sig";
import DiagramHelper from "../../helper/pe/DiagramHelper";
import IndicadoresObjetivo from "../modules/pe/mapa/IndicadoresObjetivo";
import CustomTooltip from "./CustomTooltip";
import EssentialStyle from "../../style/EssentialStyle";
import MapaIndicadorSelect from "../modules/pe/mapa/MapaIndicadorSelect/MapaIndicadorSelect";

const Icons = DiagramHelper.getIcons();
const colorPickerColors = DiagramHelper.getColorPickerColors();

function withArrowDragHook(Component) {
    return function WrappedComponent(props) {
        const hook = useXarrow();
        return <Component {...props} useXarrow={hook} />;
    }
}
class DrawDiagram extends React.Component {
    state = {
        visao: {},
        elements: [],
        objects: [],
        perspectivas: [],
        editing: null,
        editData: {},
        loadingDiagram: true,
        loading: false,
        isScrolling: false,
        top: 0,
        left: 0,
        x: 0,
        y: 0,
        isFavorite: false,
        showHistory: false,
        print: false,
        width: 0,
        height: 0,
        encodedImage: '',
        stopAddingListeners: false,
        showSettings: false,
        mode: this.props.modeView ? this.props.modeView : 'read-only',
        zoom: 100,
        isDragging: false,
        isResizing: false,
        isInteractingWithElement: false,
        swots: [],
        colabs: [],
        indicadores: [],
        view: 'normal',
        showMapas: false,
        loadingEditViewProp: false,
        indicadorPaineis: [],
        indicadoresPainel: [],
        podeFavoritar: false,
        podeFavoritarObjetivo: false,
        podeRemover: false,
        podeConfigurar: false,
        podeEditar: false,
        windowWidth: window.innerWidth,
        resizingCanvas: false,
        loadingResizingWidth: false,
        loadingResizingHeight: false,
        loadingSize: true,
        viewDetailsIndicador: null,
        detailsIndicador: [],
        expandIndicadores: false,
        loadingDetailsIndicador: false
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        await this.load();
    }

    async componentDidUpdate() {
        if (this.canvas && !this.state.stopAddingListeners) {
            this.canvas.addEventListener('wheel', this.handleWheelZoom);
            this.setState({ stopAddingListeners: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.canvas) {
            this.canvas.removeEventListener('wheel', this.handleWheelZoom);
        }
    }

    handleResize = () => {
        const newWindowWidth = window.innerWidth;
        const { windowWidth } = this.state;

        if (Math.abs(newWindowWidth - windowWidth) > 20) {
            this.setCanvasSize();
        }

        this.setState({ windowWidth: newWindowWidth });
    };

    async load(loading = false, loadIndicadores = true) {

        if (loading) { this.setState({ loadingDiagram: true }) };

        let isFavorite = false;
        let visao = false;

        let podeFavoritar = false;
        let podeFavoritarObjetivo = false;
        let podeRemover = false;
        let podeConfigurar = false;
        let podeEditar = false;

        if (this.props.table === 'PeMapa') {
            let request = await Sig.request('GET', 'pe/mapa/getMapa', { id: this.props.id });
            isFavorite = request?.mapa?.favorito == "1" || false;

            podeFavoritar = request?.podeFavoritarMapa;
            podeFavoritarObjetivo = request?.podeFavoritarObjetivo;
            podeRemover = request?.podeRemoverMapa;
            podeConfigurar = request?.podeConfigurarMapa;

            let { width, height } = request?.mapa;

            width = parseInt(width);
            height = parseInt(height);

            this.setState({ width, height });
        }

        if (this.props.table === 'PeVisao') {
            let request = await Sig.request('POST', 'pe/visao/getVisao', { id: this.props.id });
            visao = request?.visao;
            isFavorite = visao?.favorito == "1" || false;

            podeFavoritar = request?.podeFavoritarVisao;
            podeRemover = request?.podeRemoverVisao;
            podeConfigurar = request?.podeConfigurarVisao;
            podeEditar = request?.podeEditarVisao;
        }

        await this.getElements();
        await this.getTable();

        if (this.props.table === 'PeMapa') {
            await this.getPerspectivas();
            await this.getSwots();
            await this.getColabs();

            if (loadIndicadores) {
                await this.getIndicadores();
            }

            await this.getVinculos();
        }

        await this.getElementos(() => { if (loadIndicadores) this.setCanvasSize(); });

        if (this.state.visao.template) this.getAlterImage();

        this.setState({
            isFavorite,
            loading: false,
            loadingDiagram: false,
            podeFavoritar,
            podeFavoritarObjetivo,
            podeRemover,
            podeConfigurar,
            podeEditar,
        });
    }

    areEqual(value1, value2) {
        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (value1.length !== value2.length) return false;
            for (let i = 0; i < value1.length; i++) {
                if (value1[i] !== value2[i]) return false;
            }
            return true;
        } else {
            return value1 === value2;
        }
    }

    setCanvasSize = async () => {
        this.setState({ loadingSize: true }, async () => {
            if (this.state.visao.template) {

                await this.getImage();

            } else {
                let request = await Sig.request('GET', 'pe/mapa/getMapa', { id: this.props.id });

                let { width, height } = request?.mapa;
                width = parseInt(width);
                height = parseInt(height);

                let contentSize = this.getPrintSize();

                if (this.props.mode === 'read-only') {
                    width = contentSize.width + DiagramHelper.scaleSizeByPercent(200, this.state.zoom);
                    height = contentSize.height + DiagramHelper.scaleSizeByPercent(100, this.state.zoom);
                }

                this.setState({ width, height }, () => { this.setInitialZoom(contentSize.width, contentSize.height); });
            }

            this.setState({ loadingSize: false })
        });
    }

    setInitialZoom = async (w, h, considerEditingInterface) => {
        let { width, height, zoom } = DiagramHelper.getScaledProportions(w, h, considerEditingInterface);
        this.setState({ zoom });
    }

    async getSwots() {
        let swots = (await Sig.request('GET', `pe/swot/getSwots`, { relations: 1 }))?.swots || [];

        if (swots && swots.length) {
            let sanitizedSwots = [];

            for (let s of swots) {
                let swot = s.swot;

                let items = [];

                for (let quadrante of s.quadrantes) {
                    let qTitle = quadrante.quadrante.nome;

                    for (let item of quadrante.itens) {
                        items.push({ value: item.id, label: `${qTitle} - ${item.descricao}`, id_pe_swot: swot.id });
                    }
                }

                swot.items = items;

                sanitizedSwots.push(swot);
            }

            this.setState({ swots: sanitizedSwots });
        }
    }

    async getVinculos() {
        let vinculos = (await Sig.request('POST', `pe/diagrama/getElementoVinculos`, { id: this.props.id, campoVinculo: this.props.relationField, model: `${this.props.elementsTable}Vinculo` }))?.vinculos || [];

        this.setState({ vinculos });
    }

    async getColabs() {
        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];

        if (colabs && colabs.length) {

            this.setState({ colabs });
        }
    }

    async getElements() {

        let elements = [];

        if (this.props.table === 'PeVisao') {

            elements = [{ label: 'Adicionar Novo Texto', key: 'text', icon: faT }, { label: 'Adicionar Novo Ícone', key: 'icon', icon: faIcons }];

        } else if (this.props.table === 'PeMapa') {

            elements = [{ label: 'Adicionar Perspectiva', key: 'perspectiva', icon: faSquarePlus }, { label: 'Adicionar Novo Texto', key: 'text', icon: faT }, { label: 'Adicionar Novo Ícone', key: 'icon', icon: faIcons }];
        }

        this.setState({ elements });
    }

    async getAlterImage() {
        let blob = await Sig.request('POST', `pe/diagrama/getBackground`, { id: this.props.id, model: this.props.table }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            var base64data = reader.result;
            this.setState({ encodedImage: base64data }, () => { this.setState({ loadingDiagram: false }) });
        }
    }

    async getImage() {
        let blob = await Sig.request('POST', `pe/diagrama/getBackground`, { id: this.props.id, model: this.props.table }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            var base64data = reader.result;

            await this.getImageSize(blob);

            this.setState({ encodedImage: base64data }, () => { this.setState({ loadingDiagram: false }) });
        }
    }

    async getImageSize(blob) {

        this.setState({ width: parseInt(blob.headers['x-image-width']), height: parseInt(blob.headers['x-image-height']) }, () => { this.setInitialZoom(blob.headers['x-image-width'], blob.headers['x-image-height']); });
    }

    async getTable() {
        let { tabela } = await Sig.request('POST', `pe/diagrama/getTabelaBase`, { id: this.props.id, model: this.props.table });

        if (tabela && tabela.length) {
            this.setState({ visao: tabela[0] });
        }
    }

    async getPerspectivas() {
        let perspectivas = (await Sig.request('POST', `pe/mapa/getPerspectivas`, { id: this.props.id }))?.perspectivas || [];

        if (perspectivas && perspectivas.length) {
            this.setState({ perspectivas });
        }
    }

    async getElementos(callback) {
        let { elementos, swotItems, indicadores } = await Sig.request('POST', `pe/diagrama/getElementos`, { id: this.props.id, model: this.props.table });

        for (let element of elementos) {
            let id = element.elemento.id;
            element.elemento.id_pe_swot_quadrante_item = [];
            element.elemento.id_pe_swot = null;

            if (swotItems[id] && swotItems[id].length > 0) {
                for (let item of swotItems[id]) {
                    element.elemento.id_pe_swot_quadrante_item.push(item.id_pe_swot_quadrante_item);
                }
            }

            if (element.elemento.id_pe_swot_quadrante_item.length > 0) element.elemento.id_pe_swot = swotItems[id][0].id_pe_swot;

            if (element.elemento.id_pe_swot_quadrante_item.length > 0) {
                let swot = this.state.swots.find(item => item.id == element.elemento.id_pe_swot);

                if (swot) {
                    element.elemento.id_pe_swot_quadrante_item = swot.items.filter(item => element.elemento.id_pe_swot_quadrante_item.includes(item.value));
                }
            }

            element.elemento.indicadores = [];

            if (indicadores[id] && indicadores[id].length > 0) {
                for (let indicador of indicadores[id]) {
                    element.elemento.indicadores.push(indicador);
                }
            }
        }

        let objects = [];

        let dHeight = this.state.height;
        let dWidth = this.state.width;

        for (let element of elementos) {
            let elemento = element.elemento;

            elemento.x = parseInt(elemento.x);
            elemento.y = parseInt(elemento.y);
            elemento.width = parseInt(elemento.width);
            elemento.height = parseInt(elemento.height);

            if (element.participantes) elemento.participantes = element.participantes;
            if (element.participantes.length > 0) elemento.participantes = await DataHelper.formatSelectIdUserData(elemento.participantes.map(p => p.id_participante), 'id', 'nome');

            if ((elemento.y + elemento.height) > dHeight) dHeight = (elemento.y + elemento.height);
            if ((elemento.x + elemento.width) > dWidth) dWidth = (elemento.x + elemento.width);

            objects.push({ ...elemento });
        }

        let saveHeight = dHeight > this.state.height;

        this.setState({ objects, height: dHeight, width: dWidth }, () => {
            callback();
            if (saveHeight && !this.state.visao.template) this.saveCanvasSize();
        });
    }

    async savePrioridade(element, prioridade) {

        let req = await Sig.request('POST', `pe/mapa/alterarPrioridade`, { id: element.id, prioridade });
        req.status != 200 ? toast.error('Erro ao alterar prioridade') : this.load(false, false);
    }

    async save() {
        this.setState({ loading: true });

        try {
            let editingObject = this.state.editData;
            let oldObject = this.state.objects[this.state.editing];

            if (editingObject && oldObject) {
                let fields = Object.keys(editingObject);

                for (let field of fields) {
                    let value = editingObject[field];
                    let oldValue = oldObject[field];

                    if (value !== undefined && !this.areEqual(value, oldValue)) {
                        if ((field !== 'width' && field !== 'id_pe_swot' && field !== 'height' && field !== 'id_pe_mapa_perspectiva' && field !== 'participantes' && field !== 'hover' && field !== 'x' && field !== 'y' && field !== 'data_atualizacao' && field !== 'data_criacao') || (field === 'participantes') || (field === 'id_pe_swot_quadrante_item' && value.length)) {
                            if (field === 'participantes' || field === 'id_pe_swot_quadrante_item') value = JSON.stringify(value);

                            if (field === 'indicadores') {
                                this.setState({ loadingDetailsIndicador: true });
                                value = JSON.stringify(value);
                            }

                            await Sig.request('POST', `pe/diagrama/updateElementoProp`, {
                                id: editingObject.id,
                                prop: field,
                                value,
                                model: this.props.elementsTable
                            });

                            if (field === 'indicadores') this.setState({ loadingDetailsIndicador: false });
                        }
                    }
                }
            }

            this.setState({ loading: false });
            await this.load(false, false);

        } catch (error) {
            this.setState({ loading: false });
            toast.error('Houve um problema ao salvar');
        }
    }

    async updateField(data, field, value) {
        let canUpdate = true;

        if (data.tipo === 'perspectiva') {
            for (let index = 0; index <= this.state.objects.length - 1; index++) {
                if (this.state.objects[index].tipo === 'perspectiva') {
                    if (this.state.objects[index].valor == value) {

                        toast.warn('Essa perspectiva já está criada no mapa');

                        canUpdate = false;
                        break;
                    }
                }
            }
        }

        if (canUpdate) {
            let editingObject = this.state.editData;
            editingObject[field] = value;
            editingObject['data_atualizacao'] = new Date();
            this.setState({ editData: editingObject });
        }
    }

    async handleDelete(id) {
        await Sig.request('POST', `pe/diagrama/deleteElemento`, { id, model: this.props.elementsTable });
        let key = this.state.objects.findIndex(item => item.id === id);
        this.setState({ objects: [], editing: this.state.editing === key ? null : this.state.editing });
        this.load(false, false);
    }

    onMouseMove = (event) => {
        event.preventDefault();
        if (this.state.isScrolling && !this.state.isDragging) {
            const dx = event.clientX - this.state.x;
            const dy = event.clientY - this.state.y;

            window.scrollTo({
                left: this.state.left - dx,
                top: this.state.top - dy,
                behavior: 'smooth'
            })
        }
    };

    onMouseUp = (event) => {
        this.setState({ isScrolling: false });
    };

    onMouseDown = (event) => {
        if (!this.state.isDragging) {
            let left = window.scrollX;
            let top = window.scrollY;
            let x = event.clientX;
            let y = event.clientY;

            this.setState({ left, top, x, y, isScrolling: true });
        }
    };

    handleWheelZoom = (event) => {
        if (event.altKey == true) {
            event.preventDefault();

            const delta = Math.sign(event.deltaY);

            if (delta > 0) {
                //zoom out
                this.handleZoom('decrease');
            } else {
                //zoom in
                this.handleZoom('increase');
            }
        }
    }

    handleZoom(operation) {

        let zoom = this.state.zoom;
        let unit = 10;

        if (operation === 'increase') {

            zoom += unit;

        } else {

            zoom -= unit;
        }

        zoom = Math.min(Math.max(zoom, 20), 300);

        this.setState({ zoom });
    }

    handleEdit(evt, key) {
        evt.stopPropagation();
        const clone = JSON.parse(JSON.stringify(this.state.objects[key]));
        this.setState({ editing: null, editData: {} }, () => { this.setState({ editing: key, editData: clone }) });
    }

    handleRemove(evt, id) {
        evt.stopPropagation();

        confirmAlert({

            title: 'Remover esse elemento?',
            message: 'Essa ação não pode ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => { this.handleDelete(id) }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    async updatePosition(x, y, element) {
        element.x = x;
        element.y = y;

        if (x && y) await Sig.request('POST', `pe/diagrama/updateElementoPos`, { id: element.id, x, y, model: this.props.elementsTable });
    }

    async updateSize(target, element) {
        let width = parseInt(target.style.width.replace('px', ''));
        let height = parseInt(target.style.height.replace('px', ''));

        if (width < 200) {
            width = 200;
            target.style.width = `${width}px`;
        }

        if (height < 100 && element.tipo !== 'text') {
            height = 100;
            target.style.height = `${height}px`;
        } else if (height < 60 && element.tipo === 'text') {
            height = 60;
            target.style.height = `${height}px`;
        }

        element.height = height;
        element.width = width;

        if (width && height) await Sig.request('POST', `pe/diagrama/updateElementoSize`, { id: element.id, width, height, model: this.props.elementsTable });


        this.setState({ isDragging: false });
    }

    async chooseSourceObjective(element) {
        this.setState({ sourceElem: element.id });
    }

    async chooseTargetObjective(element) {
        this.setState({ targetElem: element.id, linking: true });

        await Sig.request(
            'POST',
            `pe/diagrama/vinculaElementos`,
            {
                id: this.props.id,
                id_fonte: element.id,
                id_alvo: this.state.sourceElem,
                campoVinculo: this.props.relationField,
                tipo: 'objetivo_estrategico',
                model: `${this.props.elementsTable}Vinculo`
            }
        );

        this.setState({ linking: false }, () => { this.getVinculos() });
    }

    async unlinkObjectives(vinculo) {
        this.setState({ linking: true });

        await Sig.request(
            'POST',
            `pe/diagrama/desvinculaElementos`,
            {
                id_fonte: vinculo.id_fonte,
                id_alvo: vinculo.id_alvo,
                model: `${this.props.elementsTable}Vinculo`
            }
        );

        this.setState({ linking: false }, () => { this.getVinculos() });
    }

    async switchObjectivesLink(vinculo) {
        this.setState({ linking: true });

        await Sig.request(
            'POST',
            `pe/diagrama/inverteVinculoElementos`,
            {
                id_fonte: vinculo.id_fonte,
                id_alvo: vinculo.id_alvo,
                model: `${this.props.elementsTable}Vinculo`
            }
        );

        this.setState({ linking: false }, () => { this.getVinculos() });
    }

    objectiveHasLinksTo(id) {
        let linksTo = [];

        if (this.state.vinculos && this.state.vinculos.length) {
            this.state.vinculos.forEach(vinculo => {
                if (vinculo.id_fonte == id) linksTo.push({ type: 'id_fonte', linkId: vinculo.id_alvo });
                if (vinculo.id_alvo == id) linksTo.push({ type: 'id_alvo', linkId: vinculo.id_fonte });
            });
        }

        return linksTo;
    }

    objectivesShareLinks(id1, id2) {
        let shareLinks = false;

        if (this.state.vinculos && this.state.vinculos.length) {
            for (let vinculo of this.state.vinculos) {
                if ((vinculo.id_alvo === id1 && vinculo.id_fonte === id2) ||
                    (vinculo.id_alvo === id2 && vinculo.id_fonte === id1)) {
                    shareLinks = vinculo;
                    break;
                }
            }

        }

        return shareLinks;
    }

    renderLinkingOptions(element) {
        let children = [];
        let wrapperStyle = { position: 'absolute', bottom: 5, left: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' };

        if (this.state.sourceElem && element.tipo === 'objetivo_estrategico' && this.state.mode === 'edit') {
            let sharesLinkWithSource = this.objectivesShareLinks(this.state.sourceElem, element.id);

            if (element.id !== this.state.sourceElem && !sharesLinkWithSource) {
                children.push(
                    <DefaultButton
                        style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                        tooltip={'Vincular com este objetivo'}
                        tooltipPlacement={'left'}
                        loading={this.state.linking}
                        color={Colors.info}
                        leftIcon={<FontAwesomeIcon color={'white'} icon={faLink} />}
                        onClick={(evt) => { this.chooseTargetObjective(element) }}
                    />
                );
            } else if (element.id !== this.state.sourceElem && sharesLinkWithSource) {
                children.push(
                    <DefaultButton
                        style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                        tooltip={'Remover Vínculo'}
                        tooltipPlacement={'left'}
                        loading={this.state.linking}
                        color={Colors.error}
                        leftIcon={<FontAwesomeIcon color={'white'} icon={faUnlink} />}
                        onClick={(evt) => { this.unlinkObjectives(sharesLinkWithSource) }}
                    />
                );
                children.push(
                    <DefaultButton
                        style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                        tooltip={'Inverter direção da seta'}
                        tooltipPlacement={'left'}
                        loading={this.state.linking}
                        color={Colors.info}
                        leftIcon={<FontAwesomeIcon color={'white'} icon={faArrowsLeftRight} />}
                        onClick={(evt) => { this.switchObjectivesLink(sharesLinkWithSource) }}
                    />
                );
            }

            if (element.id === this.state.sourceElem) {
                children.push(
                    <DefaultButton
                        style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                        tooltip={'Concluir edição'}
                        tooltipPlacement={'left'}
                        loading={this.state.linking}
                        color={Colors.success}
                        leftIcon={<FontAwesomeIcon color={'white'} icon={faCheck} />}
                        onClick={(evt) => { this.setState({ sourceElem: null, targetElem: null }) }}
                    />
                );
            }
        } else if (!this.state.sourceElem && element.hover && this.state.mode === 'edit' && this.props.mode !== 'read-only') {
            if (element.tipo === 'objetivo_estrategico') {
                wrapperStyle = {};
                children.push(
                    <DefaultButton
                        style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                        tooltip={'Vincular a outro objetivo com setas'}
                        tooltipPlacement={'left'}
                        loading={this.state.linking}
                        color={Colors.info}
                        leftIcon={<FontAwesomeIcon color={'white'} icon={faLink} />}
                        onClick={(evt) => { this.chooseSourceObjective(element) }}
                    />
                );
            }
        }

        return <div style={wrapperStyle}>{children.map(elem => (<div style={{ marginRight: children.length > 1 ? 8 : 0 }}>{elem}</div>))}</div>;
    }

    findElementBoxNode = (id) => {
        let element = document.getElementById(`element_${id}`);

        if (element) {
            return element;
        }
    }

    updateLinkedChildrenPositions = async (parent, linkedObjects) => {
        let children = {};

        for (let child of linkedObjects) {
            let xDiff = parent.x + (child.x - parent.prevX), yDiff = parent.y + (child.y - parent.prevY);

            let x = xDiff, y = yDiff;

            this.updatePosition(x, y, { id: child.id, x, y });

            if (!children.id) {
                children[child.id] = { x, y };
            }
        }

        let objects = this.state.objects;

        objects.forEach(obj => {
            if (children[obj.id]) {
                obj.x = children[obj.id].x;
                obj.y = children[obj.id].y;
            }
        })

        this.setState({ objects });
    }

    getLinkedChildren(parent_id) {
        let linkedObjects = [];

        this.state.objects.forEach((elem) => {
            if (elem.id_pe_mapa_perspectiva && parseInt(elem.id_pe_mapa_perspectiva) === parseInt(parent_id)) {
                linkedObjects.push(elem);
            }
        });

        return linkedObjects;
    }

    renderDraggableElementEdit = (element, key) => {
        if (!this.state.sourceElem && element.hover && this.state.mode === 'edit' && this.props.mode !== 'read-only') {
            let left = [], middle = [], right = [];

            if (element.tipo === 'perspectiva') left.push(<DefaultButton title={'Adicionar Objetivo'} color={Colors.success} leftIcon={<FontAwesomeIcon color={'white'} icon={faAdd} />} onClick={() => { this.addObject({ key: 'objetivo_estrategico' }, element) }} />);

            left.push(this.renderLinkingOptions(element));
            right.push(
                <DefaultButton
                    style={{ height: 42, width: 42, borderRadius: 42 / 2 }}
                    tooltip={'Editar'}
                    tooltipPlacement={'left'}
                    color={Colors.success}
                    leftIcon={<FontAwesomeIcon color={'white'} icon={faPen} />}
                    onClick={async (evt) => {
                        this.handleEdit(evt, key); this.updatePosition(element.x, element.y, element);
                    }} />
            );

            if (!(element.tipo === 'text' && element.id_pe_mapa_perspectiva)) {
                right.push(<DefaultButton style={{ height: 42, width: 42, borderRadius: 42 / 2 }} tooltip={'Remover'} tooltipPlacement={'left'} color={Colors.error} leftIcon={<FontAwesomeIcon color={'white'} icon={faTimes} />} onClick={(evt) => { this.handleRemove(evt, element.id); this.updatePosition(element.x, element.y, element); }} />);
            }

            let barStyle = {
                width: '100%',
                backgroundColor: element.tipo !== `perspectiva` ? 'rgba(0,0,0, 0.3)' : 'transparent',
                paddingLeft: 10,
                paddingRight: 10,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                position: 'absolute',
                cursor: 'pointer',
                height: 58,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                zIndex: 999
            };

            if (element.tipo === `perspectiva`) {
                barStyle.top = 0;
            } else {
                if (element.y < barStyle.height) {
                    barStyle.bottom = -57;
                } else {
                    barStyle.top = -57;
                }
            }

            return (
                <div style={barStyle}>
                    <div style={{ justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row' }}>
                        {left.map(elem => (<div style={{ marginRight: left.length > 1 ? 8 : 0 }}>{elem}</div>))}
                    </div>
                    <div style={{ justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row' }}>
                        {middle.map(elem => (<div style={{ marginLeft: middle.length > 1 ? 8 : 0 }}>{elem}</div>))}
                    </div>
                    <div style={{ justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row' }}>
                        {right.map(elem => (<div style={{ marginLeft: right.length > 1 ? 8 : 0 }}>{elem}</div>))}
                    </div>
                </div>
            );
        } else {
            return this.renderLinkingOptions(element);
        }
    }

    renderDraggableElement = (element, key) => {
        const updateXarrow = this.props.useXarrow;
        const style = {
            width: element.width,
            height: element.height
        };

        return (
            <Draggable
                key={`diagram_element_${key}`}
                axis="both"
                handle=".drag"
                bounds={'parent'}
                position={{ x: element.x, y: element.y }}
                grid={[1, 1]}
                scale={this.state.zoom / 100}
                onStart={() => { this.setState({ isDragging: true }); }}
                onDrag={updateXarrow}
                onStop={(drag, ui) => {
                    let x = ui.x;
                    let y = ui.y;
                    const prevX = element.x;
                    const prevY = element.y;

                    this.updatePosition(x, y, element);
                    updateXarrow(drag);

                    if (element.tipo === 'perspectiva') {
                        this.updateLinkedChildrenPositions({ x, y, prevX, prevY }, this.getLinkedChildren(parseInt(element.valor)));
                    }

                    this.setState({ isDragging: false });
                }}
            >
                <div
                    id={`parent_absolute_element_${element.id}`}
                    style={{ ...style, position: 'absolute' }}
                    onMouseLeave={() => {
                        let objects = this.state.objects;
                        objects[key].hover = false;
                        this.setState({ objects, isInteractingWithElement: false });
                    }}
                    onMouseEnter={() => {
                        let objects = this.state.objects;
                        objects[key].hover = true;
                        this.setState({ objects, isInteractingWithElement: element.id });
                    }}
                >
                    {!this.state.isDragging && !this.state.isResizing && this.renderDraggableElementEdit(element, key)}

                    <div
                        className="diagram_nodes"
                        id={`element_${element.id}`}
                        onPointerDown={() => {
                            this.setState({ isDragging: true });
                        }}
                        onPointerUp={(evt) => {
                            this.updateSize(this.findElementBoxNode(element.id), element);

                            this.setState({ isResizing: false }, () => {
                                let objects = this.state.objects;
                                this.setState({ objects });
                            });
                        }}
                        onMouseMove={(e) => {
                            const rect = e.target.getBoundingClientRect();
                            const lowerRightX = rect.left + rect.width;
                            const lowerRightY = rect.top + rect.height;

                            const isNearLowerRightCorner = Math.abs(e.clientX - lowerRightX) < 10 && Math.abs(e.clientY - lowerRightY) < 10;

                            if (isNearLowerRightCorner) {
                                this.setState({ isResizing: true });
                            } else {
                                this.setState({ isResizing: false });
                            }
                        }}
                        style={{
                            resize: this.state.mode === 'edit' && this.props.mode !== 'read-only' ? 'both' : 'none',
                            overflow: this.state.mode === 'edit' ? 'scroll' : 'auto',
                            border: element.hover && this.state.mode === 'edit' ? '1px solid grey' : `2px solid ${element.cor_borda}`,
                            borderRadius: 3,
                            borderStyle: element.hover && this.state.mode === 'edit' ? 'dashed' : 'solid',
                            backgroundColor: element.cor_fundo,
                            opacity: 1,
                            color: element.cor_texto,
                            backgroundColor: element.cor_fundo,
                            ...style
                        }}
                    >
                        <div
                            onDoubleClick={(evt) => { this.handleEdit(evt, key); }}
                            id={`element-${element.id}`}
                            className={this.state.mode === 'edit' && this.props.mode !== 'read-only' ? 'drag' : 'no-drag'}
                            style={{
                                cursor: this.state.mode === 'edit' && this.props.mode !== 'read-only' ? 'move' : 'auto',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <div style={{
                                width: '100%',
                                height: '100%',
                                lineBreak: 'normal',
                                wordBreak: 'break-all',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '8px' }}>
                                    {
                                        element.tipo === 'objetivo_estrategico' && element.indicadores?.length && !this.state.loadingDetailsIndicador ?
                                            <div>
                                                <ObjetivoEstrategicoMeta
                                                    hideTooltip={this.state.mode === 'edit'}
                                                    id={element.id}
                                                    model={this.props.elementsTable}
                                                    viewDetailsIndicador={this.state.viewDetailsIndicador}
                                                    handleViewDetails={(id) => {
                                                        if (this.state.mode === 'read-only' || this.props.mode === 'read-only')
                                                            this.handleViewDetails(id);
                                                    }}
                                                />
                                            </div>
                                            : null
                                    }

                                    {
                                        element.tipo === 'objetivo_estrategico' && (
                                            (this.state.mode === 'read-only' || this.props.mode === 'read-only') ?
                                                <div style={{ marginLeft: 'auto' }}>
                                                    {this.renderStarVisualizacao(element)}
                                                </div>
                                                : this.state.mode === 'edit' ?
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {this.state.editData?.id == element.id ? this.renderStar() : this.renderStarVisualizacao(element)}
                                                    </div>
                                                    : null
                                        )
                                    }
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: element.tipo === 'text' && element.id_pe_mapa_perspectiva ? 'flex-start' : 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%',
                                    wordBreak: 'keep-all',
                                    padding: 8
                                }}>
                                    {element.tipo === 'text' || element.tipo === 'objetivo_estrategico' ?
                                        <div
                                            className="revert-font-size"
                                            style={{ height: '100%', padding: 8 }}
                                            dangerouslySetInnerHTML={{ __html: element.valor }}
                                        ></div>
                                        : null}
                                    {element.tipo === 'icon' && element.valor ?
                                        <FontAwesomeIcon
                                            style={{ fontSize: (element.width + element.height) / 3 }}
                                            icon={Icons[element.valor]} />
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }

    renderArrows = (element) => {
        if (element.tipo === 'objetivo_estrategico') {
            let links = this.objectiveHasLinksTo(element.id);

            if (links) {
                return links.map((link, key) => {
                    if (link.type == 'id_alvo') {
                        let arrowProps = {
                            start: `parent_absolute_element_${element.id}`,
                            end: `element_${link.linkId}`,
                            path: 'grid',
                            dashness: this.state.sourceElem ? { animation: 2 } : false,
                            color: element.cor_fundo !== 'white' ? element.cor_fundo : Colors.dark,
                            strokeWidth: 2.5 * (this.state.zoom / 100),
                            animateDrawing: .3
                        }

                        return !this.state.isResizing ? (
                            <Xarrow key={`xarrow_elem_${key}`} {...arrowProps} />
                        ) : null;
                    }
                })
            }
        }
    }

    renderObjects = () => {
        return (
            <Xwrapper>
                {
                    this.state.objects.map((element, key) => (this.renderDraggableElement(element, key)))
                }
            </Xwrapper>
        )
    }

    renderArrowsWrapper = () => {
        return (
            <Xwrapper>
                {
                    this.state.objects.map((element) => (this.renderArrows(element)))
                }
            </Xwrapper>
        )
    }

    getNextPerspectiva() {

        let perspectivas = [...this.state.perspectivas];

        for (let index = 0; index <= this.state.objects.length - 1; index++) {

            if (this.state.objects[index].tipo === 'perspectiva') {

                let find = perspectivas.findIndex(item => item.id == this.state.objects[index].valor);

                if (find >= 0) {

                    perspectivas.splice(find, 1);
                }
            }
        }

        if (perspectivas.length) {

            return perspectivas[0].id;

        } else {

            toast.warn('Todas as perspectivas já estão no mapa');
            throw new Error('Perspectivas');
        }
    }

    async addObject(element, data) {
        let x = (this.state.width / 2);
        let y = (this.state.height / 2);
        let width = 500;
        let height = 198;

        if ((element.key === 'text' && data.from_perspectiva)) {
            let perspectiva = this.state.objects.find(obj => (data.id === obj.id));

            x = perspectiva.x + 20;
            y = perspectiva.y + 20;
        }

        let nextPerspectiva = '';

        if (element.key === 'perspectiva') {
            width = 600;
            height = 300;

            nextPerspectiva = this.getNextPerspectiva();
        }


        let object = {
            x,
            y,
            width,
            height,
            hover: false,
            valor: element.key === 'text' ? data.defaultText || 'Novo Texto' : element.key === 'objetivo_estrategico' ? '<h4>Novo Objetivo</h4>' : element.key === 'perspectiva' ? nextPerspectiva : '0',
            id_colaborador: SessionHelper.getData().id_usuario,
            data_criacao: new Date(),
            data_atualizacao: new Date(),
            tipo: element.key,
            cor_fundo: element.key === 'perspectiva' || element.key === 'objetivo_estrategico' ? '#e0e0e0' : 'transparent',
            cor_borda: 'transparent',
            cor_texto: '#000000',
            id_pe_mapa_perspectiva: null,
            prioritario: 0,
            indicadores: null,
            id_pe_swot: null,
            id_pe_swot_quadrante_item: null,
        };

        if (element.key === 'objetivo_estrategico' || (element.key === 'text' && data.from_perspectiva)) {
            object.id_pe_mapa_perspectiva = data.valor;
        }

        object.prioritario = 0;

        let requestData = {
            idRelation: this.props.id,
            model: this.props.elementsTable,
            relationField: this.props.relationField
        }

        Object.keys(object).forEach((key, index) => { requestData[key] = object[key]; });

        if (element.key === 'perspectiva') {
            await Sig.request('POST', 'pe/mapa/generatePerspectivas', { id: this.props.id, width: this.state.width, justOne: 1 });
            await this.load();
        } else {
            let { elemento } = await Sig.request('POST', `pe/diagrama/addElemento`, requestData);

            if (elemento && elemento.id) {
                await this.getElementos(() => {
                    let addedElementIndex = this.state.objects.findIndex(obj => obj.id === elemento.id);
                    let addedElement = this.state.objects[addedElementIndex];

                    if (this.state.editing) {
                        this.setState({ editing: null, editData: {} }, () => { this.setState({ editing: addedElementIndex, editData: { ...addedElement } }); });
                    } else {
                        this.setState({ editing: addedElementIndex, editData: { ...addedElement } });
                    }
                });
            }
        }
    }

    async handleFavorite() {

        this.setState({ loading: true });

        if (this.props.handleFavorite) this.props.handleFavorite(this.props.id, this.state.isFavorite);

        await this.load(false, false);

        this.setState({ loading: false });
        toast.success('Alterado com sucesso');
    }

    async handleDiagramDelete() {

        confirmAlert({

            title: 'Remover esse diagrama?',
            message: 'Essa ação não pode ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {

                        await this.setState({ loading: true });
                        await this.props.delete();

                        toast.success('Diagrama removido com sucesso');

                        this.props.callback();
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    handleMode() {
        if (this.props.table === 'pe_mapa') {
            return this.props.callback();
        }

        let mode = this.state.mode;
        mode = mode === 'edit' ? 'read-only' : 'edit';

        this.setState({ mode, view: 'normal' });
    }

    renderCanvasTools() {
        if (this.props.table === 'PeMapa') {
            return (
                <div>
                    <CustomTooltip
                        tooltip={"Redimensionar Mapa"}
                        placement="right"
                    >
                        <div
                            key={`panel_elem_resize_canvas`}
                            onMouseDown={(evt) => { this.setState({ resizingCanvas: !this.state.resizingCanvas }) }}
                            style={{
                                minWidth: 64,
                                minHeight: 48,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: this.state.resizingCanvas ? `3px solid grey` : '3px solid lightgrey',
                                backgroundColor: this.state.resizingCanvas ? 'grey' : 'transparent',
                                borderRadius: 8,
                                marginBottom: 8,
                                cursor: 'pointer'
                            }}>
                            <FontAwesomeIcon size="xl" icon={faCropSimple} color={!this.state.resizingCanvas ? 'grey' : Colors.white} />
                        </div>
                    </CustomTooltip>
                </div>
            );
        }
    }

    renderCanvasResize() {
        return (
            <div>
                <div style={{
                    position: 'fixed',
                    left: 100,
                    bottom: 5,
                    boxShadow: Colors.boxShadow,
                    width: 300,
                    height: 64,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: '0.8px solid lightgrey',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    zIndex: 999
                }}
                >
                    <div style={{ ...EssentialStyle.rowSpaceBetween, height: '100%', width: '100%' }} >
                        <CustomTooltip
                            tooltip={"Redimensionar Altura"}
                            placement="left"
                        >
                            <FontAwesomeIcon size="xl" icon={faUpDown} color={'grey'} style={{ marginRight: 20 }} />
                        </CustomTooltip>
                        {this.state.loadingSize ?
                            <Form.Control disabled /> :
                            <Form.Control
                                value={this.state.height}
                                type="text"
                                style={{ marginRight: 2 }}
                                onChange={(event) => {
                                    this.setState({ height: event.target.value ? parseInt(event.target.value) : '' });
                                }}
                            />
                        }
                        <div style={{ marginRight: 20, fontWeight: 'bold', color: 'grey' }}>px</div>
                        <DefaultButton
                            style={{ marginRight: 2 }}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faMinus} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loadingResizingHeight}
                            onClick={() => {
                                this.setState({ loadingSize: true }, () => {
                                    this.setState({ height: this.state.height - 100 }, () => { this.setState({ loadingSize: false }) })
                                });
                            }}
                        />
                        <DefaultButton
                            style={{ marginRight: 10 }}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faPlus} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loadingResizingHeight}
                            onClick={() => {
                                this.setState({ loadingSize: true }, () => {
                                    this.setState({ height: this.state.height + 100 }, () => { this.setState({ loadingSize: false }) })
                                });
                            }}
                        />
                        <DefaultButton
                            tooltip={'Salvar Altura'}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={Colors.success}
                            loading={this.state.loadingResizingHeight}
                            onClick={() => {
                                this.saveCanvasSize();
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    position: 'fixed',
                    right: 10,
                    top: 75,
                    boxShadow: Colors.boxShadow,
                    width: 300,
                    height: 64,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: '0.8px solid lightgrey',
                    borderRadius: 5,
                    backgroundColor: 'white',
                    zIndex: 999
                }}
                >
                    <div style={{ ...EssentialStyle.rowSpaceBetween, height: '100%', width: '100%' }} >
                        <CustomTooltip
                            tooltip={"Redimensionar Largura"}
                            placement="left"
                        >
                            <FontAwesomeIcon size="xl" icon={faLeftRight} color={'grey'} style={{ marginRight: 20 }} />
                        </CustomTooltip>
                        {this.state.loadingSize ?
                            <Form.Control disabled /> :
                            <Form.Control
                                value={this.state.width}
                                type="text"
                                style={{ marginRight: 2 }}
                                onChange={(event) => {
                                    this.setState({ width: event.target.value ? parseInt(event.target.value) : '' });
                                }}
                            />
                        }
                        <div style={{ marginRight: 20, fontWeight: 'bold', color: 'grey' }}>px</div>
                        <DefaultButton
                            style={{ marginRight: 2 }}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faMinus} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loadingResizingWidth}
                            onClick={() => {
                                this.setState({ loadingSize: true }, () => {
                                    this.setState({ width: this.state.width - 100 }, () => { this.setState({ loadingSize: false }) })
                                });
                            }}
                        />
                        <DefaultButton
                            style={{ marginRight: 10 }}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faPlus} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loadingResizingWidth}
                            onClick={() => {
                                this.setState({ loadingSize: true }, () => {
                                    this.setState({ width: this.state.width + 100 }, () => { this.setState({ loadingSize: false }) })
                                });
                            }}
                        />
                        <DefaultButton
                            tooltip={'Salvar Largura'}
                            tooltipPlacement={'left'}
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={Colors.success}
                            loading={this.state.loadingResizingWidth}
                            onClick={() => {
                                this.saveCanvasSize();
                            }}
                        />
                    </div>
                </div>
            </div>

        )
    }

    async saveCanvasSize() {
        this.setState({ loadingResizingWidth: true, loadingSize: true, loadingResizingHeight: true });

        await Sig.request('POST', `pe/diagrama/updateCanvasSize`, { id: this.props.id, width: this.state.width, height: this.state.height });

        this.setState({ loadingResizingWidth: false, loadingSize: false, loadingResizingHeight: false });
    }

    renderPanel() {
        return (
            <div style={{ position: 'fixed', left: 10, top: 10, boxShadow: Colors.boxShadow, width: 80, height: 'calc(100vh - 30px)', border: '0.8px solid lightgrey', borderRadius: 5, paddingTop: 10, backgroundColor: 'white', zIndex: 999 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {
                        this.state.elements.map((element, key) => {

                            return (
                                <CustomTooltip
                                    tooltip={element.label}
                                    placement="right"
                                >
                                    <div
                                        key={`panel_elem_${key}`}
                                        onMouseDown={(evt) => { this.addObject(element, {}) }}
                                        style={{
                                            minWidth: 64,
                                            minHeight: 48,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: `3px solid lightgrey`,
                                            borderRadius: 8, marginBottom: 8, cursor: 'pointer'
                                        }}>
                                        <FontAwesomeIcon size="xl" icon={element.icon} color={'grey'} />
                                    </div>
                                </CustomTooltip>
                            )
                        })
                    }

                    {this.renderCanvasTools()}
                </div>
            </div>
        )
    }

    renderHeader() {
        let podeFavoritar = this.state.podeFavoritar;
        let podeRemover = this.state.podeRemover;
        let podeConfigurar = this.state.podeConfigurar;

        return (
            <div style={{ position: 'fixed', top: 10, left: 100, right: 10, boxShadow: Colors.boxShadow, border: `0.8px solid lightgrey`, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, height: 60, backgroundColor: 'white', zIndex: 999 }}>
                <div>
                    {this.props.backButton ? <DefaultButton tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faArrowLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading}
                        onClick={() => { this.props.callback(); }} style={{ marginRight: 8 }} /> : null}
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{this.state.visao.nome || ''}</h5>
                <div style={{ display: 'flex', flexDirectio: 'column' }}>
                    {this.props.table === "PeVisao" &&
                        <DefaultButton tooltip={'Modo de Visualização'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faEye} color={Colors.dark} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading}
                            onClick={() => {
                                this.handleMode();
                                let considerEditingInterface = false;
                                this.setInitialZoom(this.state.width, this.state.height, considerEditingInterface);
                            }} style={{ marginRight: 8 }} />
                    }
                    <DefaultButton tooltip={!podeFavoritar ? 'Sem Permissão Para Favoritar' : (this.state.isFavorite ? 'Remover Favorito' : 'Marcar como Favorito')} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faStar} color={this.state.isFavorite ? Colors.favorite : Colors.dark} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeFavoritar} onClick={() => { this.handleFavorite() }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={'Exportar'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faCamera} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading || this.state.print} onClick={() => { this.print() }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={!podeRemover ? 'Sem Permissão Para Remover' : 'Remover'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTrash} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeRemover} onClick={() => { this.handleDiagramDelete() }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={'Histórico'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faHistory} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ showHistory: true }) }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={!podeConfigurar ? 'Sem Permissão Para Configurar' : 'Configurações'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faCog} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeConfigurar} onClick={() => { this.setState({ showSettings: true }) }} style={{ marginRight: 8 }} />
                    {!this.props.backButton ? <DefaultButton tooltip={this.props.table === 'PeVisao' ? 'Listar Visões' : 'Listar Mapas'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTableCells} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.callback(); }} style={{ marginRight: 8 }} /> : null}
                    <DefaultButton
                        style={{ width: this.state.isSmallScreen ? 30 : 38, height: this.state.isSmallScreen ? 30 : 38 }}
                        fontSize={this.state.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={this.props.table === 'PeVisao' ? 'https://advis.freshdesk.com/support/solutions/articles/63000283184-vis%C3%A3o-de-futuro' : 'https://advis.freshdesk.com/support/solutions/articles/63000218509-mapa-estrat%C3%A9gico'} />
                </div>
            </div>
        )
    }

    renderIconPicker() {

        return (

            <div style={{ height: 200, overflowY: 'scroll', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                {Object.keys(Icons).map((icon) => {

                    if (Icons[icon]) {

                        return (

                            <FontAwesomeIcon key={icon} onClick={() => { this.updateField(this.state.editData, 'valor', icon.toString()) }} style={{ width: 25, height: 25, padding: 8, fontSize: 20, cursor: 'pointer', border: this.state.editData.valor === icon ? '3px solid lightgrey' : '', borderRadius: 3, color: 'grey' }} icon={Icons[icon]} />
                        )
                    }
                })}

            </div>
        )
    }

    renderTextEditor() {
        return (
            <TextEditor
                onChange={(value) => { this.updateField(this.state.editData, 'valor', value) }}
                defaultValue={this.state.editData.valor}
                height={'auto'}
                width={'100%'}
            />
        )
    }

    renderPerspectivaSelect() {

        let value = DataHelper.formatSelectData([{ id: this.state.editData.valor, nome: this.state.perspectivas.find(item => item.id == this.state.editData.valor).nome }], 'id', 'nome')[0];

        return (
            <div style={{ marginBottom: 20 }}>
                <Select value={value} options={DataHelper.formatSelectData(this.state.perspectivas, 'id', 'nome')} placeholder={'Selecione uma Perspectiva'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.updateField(this.state.editData, 'valor', value.value) }} />
            </div>
        )
    }

    renderSwotSelect() {

        let swot = this.state.swots.find(item => item.id == this.state.editData.id_pe_swot);
        let value = null;

        if (swot) {
            value = DataHelper.formatSelectData([{ id: this.state.editData.id_pe_swot, nome: swot.nome }], 'id', 'nome')[0];
        }

        return (

            <div style={{ marginBottom: 20 }}>
                <h6 style={{ fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>Swot</h6>
                <Select value={value} options={DataHelper.formatSelectData(this.state.swots, 'id', 'nome')} placeholder={'Selecione um Swot'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isSearchable onChange={(value) => { this.updateField(this.state.editData, 'id_pe_swot', value.value) }} />

                {this.state.editData.id_pe_swot ?

                    <div>
                        <h6 style={{ fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>Fator</h6>

                        <Select value={this.state.editData.id_pe_swot_quadrante_item} isMulti options={DiagramHelper.ordenaSwots(swot.items)} placeholder={'Selecione um Fator'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isSearchable
                            onChange={(value) => {
                                this.updateField(this.state.editData, 'id_pe_swot_quadrante_item', value)
                            }} />
                    </div>

                    : null}
            </div>
        )
    }

    async getIndicadores() {
        let indicadores = await Sig.request('POST', 'helper/getIndicadoresPaineisGmr', { raw_object: true }) || [];
        this.setState({ indicadores });
    }

    renderIndicadorSelect(indicador, index) {
        return (
            <MapaIndicadorSelect
                key={indicador.id_indicador && indicador.id_gmr_painel ? `${indicador.id_indicador}-${indicador.id_gmr_painel}` : `${index}`}
                borderTop={index > 0 ? `.1px solid ${Colors.border}` : 'none'}
                id_pe_mapa={this.props.id}
                indicadores={this.state.indicadores}
                indicador={indicador}
                removeCallback={() => {
                    let indicadoresElemento = this.state.editData.indicadores || [];
                    indicadoresElemento.splice(index, 1);
                    this.updateField(this.state.editData, 'indicadores', indicadoresElemento);
                }}
                updateCallback={(id_indicador, id_gmr_painel) => {
                    if(this.state.updatingIndicadorCallback) return;

                    this.setState({ updatingIndicadorCallback: true }, () => {
                        let indicadoresElemento = this.state.editData.indicadores || [];

                        indicadoresElemento[index] = { ...indicadoresElemento[index], id_indicador, id_gmr_painel };

                        this.updateField(this.state.editData, 'indicadores', indicadoresElemento);

                        this.setState({ updatingIndicadorCallback: false });
                    });                    
                }}
            />
        );
    }

    renderIndicadoresSelect() {
        let indicadoresList = this.state.editData.indicadores || [];

        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%' }}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', cursor: 'pointer' }} onClick={() => { this.setState({ expandIndicadores: !this.state.expandIndicadores }); }}>
                    <div style={{ ...EssentialStyle.rowFlexStart }}>
                        <FontAwesomeIcon icon={this.state.expandIndicadores ? faChevronDown : faChevronRight} style={{ color: Colors.dark, marginRight: 10 }} />
                        <h6 style={{ fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>Indicadores</h6>
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexCenter }}>
                        <div
                            style={{
                                height: 28,
                                width: 'auto',
                                minWidth: 28,
                                borderRadius: 14,
                                backgroundColor: Colors.dark,
                                color: Colors.light,
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}>
                            {indicadoresList.length}
                        </div>
                    </div>
                </div>
                <Collapse in={this.state.expandIndicadores}>
                    {this.state.expandIndicadores ?
                        <div style={{ ...EssentialStyle.columnStart, marginBottom: 20, width: '100%' }}>
                            {indicadoresList.map((indicador, index) => (this.renderIndicadorSelect(indicador, index)))}
                        </div>
                    : <div/>}
                </Collapse>
                <div style={{ ...EssentialStyle.columnStart, marginTop: 20, width: '100%' }} ref={ref => { this.btnAddIndicador = ref }}>
                    <DefaultButton
                         title={'Adicionar Indicador'}
                        leftIcon={<FontAwesomeIcon icon={faAdd} />}
                        onClick={() => {
                            let indicadoresElemento = this.state.editData.indicadores || [];
                            indicadoresElemento.push({ id_indicador: null, id_gmr_painel: null, id_pe_mapa_elemento: this.state.editData.id });
                            this.updateField(this.state.editData, 'indicadores', indicadoresElemento);

                            this.setState({ expandIndicadores: true }, () => {
                                setTimeout(() => {
                                    if(this.btnAddIndicador) this.btnAddIndicador.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                                }, 500);
                            });
                        }}
                    />
                </div>
            </div>
        )
    }

    renderParticipantsSelect() {
        return (
            <div style={{ marginBottom: 20 }}>
                <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>Responsáveis</h6>
                <Select value={this.state.editData.participantes} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o(s) responsáveis'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.updateField(this.state.editData, 'participantes', value) }} />
            </div>
        )
    }

    renderColorCircle(color) {
        return color ? <div style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: color, marginRight: 8, border: '.1px solid grey' }} /> : null;
    }

    renderBackgroundColorPicker() {
        return (
            <div>
                <h6 style={{ ...EssentialStyle.rowFlexStart, fontWeight: 'bold', marginBottom: 20 }}>{this.renderColorCircle(this.state.editData.cor_fundo)} Cor de Fundo</h6>
                <TwitterPicker triangle={'hide'} width={'100%'} colors={colorPickerColors} onChange={(evt) => { this.updateField(this.state.editData, 'cor_fundo', evt.hex) }} color={this.state.editData.cor_fundo} />
            </div>
        )
    }

    renderBorderColorPicker() {

        return (

            <div>
                <h6 style={{ ...EssentialStyle.rowFlexStart, fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>{this.renderColorCircle(this.state.editData.cor_borda)} Cor da Borda</h6>
                <TwitterPicker triangle={'hide'} width={'100%'} colors={colorPickerColors} onChange={(evt) => { this.updateField(this.state.editData, 'cor_borda', evt.hex) }} color={this.state.editData.cor_borda} />
            </div>
        )
    }

    renderContentColorPicker() {
        return (
            <div>
                <h6 style={{ ...EssentialStyle.rowFlexStart, fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>{this.renderColorCircle(this.state.editData.cor_texto)} Cor do Conteúdo</h6>
                <TwitterPicker triangle={'hide'} width={'100%'} colors={colorPickerColors} onChange={(evt) => { this.updateField(this.state.editData, 'cor_texto', evt.hex); }} color={this.state.editData.cor_texto} />
            </div>
        )
    }

    renderStar(marginRight = 0) {
        let podeAlterar = this.state.podeFavoritarObjetivo;
        let colorStar = this.state.editData.prioritario == 1 ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon
                icon={faStar}
                style={{
                    color: colorStar,
                    marginRight: marginRight,
                    fontSize: '1.5em',
                    transition: 'color 0.15s ease-in-out',
                    cursor: podeAlterar ? 'pointer' : 'default'
                }}
                onClick={(event) => {
                    if (podeAlterar) {
                        this.setState({ editData: { ...this.state.editData, prioritario: this.state.editData.prioritario == 1 ? 0 : 1 } });
                        this.updateField(this.state.editData, 'prioritario', this.state.editData.prioritario == 1 ? 0 : 1)
                    }
                }}
            />
        );
    }

    renderStarVisualizacao(element) {
        let podeAlterar = this.state.podeFavoritarObjetivo;
        let colorStar = element.prioritario == 1 ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon
                icon={faStar}
                style={{
                    color: colorStar,
                    fontSize: '1.5em',
                    transition: 'color 0.15s ease-in-out',
                    cursor: podeAlterar ? 'pointer' : 'default'
                }}
                onClick={(event) => {
                    if (podeAlterar) {
                        this.savePrioridade(element, element.prioritario == 1 ? 0 : 1)
                    }
                }}
            />
        );
    }

    renderPrioridade() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                {this.renderStar('0.3em')}
                <h6 style={{ fontWeight: 'bold', marginRight: 10, marginBottom: 0 }}>Marcar com estrela</h6>
            </div>
        )
    }

    renderEdit() {
        let width = (window.innerWidth * 0.6) >= 500 ? window.innerWidth * 0.6 : window.innerWidth;

        if (this.state.editing !== null) {
            return (
                <div style={{ position: 'fixed', right: 10, top: 80, bottom: 0, zIndex: 999, boxShadow: Colors.boxShadow, width: width, padding: 20, border: '0.8px solid lightgrey', borderRadius: 5, backgroundColor: 'white', overflow: 'hidden' }}>

                    <div ref={ref => { this.modalEdit = ref }} style={{ height: '95%', overflowY: 'scroll', overflowX: 'hidden', paddingLeft: 20, paddingRight: 20, paddingBottom: 30, marginBottom: 50, width: '100%' }}>
                        <h5 style={{ fontWeight: 'bold', marginBottom: 20, textAlign: 'center' }}>Editar</h5>
                        <h6 style={{ fontWeight: 'bold', marginBottom: 20, marginTop: 20 }}>{this.state.objects[this.state.editing]?.tipo === 'icon' ? `Ícone` : `Texto`}</h6>

                        {this.state.objects[this.state.editing].tipo === 'text' || this.state.objects[this.state.editing].tipo === 'objetivo_estrategico' ? this.renderTextEditor() : null}
                        {this.state.objects[this.state.editing].tipo === 'icon' ? this.renderIconPicker() : null}
                        {this.state.objects[this.state.editing].tipo === 'perspectiva' ? this.renderPerspectivaSelect() : null}
                        {this.state.objects[this.state.editing].tipo === 'objetivo_estrategico' ? this.renderPrioridade() : null}
                        {this.state.objects[this.state.editing].tipo === 'objetivo_estrategico' ? this.renderIndicadoresSelect() : null}
                        {this.state.objects[this.state.editing].tipo === 'objetivo_estrategico' ? this.renderSwotSelect() : null}
                        {this.state.objects[this.state.editing].tipo === 'objetivo_estrategico' ? this.renderParticipantsSelect() : null}

                        {this.renderBackgroundColorPicker()}
                        {this.renderContentColorPicker()}
                        {this.renderBorderColorPicker()}


                        <div onClick={() => { this.setState({ editing: null, editData: {} }) }} style={{ zIndex: 5, position: 'absolute', left: 5, top: 5, padding: 5, cursor: 'pointer', backgroundColor: 'white', width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FontAwesomeIcon style={{ fontSize: 20, color: Colors.dark }} icon={faTimes} />
                        </div>
                    </div>
                    <div style={{ zIndex: 5, backgroundColor: 'white', position: 'absolute', bottom: 10, width: width - 55 }}>
                        <DefaultButton loading={this.state.loading} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faCheck} />} style={{ width: '100%', marginTop: 10 }} title={'Salvar Alterações'} onClick={() => { this.save() }} />
                    </div>
                </div>
            )
        }
    }

    historyCallback = () => {
        this.setState({ showHistory: false });
        this.load(false, false);
    }

    getPrintSize = () => {

        let size = { width: 0, height: 0 };

        switch (this.props.table) {

            case 'PeVisao':

                size = { width: this.state.width, height: this.state.height };
                break;

            case 'PeMapa':

                let maxWidth = 0;
                let maxHeight = 0;

                for (let index = 0; index <= this.state.objects.length - 1; index++) {

                    if (this.state.objects[index].x > maxWidth) {

                        maxWidth = this.state.objects[index].x + this.state.objects[index].width;
                    }

                    if (this.state.objects[index].y > maxHeight) {

                        maxHeight = this.state.objects[index].y + this.state.objects[index].height;
                    }
                }

                size.width = maxWidth;
                size.height = maxHeight;
                break;

            default:

                size = { width: this.state.width, height: this.state.height };
                break;
        }

        return size;
    }

    async print() {

        if (!this.state.print) {

            let previousZoom = this.state.zoom;

            this.setState({ print: true, zoom: 100 });

            let canvas = document.getElementById('canvas-wrapper');
            canvas.style.display = 'block';

            if (canvas) {
                try {
                    let size = this.getPrintSize();

                    setTimeout(async () => {
                        let png = await domtoimage.toPng(canvas, { width: size.width, height: size.height, bgcolor: 'rgb(247, 247, 247)' })

                        var link = document.createElement('a');
                        link.download = `${this.state.visao.nome}.png`;
                        link.href = png;
                        link.click();

                        this.setState({ print: false, zoom: previousZoom });
                    }, 1500);


                } catch (error) {
                    this.setState({ print: false, zoom: previousZoom });

                    toast.error('Houve um problema ao gerar a imagem');
                }
            }
        }
    }

    settingsCallback = async () => {
        this.setState({ showSettings: false });
        await this.load(false, false);
    }

    renderSwitch() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faSitemap} />
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.view === 'normal' ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <FontAwesomeIcon icon={faTableCells} />
            </div>
        )
    }

    handleView() {

        let view = this.state.view;
        view = view === 'normal' ? 'table' : 'normal';

        this.setState({ view });
    }

    renderBackToEdit() {

        let podeEditar = this.props.table == 'PeVisao' && this.state.podeEditar;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
                <DefaultButton
                    tooltip={!podeEditar ? 'Sem Permissão de Edição' : 'Modo de Edição'}
                    tooltipPlacement={'left'}
                    leftIcon={<FontAwesomeIcon icon={faPen} color={Colors.dark} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading}
                    onClick={() => { this.handleMode() }}
                    style={{ marginBottom: 8 }}
                    disabled={!podeEditar}
                />
                <DefaultButton
                    tooltip={'Exportar'}
                    tooltipPlacement={'left'}
                    leftIcon={<FontAwesomeIcon icon={faCamera} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading || this.state.print}
                    onClick={() => { this.print() }} style={{ marginBottom: 8 }}
                />
                <DefaultButton
                    tooltip={this.props.table === 'PeVisao' ? 'Listar Visões' : 'Listar Mapas'}
                    tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTableCells} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading}
                    onClick={() => { this.props.callback(); }}
                    style={{ marginBottom: 8 }}
                />

                {this.props.table === 'pe_mapa' ? <DefaultButton tooltip={this.state.view === 'normal' ? 'Visualizar em Tabela' : 'Visualizar em Painel'} tooltipPlacement={'left'} leftIcon={this.renderSwitch()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.handleView() }} style={{ marginRight: 8 }} /> : null}
                {this.props.table === 'pe_mapa' ? <DefaultButton tooltip={'Listar Mapas'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTableCells} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ showMapas: true }) }} style={{ marginRight: 8 }} /> : null}
            </div>
        )
    }

    renderZoom() {
        return (

            <div style={{ position: 'fixed', right: 10, bottom: 10, zIndex: 998, display: 'flex', flexDirection: 'column' }}>
                <div onClick={() => { this.handleZoom('increase') }} style={{ boxShadow: Colors.boxShadow, width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.8px solid lightgrey', borderRadius: 5, backgroundColor: 'white', cursor: 'pointer' }}><FontAwesomeIcon icon={faPlus} style={{ fontSize: 20 }} /></div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>{this.state.zoom}%</div>
                <div onClick={() => { this.handleZoom('decrease') }} style={{ boxShadow: Colors.boxShadow, width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.8px solid lightgrey', borderRadius: 5, backgroundColor: 'white', cursor: 'pointer' }}><FontAwesomeIcon icon={faMinus} style={{ fontSize: 20 }} /></div>
            </div>
        )
    }

    handleViewDetails(id) {
        this.setState({ viewDetailsIndicador: this.state.viewDetailsIndicador == id ? null : id });
    }

    render() {

        if (this.state.loadingDiagram) return <LoadingPage />
        if (this.state.showHistory && this.props.table === 'PeVisao') return <VisaoHistory id={this.props.id} historyCallback={this.historyCallback} />
        if (this.state.showHistory && this.props.table === 'PeMapa') return <MapaHistory id={this.props.id} historyCallback={this.historyCallback} />
        if (this.state.showSettings) return this.props.table === 'PeVisao' ? <EditVisao id={this.props.id} settingsCallback={this.settingsCallback} /> : <EditMapa id={this.props.id} settingsCallback={this.settingsCallback} />
        if (this.state.showMapas) return <MapaList callback={() => { this.setState({ showMapas: false }) }} />

        return (

            <div>
                {this.state.mode === 'edit' && this.props.mode !== 'read-only' ? this.renderPanel() : null}
                {this.state.mode === 'edit' && this.props.mode !== 'read-only' && this.state.resizingCanvas ? this.renderCanvasResize() : null}
                {this.state.mode === 'edit' && this.props.mode !== 'read-only' ? this.renderHeader() : null}
                {this.state.mode === 'edit' && this.props.mode !== 'read-only' ? this.renderEdit() : null}
                {this.state.viewDetailsIndicador !== null ? <IndicadoresObjetivo id={this.state.viewDetailsIndicador} model={this.props.elementsTable} handleViewDetails={(id) => { this.handleViewDetails(id) }} /> : null}
                {this.state.mode === 'read-only' && this.props.mode !== 'read-only' ? this.renderBackToEdit() : null}
                {this.renderZoom()}

                <div id="canvas-wrapper"
                    style={{
                        paddingLeft: this.state.mode === 'read-only' || this.state.print ? 0 : 100,
                        paddingTop: this.state.mode === 'read-only' || this.state.print ? 0 : 100,
                        position: 'relative'
                    }}
                >
                    <div
                        onMouseUp={(e) => { this.onMouseUp(e) }}
                        onMouseDown={(e) => { this.onMouseDown(e) }}
                        onMouseMove={(e) => { this.onMouseMove(e) }}
                        ref={elem => this.canvas = elem}
                        id="canvas"
                        style={{
                            position: (this.state.mode == 'read-only' && this.props.mode == 'read-only') || (this.state.mode == 'edit' && this.props.mode !== 'read-only') ? 'static' : 'absolute',
                            top: '0',
                            left: '0',
                            cursor: 'grab',
                            width: this.state.mode === 'read-only' || this.state.print ? this.state.width : this.state.width + DiagramHelper.scaleSizeByPercent(10, this.state.zoom),
                            height: this.state.mode === 'read-only' || this.state.print ? this.state.height : this.state.height + DiagramHelper.scaleSizeByPercent(10, this.state.zoom),
                            WebkitUserSelect: 'none',
                            backgroundColor: '#ffffff',
                            overflow: 'hidden',
                            transform: `scale(${this.state.zoom / 100})`,
                            transformOrigin: '0% 0% 0px',
                        }}
                    >

                        {this.state.view === 'normal' || this.props.table != 'pe_mapa'
                            ? this.renderObjects()
                            : <div style={{ marginTop: 70 }}><MapaTableView id={this.props.id} podeFavoritarObjetivo={this.state.podeFavoritarObjetivo} /></div>}

                        {this.state.visao.template ? <img draggable="false" src={this.state.encodedImage} style={{ width: this.state.width, height: this.state.height, borderRadius: 5 }} /> : null}

                    </div>

                    {this.state.view === 'normal' || this.props.table != 'pe_mapa' ? this.renderArrowsWrapper() : null}
                </div>
            </div>
        )
    }
}

export default withArrowDragHook(DrawDiagram);