import React from "react";
import ReactDOM from "react-dom";
import Colors from '../../constants/Colors';
import LayoutHelper from "../../helper/LayoutHelper";
import EssentialStyle from "../../style/EssentialStyle";

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: LayoutHelper.isSmallScreen(),
        };
    }

    componentDidMount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    modal() {
        return (
            <div style={{ 
                ...EssentialStyle.columnStart, 
                position: "fixed", 
                top: "50%", 
                left: "50%", 
                transform: "translate(-50%, -50%)", 
                minWidth: this.state.isSmallScreen ? 385 : 550, 
                width: this.state.isSmallScreen ? '95vw' : '50vw', 
                backgroundColor: Colors.white, 
                zIndex: 20, 
                borderRadius: 8, 
                boxShadow: `0px 0px 500px 500px rgba(50, 50, 50, 0.2)`, 
                padding: 15,
                ...this.props.style,
            }}>                
                {this.props.children}
            </div>    
        )
    }

    render() {
        const element = document.getElementById('root');

        return (
            ReactDOM.createPortal(
                this.modal(),
                element
            )
        );
    }
}