import React from "react";
import DataHelper from "../../../../helper/DataHelper";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarDays, faTimes, faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import AddTag from "../../../tools/AddTag";
import Select from 'react-select'
import DefaultButton from "../../../tools/DefaultButton";
import moment from "moment";
import Colors from "../../../../constants/Colors";
import Sig from "../../../../api/Sig";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DefaultLoader from "../../../tools/DefaultLoader";
import EssentialStyle from "../../../../style/EssentialStyle";
import LayoutHelper from "../../../../helper/LayoutHelper";
import Tabs from "../../../tools/Tabs";

export default class ProjetoListSearch extends React.Component {
    state = {
        loading: true,
        typingTimeout: null,
        name: this.props.nome,
        status: null,
        objetivos: null,
        colabs: [],
        responsables: [],
        dataInicio: null,
        dataFim: null,
        colabsList: [],
        statusList: [],
        objetivosList: [],
        isSmallScreen: this.props.isSmallScreen,
        type: this.props.type
    }

    async componentDidMount() {
        await this.loadData();

        this.setState({ name: this.props.nome || null });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let req = await Sig.request('GET', 'pe/projeto/getFilterList', { mapaId: this.props.mapaId ? this.props.mapaId : 0 });

        if (req && req.status === 200) {
            this.setState({ colabsList: req.colaboradores, statusList: req.statusList, objetivosList: req.objetivosList });
        }

        this.setState({ loading: false })
    }

    limpar = () => {
        this.setState({
            loading: true,
            name: null,
            status: null,
            colabs: [],
            responsables: [],
            objetivos: null,
            dataInicio: null,
            dataFim: null,
        }, () => { 
            this.filterCallback();
            this.setState({ loading: false }) 
        });
    }

    renderDatePickerPeriodo() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} readOnly />
        ));

        let dataMoment1 = this.state.dataInicio ? moment(this.state.dataInicio).toDate() : null;
        let dataMoment2 = this.state.dataFim ? moment(this.state.dataFim).toDate() : null;

        return (
            <div className="d-flex flex-row gap-3">
                <DatePicker
                    wrapperClassName="w-100"
                    selected={dataMoment1}
                    startDate={dataMoment1}
                    endDate={dataMoment2}
                    selectsRange
                    onChange={(dates) => {
                        const [start, end] = dates;
                        this.setState({ dataInicio: start, dataFim: end });
                    }}
                    dateFormat={this.state.type === 'relatorio' ? "dd/MM/yyyy" : "MM/yyyy"}
                    showMonthYearPicker={this.state.type === 'relatorio' ? false : true} 
                    customInput={<CustomPicker />}
                />
            </div>
        );
    }

    filterCallback = (closeFilter = false) => {
        this.props.filterCallback({
            nome: this.state.name,
            status: this.state.status ? this.state.status.map(item => item.value) : null,
            objetivos: this.state.objetivos ? this.state.objetivos.map(item => item.value) : null,
            responsables: this.state.responsables ? this.state.responsables.map(item => item.value) : null,
            colabs: this.state.colabs ? this.state.colabs.map(item => item.value) : null,
            dataInicio: this.state.dataInicio ? DataHelper.getDefaultDbDateFormat(this.state.dataInicio) : null,
            dataFim: this.state.dataFim ? DataHelper.getDefaultDbDateFormat(this.state.dataFim) : null,
        }, closeFilter);
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        var dataInicio = this.state.dataInicio;
        var dataFim = this.state.dataFim;
        this.setState({ activeTab: tab.name, dataInicio, dataFim });
    }

    renderLoading() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100" style={{ minHeight: 330, ...EssentialStyle.columnCenter }}>
                    <DefaultLoader />
                </Form>
            </div>
        )
    }

    renderNameFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Nome do Projeto</Form.Label>
                <Form.Control className="form-control-xsm" type="text" placeholder="Nome do Projeto..." value={this.state.name || ''} onChange={(event) => { this.setState({ name: event.target.value }) }} />
            </Form.Group>
        );
    }

    renderStatusFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Status</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.statusList, 'id', 'nome')}
                    placeholder={'Selecione o Status'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.status}
                    onChange={(value) => { this.setState({ status: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
                />
            </Form.Group>
        );
    }

    renderObjetivos() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Objetivos</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.objetivosList, 'id', 'nome')}
                    placeholder={'Selecione os Objetivos'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.objetivos}
                    onChange={(value) => { this.setState({ objetivos: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
                />
            </Form.Group>
        );
    }

    renderResponsablesFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Responsável</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.colabsList.filter(colab => !this.state.colabs.flatMap(colab => colab.value).includes(colab.id)), 'id', 'nome')}
                    placeholder={'Selecione os Colaboradores'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.responsables}
                    onChange={(values) => { this.setState({ responsables: values }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}    
                />
            </Form.Group>
        );
    }

    renderPeopleFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Colaboradores</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.colabsList.filter(colab => !this.state.responsables.flatMap(responsavel => responsavel.value).includes(colab.id)), 'id', 'nome')}
                    placeholder={'Selecione os colaboradores'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.colabs}
                    onChange={(value) => { this.setState({ colabs: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
                />
            </Form.Group>
        );
    }

    renderPeriodoFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Período</Form.Label>
                {this.renderDatePickerPeriodo()}
            </Form.Group>
        );
    }

    renderButtons() {
        return (
            <div className="mb-0 d-flex flex-row-reverse">
                <Form.Group>
                    <DefaultButton className="me-1" color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} title={'Limpar'} loading={this.state.loading} onClick={this.limpar} />
                    <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSearch} />} title={'Filtrar'} loading={this.state.loading} onClick={() => this.filterCallback(true)} />
                </Form.Group>
            </div>
        );
    }

    renderDesktop() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="mb-3">
                        {this.renderNameFilter()}
                        {this.renderStatusFilter()}
                    </Row>

                    <Row className="mb-3">
                        {this.renderResponsablesFilter()}
                        {this.renderPeopleFilter()}
                    </Row>

                    <Row className="mb-3">
                        {this.props.mapaId && this.renderObjetivos()}
                        {this.renderPeriodoFilter()}
                    </Row>

                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="box-advancedSearch" style={{ maxHeight: window.innerHeight - 150, overflowY: 'auto' }}>
                <Form className="w-100">
                    <Row className="mb-3">{this.renderNameFilter()}</Row>
                    <Row className="mb-3">{this.state.type != 'lista' && this.renderStatusFilter()}</Row>
                    <Row className="mb-3">{this.renderResponsablesFilter()}</Row>
                    <Row className="mb-3">{this.renderPeopleFilter()}</Row>
                    {this.props.mapaId && <Row className="mb-3">{this.renderObjetivos()}</Row>}
                    <Row className="mb-3">{this.renderPeriodoFilter()}</Row>
                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : (this.state.isSmallScreen ? this.renderMobile() : this.renderDesktop());
    }
}