import React from "react";
import Constants from "../../../../../constants/Api";
import DefaultLoader from "../../../../tools/DefaultLoader";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";

import solidgauge from "highcharts/modules/solid-gauge";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import Colors from "../../../../../constants/Colors";
import Sig from "../../../../../api/Sig";
import moment from "moment";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import SessionHelper from "../../../../../helper/SessionHelper";
import EllipsisText from "../../../../tools/EllipsisText";

highchartsMore(Highcharts);
accessibility(Highcharts);
solidgauge(Highcharts);

const gateway = Constants.getSigEndPoint();

export default class IndicadorGauge extends React.Component {
    state = {
        loading: true,
        metaValor: '--',
        realizadoValor: '--',
        performanceValor: '--%',
        saldo: '--',
        saldoIcon: '',
        desvio: '--',
        desvioIcon: '',
        color: Colors.notSelected,
        progress: 0,
        hover: false,
    }

    async componentDidMount() {
        await this.getIndicador();
    }

    async componentDidUpdate(prevProps) {
        if ((prevProps.id_indicador !== this.props.id_indicador
            && prevProps.id_gmr_painel !== this.props.id_gmr_painel)
            || prevProps.acumulado !== this.props.acumulado
            || prevProps.data !== this.props.data) {
            await this.getIndicador();
        }
    }

    async getIndicador() {
        this.setState({ loading: true });

        let painel = await Sig.request('POST', 'inicio/getAcompanhamentoIndicador', {
            id_gmr_painel: this.props.id_gmr_painel,
            id_indicador: this.props.id_indicador,
            data: moment(this.props.data).unix(),
            acumulado: this.props.acumulado ? 1 : 0
        });
        
        if(painel?.metaPeriodoRaw || painel?.metaPeriodoRaw == 0) {
            this.setState({ 
                metaValor: painel.metaPeriodo || '--',
                metaAjustadaValor: painel.metaAjustadaPeriodo || null,
                realizadoValor: painel.realizadoRealizado || '--',
                performanceValor: this.props.acumulado ? (painel.performanceRealizadoAll || '--%') : (painel.performanceRealizado || '--%'),
                color: painel.performanceRealizadoColor || Colors.notSelected,
                progress: this.props.acumulado ? (painel.performanceRealizadoAllRaw || 0) : (painel.performance || 0),
                saldo: painel.saldoRealizado?.valor || '--',
                saldoIcon: painel.saldoRealizado?.setaReact || '',
                desvio: painel.desvioRealizado?.valor || '--',
                desvioIcon: painel.desvioRealizado?.setaReact || ''

            });
        }

        this.setState({ loading: false });
    }

    renderLoading() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    renderGauge() {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'solidgauge',
                        events: {
                            render: function () {
                                if (this.centerText) {
                                    this.centerText.destroy();
                                }

                                const fontSize = Math.min(this.plotWidth, this.plotHeight) * 0.2;

                                this.centerText = this.renderer.text(
                                    `${this.series[0].points[0].y.toFixed(0)}%`,
                                    this.plotLeft + (this.plotWidth / 2),
                                    this.plotTop + (this.plotHeight / 2) + fontSize / 3
                                )
                                    .attr({
                                        align: 'center',
                                        'text-anchor': 'middle'
                                    })
                                    .css({
                                        fontSize: `${fontSize}px`,
                                        color: Colors.dark,
                                        fontWeight: 'bold'
                                    })
                                    .add();
                            }
                        },
                        backgroundColor: 'transparent',
                        margin: [null, null, null, null],
                        spacing: [3, 0, 3, 0]
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{
                            outerRadius: '112%',
                            innerRadius: '85%',
                            backgroundColor: Colors.notSelected,
                            borderWidth: 0
                        }]
                    },

                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },

                    series: [{
                        name: 'Conversion',
                        data: [{
                            color: this.state.color,
                            radius: '112%',
                            innerRadius: '85%',
                            y: this.state.progress
                        }],
                        custom: {
                            icon: 'filter',
                            iconColor: '#303030'
                        }
                    }]
                }}
            />
        );
    }

    renderChart() {
        const progress = this.state.progress || 0;

        let gaugeLateral = LayoutHelper.isSmallScreen() && this.props.mergulho;

        let nome = (
            <div style={{ ...styles.lineName, display: this.props.nome ? '' : 'none'}}>
                <EllipsisText text={this.props.nome} />
            </div>
        )

        let meta = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.dark,
            }}>
                M {this.state.metaValor}
            </div>
        );

        let metaAjustada = this.state.metaAjustadaValor ? (
            <div style={{...styles.lineData, backgroundColor: Colors.dark }}>
                MA {this.state.metaAjustadaValor}
            </div>
        ) : null;

        let realizado = (
            <div style={{...styles.lineData,
                background: `linear-gradient(to right, ${this.state.color} 0%, ${this.state.color} ${progress}%, ${Colors.notSelected} ${progress}%, ${Colors.notSelected} 100%)`,
            }}>
                R {this.state.realizadoValor}
            </div>
        );

        let gauge = (
            <div style={{ ...styles.lineGauge,
                height: gaugeLateral ? '100%' : `calc(100% - 50px - ${this.state.desvio == true ? '25px' : '0px'} - ${this.state.saldo == true ? '25px' : '0px'} - ${this.props.nome ? '22px' : '0px'}`,
                display: this.props.gauge ? 'flex' : 'none',
            }} >
                {this.renderGauge()}
            </div>
        );

        let desvio = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.notSelected,
                display: this.props.desvio ? 'flex' : 'none',
            }}>
                Desvio {this.state.desvio}
                <FontAwesomeIcon
                    icon={this.state.desvio != '--' ? (this.state?.desvioIcon?.icon == 'faArrowUp' ? faArrowUp : this.state?.desvioIcon?.icon == 'faArrowDown' ? faArrowDown : faMinus) : faMinus}
                    style={{
                        color: this.state?.desvioIcon?.color || 'black',
                        marginRight: 10,
                        marginLeft: 10,
                        fontSize: 12
                    }}
                />
            </div>
        );

        let saldo = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.notSelected,
                marginBottom: 0,
                display: this.props.saldo ? 'flex' : 'none',
            }}>
                Saldo {this.state.saldo}
                <FontAwesomeIcon
                    icon={this.state.saldo != '--' ? (this.state?.saldoIcon?.icon == 'faArrowUp' ? faArrowUp : this.state?.saldoIcon?.icon == 'faArrowDown' ? faArrowDown : faMinus) : faMinus}
                    style={{
                        color: this.state?.saldoIcon?.color || 'black',
                        marginRight: 10,
                        marginLeft: 10,
                        fontSize: 12
                    }}
                />
            </div>
        );

        let style = {
            conitainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 8,
                padding: gaugeLateral ? "5px 0px" : "10px 10px",
                boxShadow: this.state.hover ? Colors.boxShadow : 'none',
                backgroundColor: this.state.hover ? Colors.homePage.extraLightGrey : 'transparent',
                maxWidth: gaugeLateral ? '100%' : '30vw',
            },
            containerInfos: {
                ...EssentialStyle.columnCenter,
                fontSize: 13,
                width: gaugeLateral ? '45%' : '100%',
                height: '100%', 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        }

        return (
            <div style={style.conitainer}
                onMouseEnter={() => {this.setState({ hover: true })}}
                onMouseLeave={() => {this.setState({ hover: false })}}
                onClick={() => {this.props.onClick()}}
            >
                <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '100%'}}>
                    <div style={{ ...style.containerInfos }}>
                        {nome}
                        {meta}
                        {metaAjustada}
                        {realizado}
                        {!gaugeLateral && gauge}
                        {desvio}
                        {saldo}
                    </div>
                    {gaugeLateral && <div style={{ width: '45%', height: '100%' }}>{gauge}</div>}
                </div>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderChart()
    }
}

const styles = {

    lineName: {
        color: Colors.dark,
        fontWeight: 'bold',
        height: 22,
        fontSize: 14,
        textAlign: 'center',
        width: '100%',
        cursor: 'default',
        whiteSpace: 'normal'
    },
    lineData: {
        ...EssentialStyle.rowFlexCenter,
        width: '100%',
        height: 'calc(20% - 2px)',
        maxHeight: 25,
        minHeight: 15,
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.white,
        borderRadius: 8,
        marginBottom: 2,
        cursor: 'default',
    },
    lineGauge: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    }
}