import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import { debounce } from 'lodash';


export default class PlanoAcaoRelatorioStatus extends React.Component {
    constructor(props) {
        super(props);
        this.debouncedLegendItemClick = debounce(this.legendItemClick, 150);
        this.chartRefStatus = React.createRef();
        this.chartRefTipo = React.createRef();
    }

    state={
        expanded: true,
        dataStatus: [],
        dataTipo: [],
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        tipo: [
            "Ação de Melhoria",
            "Ação Corretiva",
            "Ação Preventiva",
            "Desenvolvimento Individual",
            "Marco de Projeto"
        ],
        loading: true,
    }

    componentDidMount(){
        this.setState({ loading: true }, () => {
            this.calculaGraficoPlanoStatus();
            this.calculaGraficoPlanoTipo();
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.planos !== this.props.planos){
            this.calculaGraficoPlanoStatus();
            this.calculaGraficoPlanoTipo();
        }
        if(prevProps.filter !== this.props.filter) {
            this.updateGraficos();
        }
    }

    updateGraficos() {
        var statusIsFiltered = this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length == 1 && this.props.filter?.values.every(v => v.field == "status");
        var tipoIsFiltered = this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length == 1 && this.props.filter?.values.every(v => v.field == "tipoName");

        if(!statusIsFiltered && this.chartRefStatus?.current) {
            this.chartRefStatus.current.chart.series[0].points.forEach(point => point.setVisible(true));
        } 
        if(!tipoIsFiltered && this.chartRefTipo?.current) {
            this.chartRefTipo.current.chart.series[0].points.forEach(point => point.setVisible(true));
        }
    }

    calculaGraficoPlanoStatus(){
        if(!this.props.planos || this.props.planos.length === 0) return;

        let dataStatus = [];

        let planos = this.props.planos;

        this.state.status.forEach(status => {
            let planosStatus = planos.filter(plano => plano.status === status);
            dataStatus.push({
                name: status,
                y: planosStatus.length || 0,
                yPercent: Math.round((planosStatus.length / planos.length) * 100),
                color: ChartHelper.getColor(status),
            });
        });

        this.setState({ dataStatus });
    }

    calculaGraficoPlanoTipo(){
        if(!this.props.planos || this.props.planos.length === 0) return;

        let dataTipo = [];

        let planos = this.props.planos;

        this.state.tipo.forEach(tipo => {
            let planosTipo = planos.filter(plano => plano.tipoName === tipo);
            dataTipo.push({
                name: tipo,
                y: planosTipo.length || 0,
                yPercent: Math.round((planosTipo.length / planos.length) * 100),
            });
        });

        this.setState({ dataTipo });
    }

    renderGraficoPlanosStatus = () => {
        var width = this.props.isSmallScreen ? '100%' : 'unset';
        var height = this.props.isSmallScreen ? 300 : 200;

        let that = this;
        return (
            <div
                style={{ display: 'flex', flexDirection: "column", width: width, justifyContent: 'center', flex: 1, maxWidth: 500, minWidth: 300, minHeight: height}}
            >
                <span style={{fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, textAlign: "center"}}>Status</span>
                <HighchartsReact
                    ref={this.chartRefStatus}
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: height, width: "100%", minHeight: height } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.props.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            }
                        },
                        legend: {
                            align: `${this.props.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return this.name + ': ' + this.y;
                            },
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                         legendItemClick: function (e) {
                                            e.preventDefault();
                                            that.debouncedLegendItemClick(that, this, "status");
                                        },
                                        click: function (e) {
                                            that.debouncedLegendItemClick(that, this, "status");
                                        }
                                    }
                                },
                            }
                        },
                        series: [{
                            minPointSize: 30,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.dataStatus,
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        );
    }

    renderPlanosCounter = () => {
        var isNotFiltered = this.props.filter?.view == "planos" && (!this.props.filter?.values || this.props.filter?.values.length == 0);
        return (
            <div style={{...EssentialStyle.columnCenter, width: 250, height: 200, color: Colors.homePage.grey , filter: isNotFiltered && "brightness(0.8)", gap: 5, cursor: "pointer"}} onClick={() => this.props.filterCallback({view: 'planos', values: []})}>
                <div style={{fontSize: 100, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%"}}>
                    {this.props.planos?.length}
                </div>
                <div style={{fontSize: 26, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%"}}>
                    Planos de Ação
                </div>
            </div>
        )
    }

    legendItemClick(componentContext, context, fieldType) {
        var isFiltered = false;
        if(componentContext.props.filter?.view == "planos" && componentContext.props.filter?.values && componentContext.props.filter?.values.length > 0) {
            isFiltered = componentContext.props.filter?.values.some(v => v.field == fieldType && v.value == context.name);
        }
        var series = context.series;
        var clickedPoint = context;
        if(isFiltered) {
            series.points.forEach(point => point.setVisible(true));
        } else {
            series.points.forEach(point => point.setVisible(false));
            clickedPoint.setVisible(true);
        }
        componentContext.props.filterCallback({view: 'planos', values: isFiltered ? [] : [{field: fieldType, value: context.name}]});
    }


    renderGraficoPlanosTipo = () => {
        var width = this.props.isSmallScreen ? '100%' : 'unset';
        var height = this.props.isSmallScreen ? 300 : 200;

        let that = this;
        return (
            <div
                style={{ display: 'flex', flexDirection: "column", width: width, justifyContent: 'center', flex: 1, maxWidth: 500, minWidth: 300, minHeight: height}}
            >
                <span style={{fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, textAlign: "center"}}>Tipos</span>
                <HighchartsReact
                    ref={this.chartRefTipo}
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: height, width: "100%", minHeight: height } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.props.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            }
                        },
                        legend: {
                            align: `${this.props.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return this.name + ': ' + this.y;
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                        legendItemClick: function (e) {
                                            e.preventDefault();
                                            that.debouncedLegendItemClick(that, this, "tipoName");
                                        },
                                        click: function (e) {
                                            that.debouncedLegendItemClick(that, this, "tipoName");
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            minPointSize: 30,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.dataTipo
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        );
    }

    renderStatus = () => {
        if(this.state.loading) {
                return (
                <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                    <DefaultLoader />
                </div>
            );
        }

        return (
            <div className="pa-status-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Status' : 'Status'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceAround), width: '100%', padding: 10, paddingTop: 0, gap: 15, padding: this.props.isSmallScreen ? 5 : "10px 20px", flexWrap: "wrap"}}>
                                {this.renderGraficoPlanosStatus()}
                                {this.renderPlanosCounter()}
                                {this.renderGraficoPlanosTipo()}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderStatus()
        )
    }
}