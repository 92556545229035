import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';

export default class PlanoAcaoRelatorioDetails extends React.Component {

    state={
        contadores: {},
        expanded: false,
        tipoVinculos: [	
            "Sem Vinculo",
            "Gestão de Metas e Resultados",
            "Planejamento Estratégico de Gestão",
            "Pesquisa Organizacional",
            "Avaliação de Competências",
            "Análise de Problema",
            "Feedback Contínuo",
            "Marco de Projeto",
            "Diagnóstico",
        ],
        validacao: [
            'Aprovação não Solicitado',
            'Solicitação de Aprovação Não Respondida',
            'Aprovado',
            'Rejeitado',
            'Parcialmente Aprovado',
        ],
        tendencia: [
            "Tendência de Eficácia",
            "Tendência de Ineficácia",
            "Inalterado",
            "Sem Informação",
        ],
        eficacia: [
            'Eficaz',
            'Parcialmente Eficaz',
            'Não Eficaz',
        ],
        loading: true,
    }

    componentDidMount(){
        this.calculaContadores();
    }

    componentDidUpdate(prevProps){
        if(prevProps.planos !== this.props.planos){
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var contadores = {
            semVinculo: 0,
            totalVinculados: 0,
            vinculados: {
                "Análise de Problema": 0,
                "Avaliação de Competências": 0,
                "Gestão de Metas e Resultados": 0,
                "Pesquisa Organizacional": 0,
                "Planejamento Estratégico de Gestão": 0,
                "Feedback Contínuo": 0,
                "Diagnóstico": 0,
            },
            totalValidacao: 0,
            validacao: {
                'Aprovação não Solicitado': 0,
                'Solicitação de Aprovação Não Respondida': 0,
                'Aprovado': 0,
                'Rejeitado': 0,
                'Parcialmente Aprovado': 0,
            },
            eficacia: {
                'Eficaz': 0,
                'Parcialmente Eficaz': 0,
                'Não Eficaz': 0,
            },
            tendencia: {
                "Tendência de Eficácia": 0,
                "Tendência de Ineficácia": 0,
                "Inalterado": 0,
                "Sem Informação": 0,
            },
        }

        if(!this.props.planos || this.props.planos.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        } 

        let planos = this.props.planos;

        planos.forEach(plano => {

            if(!plano.vinculos || plano.vinculos.length === 0) {
                contadores.semVinculo++;
            } else {
                contadores.totalVinculados++;
                this.state.tipoVinculos.forEach((tipoVinculo, index) => {
                    if(plano.vinculos.filter(vinculo => vinculo.tipo == index).length > 0) 
                        contadores.vinculados[tipoVinculo]++;
                });
            }

            if(plano.verificacao){
                switch  (plano.verificacao.eficaz) {
                    case '0':
                        contadores.eficacia['Eficaz']++;
                        break;
                    case '1':
                        contadores.eficacia['Parcialmente Eficaz']++;
                        break;
                    case '2':
                        contadores.eficacia['Não Eficaz']++;
                        break;
                    default:
                        break;
                }
            }

            if(plano.aprovacao && plano.superior){
                contadores.totalValidacao++;
                contadores.validacao[plano.aprovacao.status_text]++;
            }

            if((plano.status != 'Concluído' && plano.status != 'Cancelado') && !plano.verificacao){
                if(plano.tendencia)
                    contadores.tendencia[plano.tendencia.msg]++;
                else
                    contadores.tendencia['Sem Informação']++;
                }   
            
            }
        );
        this.setState({ contadores, loading: false });
    }

    getValidacaoText = (validacao) => {
        switch(validacao) {
            case 'Aprovação não Solicitado':
                return 'Aprovação Não Solicitada';
            case 'Solicitação de Aprovação Não Respondida':
                return 'Aprovação Não Respondida';
            case 'Aprovado':
                return 'Aprovado';
            case 'Rejeitado':
                return 'Rejeitado';
            case 'Parcialmente Aprovado':
                return 'Parcialmente Aprovado';
            default:
                return validacao;
        }
    }

    fieldIsFiltered = (fieldFilter) => {
        var isFiltered = false;
        if(this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length > 0){
            isFiltered = this.props.filter?.values.every(value => JSON.stringify(value) == JSON.stringify(fieldFilter));
        }
        return isFiltered;
    }

    renderDetails = () => {
        if(this.state.loading) {
                return (
                <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                    <DefaultLoader />
                </div>
            );
        }

        if(!this.state.contadores || Object.keys(this.state.contadores).length === 0) return null;

        return (
            <div className="pa-details-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Detalhamento' : 'Detalhamento'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%', padding: 10, paddingTop: 0, gap: 15}}>
                                {this.state.loading ? 
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                                        <DefaultLoader />
                                    </div>
                                : 
                                    <div style={{...EssentialStyle.rowSpaceAround, gap: 15, flexWrap: "wrap", paddingBottom: 5}}>
                                        <div style={{...EssentialStyle.columnCenter, gap: 5}}>
                                            <span style={{fontSize: 18, fontWeight: 500, color: Colors.homePage.grey}}>Vínculos</span>
                                            <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter  : EssentialStyle.rowFlexCenter), width: this.props.isSmallScreen ? "100%" : "auto", gap: 10}}>    
                                                <div 
                                                    onClick={() => {
                                                        if(this.fieldIsFiltered({ field: 'vinculos', value: 0 })) 
                                                            this.props.filterCallback({view: "planos", values:[]});
                                                        else
                                                            this.props.filterCallback({view: "planos", values:[{ field: 'vinculos', value: 0 }]})
                                                    }}
                                                    style={{
                                                        ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderRadius: 8, zIndex: this.fieldIsFiltered({ field: 'vinculos', value: 0 }) ? 2 : 1,
                                                        backgroundColor: this.fieldIsFiltered({ field: 'vinculos', value: 0 }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey, 
                                                        height: 200, width: 150, gap: 5, padding: 5, cursor: "pointer", outline: this.fieldIsFiltered({ field: 'vinculos', value: 0 }) ? `2px solid ${Colors.dark}` : "unset"}}
                                                >
                                                    <span style={{fontSize: 50, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey}}>
                                                        {this.state.contadores.semVinculo}
                                                    </span>
                                                    <span style={{fontSize: 18, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey}}>
                                                        Sem Vínculo com Resultados
                                                    </span>
                                                </div>
                                                <div style={{...EssentialStyle.rowFlexCenter}}>
                                                    <div 
                                                        onClick={() => {
                                                            if(this.fieldIsFiltered({ field: 'vinculos', value: 0, operation: '>' })) 
                                                                this.props.filterCallback({view: "planos", values:[]});
                                                            else
                                                                this.props.filterCallback({view: "planos", values:[{ field: 'vinculos', value: 0, operation: '>' }]})
                                                        }}
                                                        style={{
                                                            ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, zIndex: this.fieldIsFiltered({ field: 'vinculos', value: 0, operation: '>'}) ? 2 : 1,
                                                            backgroundColor: this.fieldIsFiltered({ field: 'vinculos', value: 0, operation: '>' }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey, 
                                                            height: 200, width: 150, gap: 5, padding: 5, cursor: "pointer", outline: this.fieldIsFiltered({ field: 'vinculos', value: 0, operation: '>' }) ? `2px solid ${Colors.dark}` : "unset"}}
                                                    >
                                                        <span style={{fontSize: 50, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey}}>
                                                            {this.state.contadores.totalVinculados}
                                                        </span>
                                                        <span style={{fontSize: 18, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey}}>
                                                            Vinculados com Resultados
                                                        </span>
                                                    </div>
                                                    <div style={{...EssentialStyle.columnCenter, height: 200, width: 300, borderTopRightRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderLeft: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: 'collapse'}}>
                                                        {this.state.tipoVinculos.map((tipo, index) => {
                                                            if(index == 0 || index == 7) return null;
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        if(this.fieldIsFiltered({ field: 'vinculo', value: index }))
                                                                            this.props.filterCallback({view: "planos", values:[]});
                                                                        else
                                                                            this.props.filterCallback({view: "planos", values:[{ field: 'vinculo', value: index }]})
                                                                    }} 
                                                                    key={"tipo-"+index} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, outline: this.fieldIsFiltered({ field: 'vinculo', value: index }) ? `2px solid ${Colors.dark}` : "unset", borderTopRightRadius: index == 1 ? 8 : 0, zIndex: this.fieldIsFiltered({ field: 'vinculo', value: index }) ? 2 : 1,
                                                                        borderBottom: index != (this.state.tipoVinculos.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", width: "100%", flex: 1, borderBottomRightRadius: index == (this.state.tipoVinculos.length - 1) ? 8 : 0,
                                                                        textAlign: 'center', cursor: "pointer", backgroundColor: this.fieldIsFiltered({ field: 'vinculo', value: index }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey}}
                                                                >
                                                                    <span style={{alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}`}}>
                                                                        {this.state.contadores.vinculados[tipo]}
                                                                    </span>
                                                                    <span style={{alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%",}}>
                                                                        {tipo}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{...EssentialStyle.columnCenter, gap: 5}}>
                                            <span style={{fontSize: 18, fontWeight: 500, color: Colors.homePage.grey}}>Verificação de Eficácia</span>
                                            <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: this.props.isSmallScreen ? "100%" : "auto", gap: 10, flexWrap: "wrap"}}>  
                                                <div style={{...EssentialStyle.columnCenter, height: 200, width: 250}}>
                                                    <div
                                                        onClick={() => {
                                                            if(this.fieldIsFiltered({ field: 'superior' })) 
                                                                this.props.filterCallback({view: "planos", values:[]});
                                                            else
                                                                this.props.filterCallback({view: "planos", values:[{ field: 'superior' }]})
                                                        }} 
                                                        style={{
                                                            ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8, 
                                                            backgroundColor: this.fieldIsFiltered({ field: 'superior' }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey, 
                                                            width: "100%", gap: 2, padding: 5, cursor: "pointer", zIndex: this.fieldIsFiltered({ field: 'superior' }) ? 2 : 1, outline: this.fieldIsFiltered({ field: 'superior' }) ? `2px solid ${Colors.dark}` : "unset"}}
                                                    >
                                                        <span style={{fontSize: 40, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey}}>
                                                            {this.state.contadores.totalValidacao}
                                                        </span>
                                                        <span style={{fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey}}>
                                                            Com Superior / Avaliador
                                                        </span>
                                                    </div>
                                                    <div style={{...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1}}>
                                                        {this.state.validacao.map((validacao, index) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        if(this.fieldIsFiltered({ field: 'aprovacao', value: validacao }))
                                                                            this.props.filterCallback({view: "planos", values:[]});
                                                                        else
                                                                            this.props.filterCallback({view: "planos", values:[{ field: 'aprovacao', value: validacao }]})
                                                                    }} 
                                                                    key={"validacao-"+index} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.validacao.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", borderBottomLeftRadius: index == (this.state.validacao.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.validacao.length - 1) ? 8 : 0, 
                                                                        width: "100%", flex: 1, textAlign: 'center', cursor: "pointer", backgroundColor: this.fieldIsFiltered({ field: 'aprovacao', value: validacao }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                                        outline: this.fieldIsFiltered({ field: 'aprovacao', value: validacao }) ? `2px solid ${Colors.dark}` : "unset", zIndex: this.fieldIsFiltered({ field: 'aprovacao', value: validacao }) ? 2 : 1
                                                                    }}
                                                                >
                                                                    <span style={{alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}`}}>
                                                                        {this.state.contadores.validacao[validacao]}
                                                                    </span>
                                                                    <span style={{alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%",}}>
                                                                        {this.getValidacaoText(validacao)}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div style={{...EssentialStyle.columnCenter, height: 200, width: 250}}>
                                                    <div style={{...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: Colors.homePage.extraLightGrey, width: "100%", gap: 2, padding: 5}}>
                                                        <span style={{fontSize: 25, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey}}>
                                                            Tendência
                                                        </span>
                                                        <span style={{fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey}}>
                                                            Planos não Concluídos / Verificados
                                                        </span>
                                                    </div>
                                                    <div style={{...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1}}>
                                                        {this.state.tendencia.map((tendencia, index) => {
                                                            return (
                                                                <div 
                                                                    onClick={() => {
                                                                        if(this.fieldIsFiltered({ field: 'tendencia', value: tendencia }))
                                                                            this.props.filterCallback({view: "planos", values:[]});
                                                                        else
                                                                            this.props.filterCallback({view: "planos", values:[{ field: 'tendencia', value: tendencia }]})
                                                                    }}
                                                                    key={"tendencia-"+index} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.tendencia.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", zIndex: this.fieldIsFiltered({ field: 'tendencia', value: tendencia }) ? 2 : 1,
                                                                        width: "100%", flex: 1, textAlign: 'center', cursor: "pointer", backgroundColor: this.fieldIsFiltered({ field: 'tendencia', value: tendencia }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                                        outline: this.fieldIsFiltered({ field: 'tendencia', value: tendencia }) ? `2px solid ${Colors.dark}` : "unset", borderBottomLeftRadius: index == (this.state.tendencia.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.tendencia.length - 1) ? 8 : 0,}}
                                                                >
                                                                    <span style={{alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}`}}>
                                                                        {this.state.contadores.tendencia[tendencia]}
                                                                    </span>
                                                                    <span style={{alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%",}}>
                                                                        {tendencia}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div style={{...EssentialStyle.columnCenter, height: 200, width: 250}}>
                                                    <div style={{...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: Colors.homePage.extraLightGrey, width: "100%", gap: 2, padding: 5}}>
                                                        <span style={{fontSize: 25, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey}}>
                                                            Eficácia
                                                        </span>
                                                        <span style={{fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey}}>
                                                            Planos Concluídos e com Eficácia Verificada
                                                        </span>
                                                    </div>
                                                    <div style={{...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1}}>
                                                        {this.state.eficacia.map((eficacia, index) => {
                                                            return (
                                                                <div 
                                                                    onClick={() => {
                                                                        if(this.fieldIsFiltered({ field: 'verificacao', value: index }))
                                                                            this.props.filterCallback({view: "planos", values:[]});
                                                                        else
                                                                            this.props.filterCallback({view: "planos", values:[{ field: 'verificacao', value: index }]})
                                                                    }}
                                                                    key={"eficacia-"+index} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.eficacia.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", zIndex: this.fieldIsFiltered({ field: 'verificacao', value: index }) ? 2 : 1,
                                                                        width: "100%", flex: 1, textAlign: 'center', cursor: "pointer", backgroundColor: this.fieldIsFiltered({ field: 'verificacao', value: index }) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                                        outline: this.fieldIsFiltered({ field: 'verificacao', value: index }) ? `2px solid ${Colors.dark}` : "unset", borderBottomLeftRadius: index == (this.state.eficacia.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.eficacia.length - 1) ? 8 : 0,}}
                                                                >
                                                                    <span style={{alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}`}}>
                                                                        {this.state.contadores.eficacia[eficacia]}
                                                                    </span>
                                                                    <span style={{alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%",}}>
                                                                        {eficacia}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderDetails()
        )
    }
}