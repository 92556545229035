import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faPlusSquare, faSearch, faAnglesUp, faStar } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import Sig from "../../api/Sig";
import EssentialStyle from "../../style/EssentialStyle";
import ProjetoTableView from "../../components/modules/pe/projeto/ProjetoTableView";
import DefaultLoader from "../../components/tools/DefaultLoader";
import ProjetoCard from "../../components/modules/pe/projeto/ProjetoCard";
import GutMatrix from "../../components/tools/GutMatrix";
import DataHelper from "../../helper/DataHelper";
import Select from 'react-select'
import { toast } from 'react-toastify';
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Constants from "../../constants/Api";
import LayoutHelper from "../../helper/LayoutHelper";
import AddProjeto from "../../components/forms/pe/add/AddProjeto";
import Tabs from "../../components/tools/Tabs";
import TimelineProjetos from "../../components/modules/pe/projeto/TimelineProjetos/TimelineProjetos";
import CustomTooltip from "../../components/tools/CustomTooltip";
import ProjetoListSearch from "../../components/modules/pe/projeto/ProjetoListSearch";

const gateway = Constants.getSigEndPoint();
export default class ProjetoPageDetalhar extends React.Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.headerRef = React.createRef();
        this.interval = null;
        this.searchTimeout = null;
    }

    state = {
        loading: true,
        loadingBody: false,
        projetos: [],
        preSelectedMapa: null,
        mapasUser: [],
        type: 'star', // or 'prioritization',
        view: 'normal', // or 'table',
        showSettings: false,
        acesso: null,
        permissao: null,
        temRestricoes: null,
        restricoes: null,
        podeEditarPriorizacao: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        addProjetoSemVinculo: false,
        hasMore: true,
        page: 1,
        headerSize: 0,
        activeTabGranularidade: 'ano',

        // Buscador
        viewSearch: false,
        filter: {}
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        this.interval = setInterval(() => {
            if (this.scrollRef.current && this.headerRef.current) {
                this.scrollRef.current.addEventListener('scroll', this.handleScroll);
                this.setState({ headerSize: this.headerRef.current.offsetHeight + 10 });
                clearInterval(this.interval);
            }
        }, 100);
        await this.loadData();
    }

    componentDidUpdate() {
        if (this.state.firstLoad && this.state.loadingBody) {
            this.interval = setInterval(() => {
                if (this.scrollRef.current) {
                    this.scrollRef.current.addEventListener('scroll', this.handleScroll);
                    this.setState({ headerSize: this.headerRef.current.offsetHeight + 10 });
                    clearInterval(this.interval);
                }
            }, 100);
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        if (this.scrollRef.current)
            this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (this.scrollRef.current.offsetHeight + this.scrollRef.current.scrollTop > (this.scrollRef.current.scrollHeight - 10)) {
            if (!this.state.loadingBody && this.state.hasMore) {
                this.loadProjetos(this.state.page + 1);
            }
        }
    }

    verifyScreen = () => {
        var headerSize = this.state.headerSize;
        if (this.headerRef.current && headerSize != this.headerRef.current.offsetHeight) {
            headerSize = this.headerRef.current.offsetHeight + 5;
        }
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData(mapaId = null) {
        this.setState({ loading: true });

        let { mapasUser, preSelectedMapa, type } = await Sig.request('GET', 'pe/mapa/getMapaUser', { mapaId: mapaId ? mapaId.value : null, select: true });

        mapasUser.unshift({ "label": "Projetos Sem Vinculos", "value": null });

        if (mapaId && mapaId.value == null) {
            preSelectedMapa = { "label": "Projetos Sem Vinculos", "value": null };
        }

        if (!preSelectedMapa && mapasUser.length > 0) {
            preSelectedMapa = mapasUser[0];
        }

        this.setState({ mapasUser, preSelectedMapa, type: type == 0 ? 'star' : 'prioritization', loading: false }, () => {
            this.state.preSelectedMapa != null && this.loadProjetos();
        });
    }

    async loadProjetos(page = 1, limit = 10) {
        if (this.state.loadingBody) return;

        let view = this.state.view;

        if (this.state.preSelectedMapa.value == null) {
            view = 'normal';
        }

        this.setState({ loadingBody: true, view });

        let {
            projetos,
            podeEditarPriorizacao,
            type
        } = await Sig.request('GET', 'pe/projeto/getProjetosMapa', {
            id_pe_mapa: this.state.preSelectedMapa.value,
            limit: limit,
            page: page,
            searchText: this.state.filter.nome ? this.state.filter.nome : '',
            filter: JSON.stringify(this.state.filter)
        });

        if (projetos.length < 10) {
            this.setState({ hasMore: false });
        } else {
            this.setState({ hasMore: true });
        }

        this.setState({
            page: page,
            firstLoad: false,
            projetos: page === 1 ? projetos : [...this.state.projetos, ...projetos],
            podeEditarPriorizacao,
            type: type == 0 ? 'star' : 'prioritization',
            loadingBody: false
        });
    }

    calbackDelete = async (id) => {
        let projetos = this.state.projetos.filter((projeto) => projeto.id != id);

        if (projetos.length < 10) {
            await this.loadProjetos();
            return;
        }
        this.setState({ projetos });
    }

    gutCallback = async () => {
        this.setState({ showSettings: false });
        await this.loadData(this.state.preSelectedMapa);
    }

    handleView() {

        let view = this.state.view;
        view = view === 'normal' ? 'table' : 'normal';
        this.setState({ view }, () => {
            if (view === 'normal') {
                this.setState({ firstLoad: true }, this.loadProjetos);
            }
        });
    }

    async saveType() {

        let { response } = await Sig.request('POST', 'pe/mapa/setPortfolioConfig', { type: this.state.type == 'star' ? 0 : 1, id_pe_mapa: this.state.preSelectedMapa.value });
        if (!response) {
            toast.error('Não foi possível savar o ultimo estado do tipo de priorização selecionado');
        }
    }

    renderLoadingBody() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: 'calc(100vh - 80px)' }}>
                <DefaultLoader size={32} title={"Carregando ..."} />
            </div>
        )
    }

    renderBody() {
        if (this.state.loadingBody && this.state.firstLoad) return this.renderLoadingBody();
        if (this.state.projetos?.length <= 0 && (!this.state.filter.nome || this.state.filter.nome == "")) return this.renderEmpty();
        if (this.state.projetos?.length <= 0) return this.renderEmptySearch();
        if (this.state.view === 'table') return <ProjetoTableView
                                                    id={this.state.preSelectedMapa.value} 
                                                    loading={this.state.loadingBody} 
                                                    style={{maxHeight: `calc(100% - ${this.state.headerSize}px - ${this.state.isSmallScreen ? 20 : 0}px)`, height: `calc(100% - ${this.state.headerSize}px - ${this.state.isSmallScreen ? 20 : 0}px)`}}
                                                />
        if (this.state.view === 'timeline') return <TimelineProjetos
            idMapa={this.state.preSelectedMapa.value}
            qtdMonthsStart={6}
            qtdMonthsEnd={16}
            softBorder
            granularidade={this.state.activeTabGranularidade}
            style={{ maxHeight: `calc(100vh - ${this.state.headerSize}px)`, minHeight: "unset", height: "auto", width: "100%", maxWidth: "100%", borderRadius: 8, padding: 10 }}
        />

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, margin: 0, paddingBottom: this.state.isSmallScreen ? 30 : 0, padding: 0, backgroundColor: 'transparent', boxShadow: 'none', height: `calc(100vh - ${this.state.headerSize}px)` }}>
                <div ref={this.scrollRef} style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    overflowY: 'auto',
                    flexDirection: 'column',
                    marginTop: 20
                }}>
                    {this.state.projetos.map((projeto) => (<ProjetoCard key={`${projeto.id}-projeto-key`} id={projeto?.id} priorization={this.state.type} calbackDelete={this.calbackDelete} />))}
                    {this.state.loadingBody && <DefaultLoader size={32} title={"Carregando ..."} />}
                    {!this.state.loadingBody && <div style={{minHeight: 90}}>&nbsp;</div>}
                </div>
            </div>
        )
    }

    handleSearch() {
        this.setState({ viewSearch: !this.state.viewSearch });
    }

    filter = async (filter) => {
        this.setState({
            filter: filter
        }, () => {
            this.loadProjetos();
            this.setState({ viewSearch: false });
        });
    }

    getFilterCounter = () => {
        let counter = 0;

        for (let key in this.state.filter) {
            if (this.state.filter[key] && this.state.filter[key].length > 0) {
                counter++;
            }
        }

        return counter
    }

    renderHeader() {

        let localStyle = {
            padding: 10,
            backgroundColor: 'white',
            boxShadow: Colors.boxShadow,
            border: '0.3px solid lightgrey',
            borderRadius: 8,
            ...EssentialStyle.rowFlexCenter,
            flexWrap: 'wrap',
            gap: 10
        }

        return (
            <div>
                <div ref={this.headerRef} style={localStyle}>
                    <div style={{ ...EssentialStyle.rowFlexStart, width: this.state.isSmallScreen ? '100%' : '', flex: 1 }}>
                        {this.renderSelectMapa()}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', justifyContent: 'flex-end' }}>
                        <DefaultButton
                            id={'switch-prioridade-view'}
                            leftIcon={
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <CustomTooltip
                                        tooltip={'Marcar com Estrela'}
                                        placement={'left'}
                                    >
                                        <FontAwesomeIcon icon={faStar} />
                                    </CustomTooltip>
                                    <Form.Switch
                                        type="switch"
                                        disabled={!this.state.podeEditarPriorizacao}
                                        checked={this.state.type === 'prioritization'}
                                        style={{ marginLeft: 3, marginRight: 3 }}
                                    />
                                    <CustomTooltip
                                        tooltip={'Matriz de Priorização'}
                                        placement={'left'}
                                    >
                                        <FontAwesomeIcon icon={faAnglesUp} />
                                    </CustomTooltip>
                                </div>
                            }
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            onClick={() => { this.setState({ loadingBody: true, type: this.state.type === 'star' ? 'prioritization' : 'star' }, () => { this.setState({ loadingBody: false }); this.saveType(); }) }}
                        />

                        <DefaultButton
                            tooltip={this.state.preSelectedMapa == null ? 'Sem Projetos para configurar Matriz de Priorização' : (this.state.podeEditarPriorizacao ? 'Configurações da Matriz de Priorização' : 'Sem Permissão Para Editar Matriz de Priorização')}
                            tooltipPlacement={'bottom'}
                            leftIcon={<FontAwesomeIcon icon={faLayerGroup} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            disabled={!this.state.podeEditarPriorizacao}
                            onClick={() => { this.setState({ showSettings: true }) }}
                            style={{ marginLeft: 10 }}
                        />

                    </div>

                    {this.state.view == 'normal' &&
                        <DefaultButton
                            tooltip={"Buscar Projeto"}
                            tooltipPlacement={'left'}
                            leftIcon={
                                <div>
                                    <FontAwesomeIcon icon={faSearch} />
                                    {this.getFilterCounter() > 0 &&
                                        <div style={{
                                            position: 'absolute',
                                            paddingLeft: 15,
                                            top: 5
                                        }}>
                                            <div style={{
                                                backgroundColor: Colors.error,
                                                width: 18,
                                                height: 18,
                                                borderRadius: 8,
                                                fontSize: 12,
                                                color: Colors.white
                                            }}>
                                                {this.getFilterCounter()}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            disabled={this.state.preSelectedMapa == null}
                            loading={this.state.loading}
                            onClick={() => { this.handleSearch(); }} />
                    }

                    <Tabs
                        activeTab={this.state.view}
                        tabs={[
                            { name: 'normal', title: 'Lista' },
                            { name: 'table', title: 'Tabela' },
                            { name: 'timeline', title: 'Timeline' }
                        ]}
                        onTabChange={(tab) => { this.setState({ view: tab.name, firstLoad: true }, this.loadProjetos) }}
                        isSmallScreen={this.state.isSmallScreen}
                        style={{ width: 'auto', marginTop: 0 }}
                        tabStyle={{ width: this.state.isSmallScreen ? '25vw' : 85, cursor: 'pointer' }}
                    />
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={14}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={"https://advis.freshdesk.com/support/solutions/articles/63000283268-portf%C3%B3lio-de-projetos"}
                    />
                    {this.state.view === 'timeline' &&
                        <Tabs
                            activeTab={this.state.activeTabGranularidade}
                            tabs={[
                                { name: 'ano', title: 'Ano' },
                                { name: 'mes', title: 'Mês' },
                                { name: 'semana', title: 'Semana' }
                            ]}
                            onTabChange={(tab) => { this.setState({ activeTabGranularidade: tab.name }) }}
                            isSmallScreen={this.state.isSmallScreen}
                            style={{ width: 'auto', marginTop: 0 }}
                            tabStyle={{ width: this.state.isSmallScreen ? '25vw' : 85, cursor: 'pointer' }}
                        />
                    }
                </div>

                {this.state.view == 'normal' &&
                    <Collapse in={this.state.viewSearch}>
                        <div style={{ position: "absolute", zIndex: 6, width: "100%" }}>
                            <ProjetoListSearch
                                filterCallback={this.filter}
                                isSmallScreen={this.state.isSmallScreen}
                                type={this.state.view}
                                nome={this.state.filter.nome}
                                advancedFilter={this.state.viewSearch}
                                mapaId={this.state.preSelectedMapa.value}
                            />
                        </div>
                    </Collapse>
                }

            </div>
        )
    }

    renderSelectMapa() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                <Form.Group>
                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', }), menuPortal: (base) => ({ ...base, zIndex: 20 }) }}
                        options={this.state.mapasUser}
                        value={this.state.preSelectedMapa}
                        onChange={(valueSelected) => {
                            this.setState({ preSelectedMapa: valueSelected, firstLoad: true }, () => {
                                this.loadProjetos();
                            });
                        }}
                        placeholder={'Selecione um Mapa Estratégico'}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
                </Form.Group>
            </div>
        )
    }

    renderButtonAddProjeto() {

        return (
            <DefaultButton
                color={Colors.success}
                title={'Adicionar Projeto'}
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                loading={this.state.loading}
                onClick={() => {
                    if (this.state.preSelectedMapa.value == null) {
                        this.setState({ addProjetoSemVinculo: true });
                    } else {
                        window.parent.postMessage({ type: 'navigate', url: '/pe/projeto/criar' }, '*');
                    }
                }} />
        )
    }

    renderDetails() {
        return (
            <div style={{ height: "100vh" }}>
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        )
    }

    renderSettings() {
        return (
            <GutMatrix idPeProjeto={this.state.preSelectedMapa.value} gutProjeto gutCallback={this.gutCallback} />
        );
    }

    renderEmptySearch() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', margin: "20px 10px 0px 10px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5 }}>
                <img
                    src={`${gateway}/img/undraw/undraw_search_engines.svg`}
                    alt="Imagem de um buscador sem resultados"
                    style={{
                        height: '40vh',
                        maxHeight: 120,
                        marginBottom: 10,
                    }}
                />
                <span style={{ ...EssentialStyle.titleBoxHomePage, marginBottom: 5 }}>
                    Nenhum <strong>Projeto</strong> encontrado com este filtro neste Mapa Estratégico!
                </span>
            </div>
        )
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', margin: "20px 10px 0px 10px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5 }}>
                <h5>Sua empresa ainda não criou nenhum <strong>Projeto</strong> para o que você selecionou, ou você não tem acesso a eles!</h5>
                <div style={{ marginTop: 20 }} />
                {this.renderButtonAddProjeto()}
            </div>
        )
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.addProjetoSemVinculo) return <AddProjeto title={"Criar Projeto sem Vínculo com Mapa Estratégico"} addCallback={() => { this.setState({ addProjetoSemVinculo: false }, () => { this.loadProjetos() }) }} hasBackButton={true} backButton={() => { this.setState({ addProjetoSemVinculo: false }) }} />
        if (this.state.showSettings) return this.renderSettings();
        return this.renderDetails();
    }
}

let style = {
    projetoCard: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        width: '100%',
        minWidth: '535px',
        height: 160,
        padding: '10px 20px 10px 20px',
        marginBottom: 10
    }
}