import Constants from "../constants/Api";
import DataHelper from "../helper/DataHelper";
import IdiomaHelper from "../helper/IdiomaHelper";
import LayoutHelper from "../helper/LayoutHelper";
import SessionHelper from "../helper/SessionHelper";
import { toast } from 'react-toastify';

const gateway = Constants.getSigEndPoint();

export default class Sig {
    /**
     * @param {String} type The http method: POST, GET, PUT, DELETE
     * @param {String} route The route which your function is on the API
     * @param {Object} body The body object of the POST request, containing the desired data, authKey is added automatically
     * */
    static async request(type, route, body, contentType = 'application/x-www-form-urlencoded; charset=UTF-8', transformTo = 'json') {
        let response = {};

        if(contentType == 'application/x-www-form-urlencoded; charset=UTF-8') {
            if(SessionHelper.getToken() && type === 'POST') {
                body = body ? { ...body, sgcsb: SessionHelper.getToken() } : { sgcsb: SessionHelper.getToken() };
            }

            body = body ? Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&') : null;
        }

        if(contentType == 'multipart/form-data') body.append('sgcsb', SessionHelper.getToken());
        
        // if type is GET, add the body to the route
        if(type === 'GET' && body) {
            route = `${route}?${body}`;
            body = null;
        } 

        try {
            let fetchParams = {
                method: type,
                credentials: 'include',
                body: body
            }

            if(contentType !== 'multipart/form-data') {
                fetchParams.headers = { 'Content-Type': contentType };
            } 

            const request = await fetch(`${gateway}/${route}`, fetchParams);

            if (request.redirected) {
                window.parent.postMessage({ type: 'navigate', url: request.url }, '*');
                return;
            }

            if(request.status >= 500 || request.status >= 400) {
                const responseBody = await request.text();
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(responseBody, 'text/html');
                let errorMessageElement = htmlDoc.getElementById('error-message');
                if(!errorMessageElement) errorMessageElement = htmlDoc.getElementsByClassName('message') ? htmlDoc.getElementsByClassName('message')[0] : false;
                let errorMessage = 'Erro Desconhecido';
                
                if (errorMessageElement) {
                    errorMessage = errorMessageElement.textContent;
                }

                throw new Error(errorMessage);
            }
            if(request.status === 200 && transformTo) response = await request[transformTo]();
            response.status = request.status;
            
            if(transformTo === 'blob') {
                response.headers = {};

                for (let [key, value] of request.headers.entries()) {
                    response.headers[key] = value;
                }
            }
        } catch (error) {
            toast.error(Sig.treatBackendErrorMessages(error.message));
            return {};
        }

        return response;
    }

    static treatBackendErrorMessages(message) {
        message = DataHelper.removeHtmlTags(message);
        let error = message;

        if(error.length < 1) return 'Erro desconhecido. Tente novamente mais tarde ou contate o suporte.'; 

        let dictionary = DataHelper.getErrorDictionary();

        for (let entry of dictionary) {
            for (let keyword of entry.keywords) {
                if (message.toLowerCase().includes(keyword.toLowerCase())) {
                    error = entry.message;
                    break;
                }
            }
            if (error !== message) {
                break;
            }
        }

        return error;
    }

    static async setSigSessionData() {
        let auth = await this.request('GET', 'session/getAuth');

        if(auth && auth.status === 200) {
            SessionHelper.setToken(auth?.yii_csrf_token);

            let response = await this.request('GET', 'session/getDadosSessao');
            
            if(response && response.status === 200) {
                SessionHelper.setData({ id_usuario: response?.colaborador?.id, id_empresa: response?.empresa?.id, id_filial: response?.colaborador?.id_filial });
                SessionHelper.setColor(response?.empresa?.color);
                SessionHelper.setSecondaryColor(response?.empresa?.secondary_color);
                SessionHelper.setBackgroundColor(response?.empresa?.background_color);
                await IdiomaHelper.setIdiomas();

                if(response?.empresa?.color) {
                    let style = document.createElement('style');
                    style.innerHTML = `
                    ::selection {
                        background: ${LayoutHelper.darkenColor(response?.empresa?.color, -60)};
                    }
                    ::-moz-selection {
                        background: ${LayoutHelper.darkenColor(response?.empresa?.color, -60)};
                    }`;
                    document.head.appendChild(style);
                }

                return true;
            }
        }
        return false;
    }
}
