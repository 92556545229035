import React from "react";
import ReactDOM from 'react-dom';

import Sig from "../../../../../api/Sig";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import moment from 'moment';
import TimelineMarcos from "../../canvas/TimelineMarcos";
import PlanoAcaoCardRelatorio from "../../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio";
import ModalVinculaMarco from "../../canvas/ModalVinculaMarco";

import Tabs from "../../../../tools/Tabs";
import UserAvatar from "../../../../tools/UserAvatar";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../tools/CustomTooltip";
import CustomConfirm from "../../../../tools/CustomConfirm";
import DefaultButton from "../../../../tools/DefaultButton";
import TooManyAvatars from "../../../../tools/TooManyAvatars";
import UnderConstruction from "../../../../tools/UnderConstruction";
import ProgressBarCustom from "../../../../tools/ProgressBar/ProgressBarCustom";

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Collapse, Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEye, faPlusSquare, faEllipsis, faCalendar, faEdit, faTimes, faCheck, faChevronDown, faChevronUp, faExclamationTriangle, faAdd, faStar } from '@fortawesome/free-solid-svg-icons';

import DataHelper from "../../../../../helper/DataHelper";
import SessionHelper from "../../../../../helper/SessionHelper";
import ProjetoHelper from "../../../../../helper/pe/ProjetoHelper";

import './ProjetoGestaoView.css';
import DescricaoProjeto from "../DescricaoProjeto/DescricaoProjeto";
import GraficosProjeto from "../GraficosProjeto/GraficosProjeto";



export default class ProjetoGestaoView extends React.Component {

    state = {
        loading: true,
        id: this.props.id,
        isSmallScreen: this.props.isSmallScreen,

        showDescricao: false,
        showGraficos: false,
        showIndicadores: false,
        showDetalhamento: false,

        activeTab: 'lista',
        activeTimelineTab: 'ano',
        modalAddPlano: false,
        creatingCanvas: false,
    }

    async componentDidMount() {
        await this.loadProjeto();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id }, async () => {
                await this.loadProjeto();
            });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    async loadProjeto() {
        this.setState({ loading: true });

        let { projeto, status_text, dataInicio, dataFim, porcentagemConclusao, equipe, objetivo, canvas, marcosCanvas, podeFavoritarProjeto, podeCriaCanvas, podeVisualizarCanvas, tiposProgresso } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.id, relations: 1 }));
        let progressGut = await ProjetoHelper.getGutItemValue(this.state.id);
        canvas = canvas?.length > 0 ? canvas[0] : false;
        this.setState({
            progressGut,
            porcentagemConclusao,
            dataInicio,
            dataFim,
            status: status_text,
            participantes: equipe || [],
            projeto,
            objetivo,
            canvas,
            marcosCanvas,
            podeFavoritarProjeto,
            podeCriaCanvas,
            podeVisualizarCanvas,
            tiposProgresso,
            loading: false
        });
    }

    async modifyProgressPlanos(){
        let response = await Sig.request('POST', 'pe/projeto/modifyProgressPlanos', { id: this.state.projeto.id });

        if (response.success) {
            await this.loadProjeto();
            return toast.success('Planos modificados com sucesso');
        } else {
            return toast.error('Erro ao modificar Planos');
        }
    }

    onTabChange = (tab) => {
        this.setState({ activeTab: tab.name });
    }

    toggleFavorite = async () => {
        let { projeto } = await Sig.request('POST', 'pe/projeto/toggleFavorito', { id: this.state.projeto.id });

        if (projeto.id) {
            this.setState({ projeto });
        } else {
            toast.error('Erro ao marcar projeto como favorito');
        }
    }

    hasWarning() {
        let hasWarning = false;
    
        if (this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0) {
            for (let i = 0; i < this.state.marcosCanvas.length; i++) {
                let marco = this.state.marcosCanvas[i];
                if (marco.planoAcao && marco.planoAcao.tipoProgresso !== this.state.projeto.tipo_progresso) {
                    hasWarning = <span>Divergências nas metodologias de progresso no seu Projeto! <hr/><strong>Clique para utilizar somente a metodologia do seu Projeto</strong></span>;
                    break;
                }
            }
        }
    
        return hasWarning;
    }

    renderHeader() {

        const renderPeriodo = () => {
            return (
                <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                    <span>{this.state.dataInicio != 0 ? moment(this.state.dataInicio).format('DD/MM/YY') : "- -"}{this.state.dataFim ? `~ ${moment(this.state.dataFim).format('DD/MM/YY')}` : ``}</span>
                </div>
            );
        }

        const renderStatus = () => {

            let status = this.state.status || 'Não Iniciado';
            return (
                status == 'Em Desenvolvimento' || status == 'Atrasado' || status == 'Concluído' ?
                    <ProgressBarCustom 
                        tipo={'projeto'} 
                        id={this.state.id} 
                        backgroundColor={Colors.planoAcao.status.backgroundColor[status]} 
                        color={Colors.planoAcao.status.color[status]} 
                        style={{width: '100%', fontWeight: 'bold'}}
                        completeEfect={'Concluído'}
                    />
                    :
                    <>
                        Status 
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                            <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[status], color: Colors.planoAcao.status.color[status], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{status}</div>
                        </div>
                    </>
                    
            );

            
        }

        const renderPeople = () => {
            return (
                <div style={{ ...EssentialStyle.rowFlexStart, gap: 4 }}>
                    <UserAvatar id={this.state.projeto?.id_responsavel} showName={true} />
                    {this.state.participantes?.length ?
                        <>
                            <span style={{ borderRight: `1px solid ${Colors.dark}`, height: 25, width: 1 }}></span>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 5 }}>
                                <TooManyAvatars participantsTotal={this.state.participantes?.length} participantsVisiveis={5} arrayParticipants={this.state.participantes} />
                            </div>
                        </>
                        : null}
                </div>
            )
        }

        const renderOpcoesHover = () => {

            if (!this.state.editing) {
                return (
                    <div style={{ ...EssentialStyle.rowFlexCenter, overflow: 'hidden', backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                        <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faEdit} onClick={() => { this.editTitleOn() }} />
                    </div>
                )
            } else {
                return (
                    <div style={{ ...EssentialStyle.rowFlexCenter, backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", gap: "5px" }}>
                        <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faTimes} onClick={() => { this.cancelEdit() }} />
                        <FontAwesomeIcon className={"icon-container"} style={{ margin: 3 }} icon={faCheck} onClick={() => { this.confirmEdit() }} />
                    </div>
                )
            }
        }

        const renderPriorizacao = () => {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, gap: 10}}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                            <div style={{ fontSize: 16, color: Colors.homePage.grey, fontWeight: 400 }}>Pontuação: {this.state.progressGut}</div>
                        </div>
                    </div>
                    |
                    <CustomTooltip placement={"left"} tooltip={!this.state.podeFavoritarProjeto ? "Sem Permissão Para Favoritar Projetos" : (this.state.projeto?.favorito == 1 ? 'Remover prioridade' : 'Marcar como prioritário') }>
                        <FontAwesomeIcon
                            icon={faStar}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (this.state.podeFavoritarProjeto) {
                                    this.toggleFavorite();
                                }
                                    
                            }}
                            style={{
                                color: this.state.projeto?.favorito == 1 ? Colors.danger : Colors.disabled,
                                cursor: 'pointer',
                                fontSize: 24
                            }}
                        />
                    </CustomTooltip>
                </div>
            )
        }

        const renderOptions = () => {
            const portalTarget = document.getElementById('root');
            return (
                <Dropdown className="optionsProjeto" onClick={(e) => { e.stopPropagation() }}>
                    <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções do Projeto"}>
                        <Dropdown.Toggle
                            onChange={() => {}}
                            role="button"
                            style={{ 
                                    backgroundColor: Colors.secondaryButton, 
                                    color: Colors.dark, 
                                    border: 'none',
                                    zIndex: 25
                            }}
                            drop={'top'}
                        > 
                            <FontAwesomeIcon icon={faEllipsis} />
                        </Dropdown.Toggle>
                    </CustomTooltip>
                    {ReactDOM.createPortal(
                        <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                            <Dropdown.Item 
                                key={`delete-projeto-btn`} 
                                onClick={ this.renderDeleteProjetoModal }
                            >
                                    <span style={{ fontSize: 14 }}>Excluir Projeto</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    , portalTarget)}
                </Dropdown>
            );
        }

        const renderCanvas = () => {
            return (
                <div style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                    {this.state.creatingCanvas ?
                    <div style={{ ...EssentialStyle.rowFlexStart,}}>
                        <Form.Label>Criar canvas?</Form.Label>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                            <DefaultButton
                                tooltip={'Adicionar'}
                                color={Colors.success}
                                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    this.addCanvas();
                                }}
                            />
                            <DefaultButton
                                tooltip={'Cancelar'}
                                textColor={Colors.dark}
                                color={Colors.secondaryButton}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={(e) => { 
                                    e.stopPropagation();
                                    this.setState({ creatingCanvas: false }) 
                                }}
                            />
                        </div>
                    </div>
                    :
                    <DefaultButton
                        color={this.state.canvas ? Colors.dark : Colors.success}
                        title={this.state.canvas ? "Ver Canvas" : "Criar Canvas"}
                        tooltip={this.state.canvas ? (!this.state.podeVisualizarCanvas ? "Sem Permissão para Visualizar Canvas do Projeto" : "") : (!this.state.podeCriaCanvas ?  "Sem Permissão para Criar Canvas de Projeto" : "")}
                        leftIcon={this.state.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                        disabled={this.state.canvas ? !this.state.podeVisualizarCanvas : !this.state.podeCriaCanvas}
                        onMouseDown={(e) => {
                            if (e.button == 1 && this.state.canvas) {
                                e.stopPropagation();
                                e.preventDefault();
                                window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                            }  
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (this.state.canvas) {
                                if(e.ctrlKey || e.metaKey) {
                                    window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                }
                                else {
                                    window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                }
                            } else {
                                this.setState({ creatingCanvas: true });
                            }
                        }}
                    />}
                    {renderOptions()}
                </div>
            )
        }

        const renderWarning = () => {
            return (
                this.hasWarning() ?
                    <CustomTooltip tooltip={this.hasWarning()} placement="left">
                        <FontAwesomeIcon 
                            icon={faExclamationTriangle} 
                            style={{ marginRight: 10, cursor: 'pointer' }} 
                            className={'icon warning'}
                            onClick={(e) => {
                                e.preventDefault();
                                renderConfirmModifyProgressPlanos()
                            }} />
                    </CustomTooltip>
                    :
                    null
            )
        }

        const renderConfirmModifyProgressPlanos = () => {

            let message = "Ao aceitar, os Planos de Ação vinculados aos marcos adotarão a mesma metodologia padrão de progresso do projeto, que, por sua vez, segue a metodologia do Mapa Estratégico (se houver um Mapa Estratégico).";
            return (
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <CustomConfirm
                            title={'Alterar Metodologias de Progresso?'}
                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                            buttons={[
                                {
                                    label: 'Aceitar',
                                    color: SessionHelper.getColor(),
                                    textColor: Colors.light,
                                    onClick: () => { this.modifyProgressPlanos(); onClose(); }
                                },
                                {
                                    label: 'Cancelar',
                                    onClick: () => { onClose(); }
                                },
                            ]}
                        />
                    )
                })
            )
        }

        const renderLeft = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(40%)' }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                        <DefaultButton tooltipPlacement={'bottom'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/pe/projeto/detalhar` }, '*'); }} />
                        <span className={"title"}>{this.state.projeto?.nome}</span>
                    </div>

                    <span className={"subTitle"}>
                        {this.state.objetivo ?
                            DataHelper.removeHtmlTags(this.state.objetivo?.valor)
                            :
                            'Sem vínculo com Mapa Estratégico'
                        }
                    </span>

                    <div className={"colaboradores"}>
                        {renderPeople()}
                    </div>
                </div>
            )
        }

        const renderCenter = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(30%)', height: '100%' }}>
                    <span className={"date"}>Período {renderPeriodo()}</span>
                    <span className={"metodologia"}>Metodologia <span style={{fontWeight: 'bold'}}>{this.state.tiposProgresso[Number(this.state.projeto.tipo_progresso)]}</span></span>
                    <span className={"status"}>{renderStatus()}</span>
                </div>
            )
        }

        const renderRight = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(30%)', height: '100%', alignItems: 'end' }}>
                    <div className={'canvas'}>{renderCanvas()}</div>
                    {renderWarning()}
                    <div className={'priorizacao'}>{renderPriorizacao()}</div>
                </div>
            )
        }

        return (
            <div className={"headerCard"} style={{ ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween), alignItens: 'end' }}>
                {this.state.loading ?
                    <div className={"loading"}><DefaultLoader /></div>
                    :
                    <>
                        {renderLeft()}
                        {renderCenter()}
                        {renderRight()}
                    </>}
            </div>
        )
    }

    renderGraficos() {
        return (
            <div className={"graficos"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showGraficos: !this.state.showGraficos }) }}
                >
                    <FontAwesomeIcon icon={this.state.showGraficos ? faChevronUp : faChevronDown} />
                    <span>Gráficos</span>
                </div>

                <Collapse in={this.state.showGraficos}>
                    <div style={{width: '100%'}}>
                        <GraficosProjeto 
                            id={this.state.id} 
                            isSmallScreen={this.state.isSmallScreen}
                            style={{width: '100%', height: '15rem'}}
                        />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderDescricao() {
        return (
            <div className={"descricao"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showDescricao: !this.state.showDescricao }) }}
                >
                    <FontAwesomeIcon icon={this.state.showDescricao ? faChevronUp : faChevronDown} />
                    <span>Descrição</span>
                </div>

                <Collapse in={this.state.showDescricao}>
                    <div style={{width: '100%'}}>
                        <DescricaoProjeto id={this.state.id} isSmallScreen={this.state.isSmallScreen} />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderIndicadores() {
        return (
            <div className={"indicadores"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showIndicadores: !this.state.showIndicadores }) }}
                >
                    <FontAwesomeIcon icon={this.state.showIndicadores ? faChevronUp : faChevronDown} />
                    <span>Indicadores</span>
                </div>

                <Collapse in={this.state.showIndicadores}>
                    <div style={{width: '100%'}}>
                        <UnderConstruction height={'15rem'}/>
                    </div>
                </Collapse>
            </div>
        )
    }

    renderDetalhamento() {

        if (this.state.loading) {
            return (
                <div className={"detalhamento"} onClick={e => e.stopPropagation()}>
                    <div className={"loading"}><DefaultLoader /></div>
                </div>
            )
        }

        return (
            <div className={"detalhamento"} onClick={e => e.stopPropagation()}>
                {this.state.canvas ?
                    <>
                        <div className={"menu"}>
                            <Tabs
                                tabs={[
                                    { name: 'lista', title: 'Lista' },
                                    { name: 'timeline', title: 'Timeline' }
                                ]}
                                style={{ alignSelf: 'flex-start' }}
                                activeTab={this.state.activeTab}
                                onTabChange={this.onTabChange}
                            />
                            {this.state.activeTab === 'timeline' &&
                                <Tabs
                                    tabs={[
                                        { name: 'ano', title: 'Ano' },
                                        { name: 'mes', title: 'Mês' },
                                        { name: 'semana', title: 'Semana' },
                                    ]}
                                    style={{ alignSelf: 'flex-end' }}
                                    activeTab={this.state.activeTimelineTab}
                                    onTabChange={(tab) => this.setState({ activeTimelineTab: tab.name })}
                                />
                            }
                        </div>

                        {this.renderCollapsable()}
                    </>
                    :
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%" }}>
                        <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Sem Canvas Vinculado!</div>
                    </div>
                }
            </div>
        )
    }

    renderCollapsable() {

        if (this.state.activeTab === 'timeline') {
            return (
                <>
                    <TimelineMarcos
                        marcos={[...this.state.marcosCanvas]}
                        changeMarcosCallback={() => { }}
                        id_pe_projeto_canvas={this.state.canvas.id}
                        softBorder
                        qtdMonthsEnd={16}
                        qtdMonthsStart={6}
                        granularidade={this.state.activeTimelineTab}
                        style={{ height: "auto", minHeight: "unset", maxHeight: "450px", width: "100%", maxWidth: "100%", borderRadius: 8, padding: 0 }}
                    />
                    <div style={{minHeight: 90}}>&nbsp;</div>
                </>
            )
        }

        return (
            this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0 ? 
            
                <>
                    {this.state.marcosCanvas.map((marco, index) => {
                        return (
                            <div key={"marco-" + index} style={{ display: "flex", minWidth: "100%", maxWidth: "100%" }}>
                                {marco.planoAcao ?
                                    <PlanoAcaoCardRelatorio key={marco.planoAcao.id} plano={marco.planoAcao} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} isSmallScreen={this.state.isSmallScreen} noMargin />
                                    :
                                    this.renderMarcoCard(marco)
                                }
                            </div>
                        )
                    })}
                    <div style={{minHeight: 90}}>&nbsp;</div>
                </>
                :
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%" }}>
                    <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Nenhum Marco definido!</div>
                </div>
        )
    }

    renderMarcoCard(marco) {
        return (
            <div style={{ ...EssentialStyle.card, ...EssentialStyle.rowFlexStart, gap: 10, width: '100%', padding: 10, borderRadius: 8, height: 90 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "100%" }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, flex: 1, width: "30%" }}>
                        <CustomTooltip tooltip={"Sem Plano de Ação Vinculado"} placement="right">
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                        </CustomTooltip>
                        <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{marco.descricao}</div>
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, width: "auto" }}>
                        {this.renderPeriodoMarco(marco)}
                        <DefaultButton
                            tooltip={"Vincular Plano de Ação"}
                            color={"transparent"}
                            textColor={Colors.homePage.darkGrey}
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            onClick={() => { this.setState({ modalAddPlano: true }) }}
                        />
                    </div>
                </div>
                {marco.planoAcao ? null : this.renderModalAddPlano(marco.id)}
            </div>
        )
    }

    renderPeriodoMarco(marco) {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{marco.data_inicio != 0 ? moment(marco.data_inicio).format('DD/MM/YY') : "- -"}{marco.data_fim ? `~ ${moment(marco.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderModalAddPlano(idMarco) {

        if (!this.state.modalAddPlano) return null;
        return (
            <ModalVinculaMarco tipoProgresso={this.state.projeto.tipo_progresso} idMarco={idMarco} closeModal={async () => { 
                this.setState({modalAddPlano: false}); 
                await this.loadProjeto();
            }}/>
        )
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnStart, with: '100vw', height: '100vh', padding: 7 }}>
                {this.renderHeader()}
                {this.renderGraficos()}
                {this.renderDescricao()}
                {this.renderIndicadores()}
                {this.renderDetalhamento()}
            </div>
        )
    }
}