import React from "react";
import Sig from "../../../../api/Sig";
import DefaultLoader from "../../../tools/DefaultLoader";
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faEye, faStar, faCheck, faTimes, faEdit, faCalendar, faAdd, faExclamationTriangle, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import UserAvatar from "../../../tools/UserAvatar";
import DataHelper from "../../../../helper/DataHelper";
import ProjetoHelper from "../../../../helper/pe/ProjetoHelper";
import DefaultButton from "../../../tools/DefaultButton";
import CustomTooltip from "../../../tools/CustomTooltip";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import LayoutHelper from "../../../../helper/LayoutHelper";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import moment from 'moment';
import { Collapse } from "react-bootstrap";
import PlanoAcaoCardRelatorio from "../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio";
import TimelineMarcos from "../canvas/TimelineMarcos";
import Tabs from "../../../tools/Tabs";
import ModalVinculaMarco from "../canvas/ModalVinculaMarco";
import ReactDOM from 'react-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import CustomConfirm from "../../../tools/CustomConfirm";
import SessionHelper from "../../../../helper/SessionHelper";
import { confirmAlert } from "react-confirm-alert";
import OrcamentoProjeto from "./OrcamentoProjetos/OrcamentoProjetos";

export default class ProjetoCard extends React.Component {
    state = {
        loading: true,
        projeto: {},
        participantes: [],
        iniciativa: {},
        objetivo: {},
        orcamento: {},
        expanded: false,
        status: null,
        dataInicio: 0,
        dataFim: 0,
        porcentagemConclusao: 0,
        canvas: {},
        creatingCanvas: false,
        escala: 100,
        podeFavoritarProjeto: false,
        podeCriaCanvas: false,
        podeVisualizarCanvas: false,
        hoverOn: false,
        blockHoverOn: false,
        nameEditing: '',
        activeTab: 'lista',
        isSmallScreen: LayoutHelper.isSmallScreen(),
        progress: 0,
        activeTimelineTab: 'mes',
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadProjeto();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadProjeto() {
        this.setState({ loading: true });

        let { projeto, status_text, dataInicio, dataFim, porcentagemConclusao, equipe, iniciativa, objetivo, canvas, marcosCanvas, podeFavoritarProjeto, podeCriaCanvas, podeVisualizarCanvas, orcamento } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.props.id, relations: 1 }));
        let { escala } = await Sig.request('GET', `pe/projeto/getEscala`);
        let progress = await ProjetoHelper.getGutItemValue(this.props.id);
        canvas = canvas?.length > 0 ? canvas[0] : false;

        this.setState({ 
            orcamento,
            escala,
            progress,
            porcentagemConclusao, 
            dataInicio, 
            dataFim, 
            status: status_text,
            participantes: equipe || [], 
            projeto, 
            iniciativa, 
            objetivo, 
            canvas, 
            marcosCanvas,
            podeFavoritarProjeto, 
            podeCriaCanvas, 
            podeVisualizarCanvas, 
            loading: false 
        });
    }

    hasWarning() {
        let hasWarning = false;
    
        if (this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0) {
            for (let i = 0; i < this.state.marcosCanvas.length; i++) {
                let marco = this.state.marcosCanvas[i];
                if (marco.planoAcao && marco.planoAcao.tipoProgresso !== this.state.projeto.tipo_progresso) {
                    hasWarning = <span>Divergências nas metodologias de progresso no seu Projeto! <hr/><strong>Clique para utilizar somente a metodologia do seu Projeto</strong></span>;
                    break;
                }
            }
        }
    
        return hasWarning;
    }

    async modifyProgressPlanos(){
        let response = await Sig.request('POST', 'pe/projeto/modifyProgressPlanos', { id: this.state.projeto.id });

        if (response.success) {
            await this.loadProjeto();
            return toast.success('Planos modificados com sucesso');
        } else {
            return toast.error('Erro ao modificar Planos');
        }
    }

    renderModifyProgressPlanos(){

        let message = "Ao aceitar, os Planos de Ação vinculados aos marcos adotarão a mesma metodologia padrão de progresso do projeto, que, por sua vez, segue a metodologia do Mapa Estratégico (se houver um Mapa Estratégico).";
        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={'Alterar Metodologias de Progresso?'}
                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                        buttons={[
                            {
                                label: 'Aceitar',
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.modifyProgressPlanos(); onClose(); }
                            },
                            {
                                label: 'Cancelar',
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        )
    }

    renderInfos() {

        let localStyle = {
            ...style.leftInfo,
            width: (this.state.isSmallScreen ? '100%' : '35%')
        }

        return (
            <div style={localStyle}>
                <div 
                    style={{...EssentialStyle.rowFlexStart, width: '100%', alignItems: 'center'}}
                >
                    {this.hasWarning() &&
                        <CustomTooltip tooltip={this.hasWarning()} placement="right">
                            <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                style={{ marginRight: 10 }} 
                                className={'icon warning'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.renderModifyProgressPlanos()
                                }} />
                        </CustomTooltip>
                    }
                    <div style={{...EssentialStyle.rowFlexStart, flex: 1, height: '100%', alignItems: 'center'}}
                        onMouseOver={ () => { this.setState({ hoverOn: true }) } } 
                        onMouseOut= { () => { this.setState({ hoverOn: false }) } }
                    >
                        <div style={{ transition: 'width 0.3s linear', width: this.state.hoverOn || this.state.blockHoverOn ? '50px' : '0px', height: "100%" }}>
                            { this.state.hoverOn || this.state.blockHoverOn ? this.renderOpcoes() : null }
                        </div>
                        {this.state.editing ? 
                            <input 
                                type="text"
                                maxLength={100}
                                value={this.state.nameEditing} 
                                autoFocus={true}
                                onChange={ (e) => { this.setState({ nameEditing: e.target.value }) } } 
                                onKeyDown={ (e) => { 
                                    e.stopPropagation();
                                    if(e.key === 'Enter') this.confirmEdit(); 
                                } }
                                style={{...style.title, border: 'none', marginLeft: 8, flexGrow: 1, height: "30px"}}
                            /> 
                            : 
                            <div style={{...style.title, paddingLeft: this.state.hoverOn || this.state.blockHoverOn ? "10px" : "0px"}} >
                                {this.state.projeto?.nome}
                            </div>
                        }
                    </div>
                </div>
                <div style={style.subTitle}>
                    {this.state.iniciativa && this.state.objetivo ?
                        `${this.state.iniciativa?.nome} | ${DataHelper.removeHtmlTags(this.state.objetivo?.valor)}`
                        :
                        'Sem vínculo com Mapa Estratégico'
                    }
                </div>
                {this.renderPeople()}
            </div>
        );
    }

    renderPeople() {
        return(
            <div style={{...EssentialStyle.rowFlexStart, gap: 4}}>
                <UserAvatar id={this.state.projeto?.id_responsavel} showName={true} />
                {this.state.participantes?.length ?
                    <>
                    <span style={{borderRight: `1px solid ${Colors.dark}`, height: 25, width: 1}}></span>
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 5 }}>
                        <TooManyAvatars participantsTotal={this.state.participantes?.length} participantsVisiveis={5} arrayParticipants={this.state.participantes} />
                    </div>
                    </>
                : null}
            </div>
        )
    }

    toggleFavorite = async () => {
        let { projeto } = await Sig.request('POST', 'pe/projeto/toggleFavorito', { id: this.state.projeto.id });

        if (projeto.id) {
            this.setState({ projeto });
        } else {
            toast.error('Erro ao marcar projeto como favorito');
        }
    }

    renderOpcoes(){
        
        if(!this.state.editing){
            return (
                <div style={{...EssentialStyle.rowFlexCenter, overflow: 'hidden', backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faEdit} onClick={() => { this.editTitleOn() } }/>
                </div>
            )
        } else {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", gap: "5px"}}>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faTimes} onClick={() => { this.cancelEdit()  } }/>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faCheck} onClick={() => { this.confirmEdit() } }/>
                </div>
            )
        }
    }

    editTitleOn() {
        this.setState({ editing: true, nameEditing: this.state.projeto.nome, blockHoverOn: true });
    }

    cancelEdit(){
         this.setState({ editing: false, blockHoverOn: false, nameEditing: this.state.projeto.nome });
    }

    async confirmEdit() {
        this.setState({ loading: true });
       
        if (this.state.nameEditing != this.state.projeto.nome) {
            let { projeto } = await Sig.request('POST', `pe/projeto/editNomeProjeto`, { id: this.state.projeto.id, nome: this.state.nameEditing });
            
            if(projeto) {
                this.setState({ projeto, nameEditing: projeto.nome });
            } else {
                toast.error('Erro ao editar projeto');
            }
        }
        
        this.setState({ loading: false, editing: false, blockHoverOn: false, hoverOn: false});
    }

    addCanvas = async () => {
        let { canvas } = await Sig.request('POST', 'pe/canvas/add',
            {
                nome: 'remover',
                id_pe_projeto: this.state.projeto.id,
                id_responsavel: this.state.projeto.id_responsavel
            }
        );

        if (canvas.id) {
            this.setState({ canvas, creatingCanvas: false, canvasName: '' }, () => {
                window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${canvas.id}` }, '*');
            });
        } else {
            toast.error('Erro ao criar canvas');
        }
    }

    async deleteProjeto() {
        
        let response = await Sig.request('POST', 'pe/projeto/delete', { id: this.state.projeto.id });

        if (response.success) {
            this.props.calbackDelete(this.state.projeto.id);
            return toast.success('Projeto deletado com sucesso');
        } else {
            return toast.error('Erro ao deletar projeto');
        }

    }

    renderProggressBar() {
        return (
            <ProgressBarCustom tipo={'projeto'} id={this.props.id} style={{width: this.state.isSmallScreen ? '100%' : 300}} />
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.state.dataInicio != 0 ? moment(this.state.dataInicio).format('DD/MM/YY') : "- -"}{this.state.dataFim ? `~ ${moment(this.state.dataFim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderPeriodoMarco(marco) {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{marco.data_inicio != 0 ? moment(marco.data_inicio).format('DD/MM/YY') : "- -"}{marco.data_fim ? `~ ${moment(marco.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderStatus() {
        let status = this.state.status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[status], color: Colors.planoAcao.status.color[status], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{status}</div>
            </div>
        );
    }

    renderDeleteProjetoModal = () => { 
        var message = "Essa ação não pode ser desfeita";

        if(this.state.marcosCanvas?.length > 0 && this.state.marcosCanvas.some(marco => marco.planoAcao?.tipo == 4)) {
            message = "Ações de marcos do projeto também serão excluídas! "+ message;
        }

        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={'Excluir este Projeto?'}
                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                        buttons={[
                            {
                                label: 'Remover',
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.deleteProjeto(); onClose(); }
                            },
                            {
                                label: 'Cancelar',
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        )
    }

    renderOptions = () => {
        const portalTarget = document.getElementById('root');
        return (
            <Dropdown className="optionsProjeto" onClick={(e) => { e.stopPropagation() }}>
                <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções do Projeto"}>
                    <Dropdown.Toggle
                        onChange={() => {}}
                        role="button"
                        style={{ 
                                backgroundColor: Colors.secondaryButton, 
                                color: Colors.dark, 
                                border: 'none',
                                zIndex: 25
                        }}
                        drop={'top'}
                    > 
                        <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>
                </CustomTooltip>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                        <Dropdown.Item 
                            key={`delete-projeto-btn`} 
                            onClick={ this.renderDeleteProjetoModal }
                        >
                                <span style={{ fontSize: 14 }}>Excluir Projeto</span>
                        </Dropdown.Item>
                        <Dropdown.Item 
                            key={`go-projeto-btn`} 
                            onClick={ () => {
                                window.parent.postMessage({ type: '_blank', url: `/pe/projeto/gestao/${this.state.projeto.id}` }, '*');
                            } }
                        >
                                <span style={{ fontSize: 14 }}>Abrir Gestão do Projeto</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    renderTools() {

        let localStyle = {
            ...style.rightInfo,
            marginTop: this.state.isSmallScreen ? 10 : 0,
            width: (this.state.isSmallScreen ? '100%' : '65%'),
            gap: 10
        }

        return (
            <div style={localStyle}>
                <div style={{...EssentialStyle.rowFlexCenter, gap: 10}}>
                    {this.props.priorization === 'star' &&
                        <CustomTooltip placement={"left"} tooltip={!this.state.podeFavoritarProjeto ? "Sem Permissão Para Favoritar Projetos" : (this.state.projeto?.favorito == 1 ? 'Remover prioridade' : 'Marcar como prioritário') }>
                            <FontAwesomeIcon
                                icon={faStar}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(this.state.podeFavoritarProjeto) 
                                        this.toggleFavorite();
                                }}
                                style={{
                                    color: this.state.projeto?.favorito == 1 ? Colors.danger : Colors.disabled,
                                    cursor: 'pointer',
                                    fontSize: 24
                                }}
                            />
                        </CustomTooltip>
                    }
                    {this.props.priorization === 'prioritization' &&
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                            <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                                <div style={{ fontSize: 16, color: Colors.homePage.grey, fontWeight: 400 }}>Pontuação: {this.state.progress}</div>
                            </div>
                        </div>
                    }
                    {this.state.creatingCanvas ?
                        <div style={{ ...EssentialStyle.rowFlexStart,}}>
                            <Form.Label>Criar canvas?</Form.Label>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                                <DefaultButton
                                    tooltip={'Adicionar'}
                                    color={Colors.success}
                                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        this.addCanvas();
                                    }}
                                />
                                <DefaultButton
                                    tooltip={'Cancelar'}
                                    textColor={Colors.dark}
                                    color={Colors.secondaryButton}
                                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        this.setState({ creatingCanvas: false }) 
                                    }}
                                />
                            </div>
                        </div>
                        :
                        <DefaultButton
                            style={{ }}
                            color={this.state.canvas ? Colors.dark : Colors.success}
                            title={this.state.canvas ? "Ver Canvas" : "Criar Canvas"}
                            tooltip={this.state.canvas ? (!this.state.podeVisualizarCanvas ? "Sem Permissão para Visualizar Canvas do Projeto" : "") : (!this.state.podeCriaCanvas ?  "Sem Permissão para Criar Canvas de Projeto" : "")}
                            leftIcon={this.state.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            disabled={this.state.canvas ? !this.state.podeVisualizarCanvas : !this.state.podeCriaCanvas}
                            onMouseDown={(e) => {
                                if (e.button == 1 && this.state.canvas) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                }  
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (this.state.canvas) {
                                    if(e.ctrlKey || e.metaKey) {
                                        window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                    }
                                    else {
                                        window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                    }
                                } else {
                                    this.setState({ creatingCanvas: true });
                                }
                            }}
                        />
                    }
                    {this.renderOptions()}
                </div>
                <div style={{...EssentialStyle.rowFlexCenter, gap: 10}}>
                    {this.renderStatus()}
                    {this.renderPeriodo()}
                </div>
                {this.renderProggressBar()}
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...style.projetoCard, ...EssentialStyle.columnCenter, height: 120 }}>
                <DefaultLoader size={32} />
            </div>
        );
    }

    renderModalAddPlano(idMarco) {

        if (!this.state.modalAddPlano) return null;
        return (
            <ModalVinculaMarco tipoProgresso={this.state.projeto.tipo_progresso} idMarco={idMarco} closeModal={async () => { 
                this.setState({modalAddPlano: false}); 
                await this.loadProjeto();
            }}/>
        )
    }

    renderMarcoCard(marco) {
        return (
            <div style={{ ...EssentialStyle.card, ...EssentialStyle.rowFlexStart, gap: 10, width: '100%', padding: 10, borderRadius: 8, height: 90 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "100%" }}>
                    <div style={{...EssentialStyle.rowFlexStart, gap: 5, flex: 1, width: "30%"}}>
                        <CustomTooltip tooltip={"Sem Plano de Ação Vinculado"} placement="right">
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                        </CustomTooltip>
                        <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{marco.descricao}</div>
                    </div>
                    <div style={{...EssentialStyle.rowFlexCenter, gap: 5, width: "auto"}}>
                        {this.renderPeriodoMarco(marco)}
                        <DefaultButton 
                            tooltip={"Vincular Plano de Ação"}
                            color={"transparent"}
                            textColor={Colors.homePage.darkGrey}
                            leftIcon={<FontAwesomeIcon icon={faAdd} />} 
                            onClick={() => {this.setState({modalAddPlano: true})}}
                        />
                    </div>
                </div>
                {marco.planoAcao ? null : this.renderModalAddPlano(marco.id)}
            </div>
        )
    }

    renderCollapsable() {

        if(this.state.activeTab === 'orcamento') {
            return (
                <OrcamentoProjeto 
                    orcamento={this.state.orcamento}
                    planos={this.state.marcosCanvas.filter(marco => marco.planoAcao).flatMap(marco => marco.planoAcao)}
                    isSmallScreen={this.state.isSmallScreen}
                />
            )
        }

        if(this.state.activeTab === 'timeline') {
            return(
                <TimelineMarcos
                    marcos={[...this.state.marcosCanvas]}
                    changeMarcosCallback={() => {}}
                    id_pe_projeto_canvas={this.state.canvas.id}
                    softBorder
                    qtdMonthsEnd={16}
                    qtdMonthsStart={6}
                    granularidade={this.state.activeTimelineTab}
                    style={{height: "auto", minHeight: "unset", maxHeight: "450px", width: "100%", maxWidth: "100%", borderRadius: 8, padding: 0}}
                />
            )
        }

        return (
             this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0 ? this.state.marcosCanvas.map((marco, index) => {
                return (
                    <div key={"marco-"+index} style={{display: "flex", minWidth: "100%", maxWidth: "100%"}}>
                        {marco.planoAcao ? 
                            <PlanoAcaoCardRelatorio key={marco.planoAcao.id} plano={marco.planoAcao} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} isSmallScreen={this.state.isSmallScreen} noMargin/>
                            : 
                            this.renderMarcoCard(marco)
                        }
                    </div>
                )
            })
            : 
            <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%" }}>
                <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Nenhum Marco definido!</div>
            </div>
        )
    }

    onTabChange = (tab) => {
        this.setState({ activeTab: tab.name });
    }

    render() {
        if (this.state.loading) return this.renderLoading();

        let localStyle = {
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween),
            width: '100%',
        }

        return (
            <div style={{...style.projetoCard, ...EssentialStyle.columnStart, cursor: 'pointer'}} onClick={() => this.setState({expanded: !this.state.expanded})}>
                <div style={localStyle}>
                    {this.renderInfos()}
                    {this.renderTools()}
                </div>
                <Collapse in={this.state.expanded} style={{width: "100%", marginTop: 10, transition: 'all 0.3s ease'}}>
                    <div>
                        <div style={{...EssentialStyle.columnStart, gap: 5, backgroundColor: Colors.background, padding: 4, borderRadius: 8}} onClick={e => e.stopPropagation()}>
                            {this.state.canvas ? 
                            <>
                                <div style={{...(this.state.isSmallScreen ? EssentialStyle.rowSpaceAround : EssentialStyle.rowSpaceBetween), width: "100%", flexWrap: "wrap", gap: 10}}>
                                    <Tabs 
                                        tabs={[
                                            {name: 'lista', title: 'Lista'},
                                            {name: 'timeline', title: 'Timeline'},
                                            {name: 'orcamento', title: 'Orçamento'},
                                        ]}
                                        style={{placeSelf: 'start'}}
                                        activeTab={this.state.activeTab} 
                                        onTabChange={this.onTabChange}
                                    />
                                    {this.state.activeTab === 'timeline' &&
                                        <Tabs 
                                            tabs={[
                                                {name: 'ano', title: 'Ano'},
                                                {name: 'mes', title: 'Mês'},
                                                {name: 'semana', title: 'Semana'},
                                            ]}
                                            style={{placeSelf: 'end'}}
                                            activeTab={this.state.activeTimelineTab}
                                            onTabChange={(tab) => this.setState({activeTimelineTab: tab.name})}
                                        />
                                    }
                                </div>
                                {this.renderCollapsable()}
                            </>
                            :
                            <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%", cursor: "default" }}>
                                <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Sem Canvas Vinculado!</div>
                            </div>
                            }
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}

let style = {
    projetoCard: {
        ...EssentialStyle.card,
        width: '100%',
        padding: '10px 20px 10px 20px',
        marginBottom: 10
    },
    title: {
        fontSize: 20,
        color: Colors.dark
    },
    subTitle: {
        fontSize: 14,
        color: Colors.disabled
    },
    leftInfo: {
        ...EssentialStyle.columnStart,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-start',
        minWidth: '320px',
    },
    rightInfo: {
        ...EssentialStyle.columnEnd,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-end',
    }
}