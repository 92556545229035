import React from "react";
import Sig from "../../../../api/Sig";
import DefaultButton from "../../../tools/DefaultButton";
import DefaultLoader from "../../../tools/DefaultLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from "../../../tools/CustomTooltip";
import EssentialStyle from "../../../../style/EssentialStyle";
import Colors from "../../../../constants/Colors";
import { toast } from 'react-toastify';
import ChartBarMeta from "../../../tools/ChartBarMeta/ChartBarMeta";

import './ObjetivoEstrategicoMeta.css';

export default class ObjetivoEstrategicoMeta extends React.Component {
    state = {
        loading: true,
        indicadores: [],
        resultado: {
            'periodos': {},
            'performanceRealizado': '--%',
            'realizadoRealizado': '',
            "metaPeriodo": '',
            'cor': '#1371cf',
            'processoNome': 'Processo não encontrado'
        },

        viewDetails: false,
        reqStatus: '',
        reqMessage: '',

    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let reqBody = {
            id: this.props.id,
            model: this.props.model
        };

        if (this.props.id_indicador && this.props.id_gmr_painel) {
            reqBody.id_indicador = this.props.id_indicador;
            reqBody.id_gmr_painel = this.props.id_gmr_painel;
        } 

        const req = await Sig.request('POST', `pe/diagrama/getAcompanhamentoObjetivoElemento`, reqBody);

        if(req.status !== 200) {
            this.setState({ reqStatus:'error', loading: false });
            return;
        }

        this.setState({ resultado: req.media, indicadores: req.indicadores, reqStatus: req.error, reqMessage: req.message }, () => { this.setState({ loading: false }) });
    }
    getMonthName(monthNumber) {
        let months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return months[monthNumber - 1];
    }

    makeStringData(periodos) {
        if (!periodos) return '';

        let chaves = Object.keys(periodos);
        let primeiraChave = chaves[0];
        let ultimaChave = chaves[chaves.length - 1];

        let anoInicio = periodos[primeiraChave].ano;
        let mesInicio = periodos[primeiraChave].mes;
        let anoFim = periodos[ultimaChave].ano;
        let mesFim = periodos[ultimaChave].mes;

        return `De ${this.getMonthName(mesInicio)}/${anoInicio} até ${this.getMonthName(mesFim)}/${anoFim}`;
    }

    handleviewDetails(evt, key) {
        evt.stopPropagation();
        this.props.handleViewDetails(this.props.id);
    }

    renderErrorPeriodo() {

        return (

            <div style={{ ...EssentialStyle.rowFlexCenter}}>
                <div style={{ minWidth: 30, minHeight: 30, borderRadius: 15, backgroundColor: '#ffc107', display: 'inline-block', marginRight: 5, padding: 3 }}>
                    <FontAwesomeIcon icon={faExclamation} color="white" />
                </div>
                Sem dados neste Período
            </div>

        )
    }

    renderGrafico() {

        if (this.state.reqStatus === 'error') return this.renderErrorPeriodo();

        let graficos = this.state.indicadores.map((indicador, index) => {
            let performance = indicador.performanceRealizado == '--%' ? '0' : indicador.performanceRealizado.replace('%', '');
            let PerformanceArredondado = indicador.performanceRealizado ? (indicador.performanceRealizado == '--%' ? ('--%') : (`${Math.round(indicador.performanceRealizado.replace('%', ''))}%`)) : ('--%');

            let content;

            if (this.props.grafico == 'performance') {
                content = (
                    <div key={`key-grafico-performance-${indicador?.indicador?.id}`} style={{ ...EssentialStyle.columnCenter, width: '100%', minHeight: this.props.minHeight, maxHeight: this.props.maxHeight, height: this.props.height }}>
                        <CustomTooltip placement={'bottom'} tooltip={<span>{"Performance: " + (indicador.performanceRealizado ? indicador.performanceRealizado : '--%')}</span>}>
                            <div className="graphic-performance" style={{ backgroundColor: indicador.cor }} >
                                <span style={{ fontSize: 12 }}>{PerformanceArredondado}</span>
                            </div>
                        </CustomTooltip>
                    </div>
                )
            } else if (this.props.grafico == 'resultado') {
                content = (
                    <ChartBarMeta
                        key={`${indicador?.indicador?.id}-${indicador?.painel?.id}`}
                        metaValue={indicador.metaPeriodo}
                        realizadoValue={indicador.realizadoRealizado}
                        color={indicador.cor}
                        performance={performance}
                        minHeight={this.props.minHeight}
                        height={this.props.height}
                    />
                )
            }
            return content;
        });

        return <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{graficos}</div>;
    }

    renderTooltip() {   
        if (this.props.hideTooltip) return '';
        if (this.state.reqStatus === 'error') return <div>{this.state.reqMessage}</div>
        return ('Ver detalhes')
    }

    renderError() {
        return (
            <CustomTooltip placement={'top'} tooltip={this.renderTooltip()}>
                <FontAwesomeIcon icon={faTriangleExclamation} color={Colors.danger}/>
            </CustomTooltip>
        )
    }

    render() {

        if (this.state.loading) return <DefaultLoader size={36} />;
        if (this.props.grafico) return this.renderGrafico();
        if (this.state.reqStatus === 'error') return this.renderError();

        return (
            <DefaultButton
                style={{ padding: 0, margin: 0, backgroundColor: 'transparent', borderColor: 'transparent', color: 'black' }}
                tooltip={this.renderTooltip()}
                disabled={this.state.reqStatus === 'error' || this.state.loading}
                leftIcon={
                    <div style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: this.props.justifyContent ? this.props.justifyContent : 'flex-start'
                    }}
                    >
                        <div style={{ width: 24, height: 24, borderRadius: 24 / 2, backgroundColor: this.state.resultado.cor }}></div>
                        <div style={{ marginLeft: 8, fontSize: 24 }}>{this.state.resultado.performanceRealizado}</div>
                    </div>
                }
                onClick={(event) => {
                    if(this.state.reqStatus !== 'error'){
                        this.handleviewDetails(event); 
                    } else if (this.state.reqStatus === 'error') {
                        toast.info(this.state.reqMessage);
                    }
                }}
            />
        )
    }
}
