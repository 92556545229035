import React from "react";
import './PlanoAcaoEtapaCard.css';

import { forwardRef } from "react";
import { Form, InputGroup } from "react-bootstrap";

import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";
import UserAvatar from "../../../tools/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCalendar, faExclamationTriangle, faPencil, faTimes, faTimesCircle, faCheck, faClock, faLink, faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Select, { components } from 'react-select';
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";
import DatePicker from 'react-datepicker';
import moment from "moment";
import CustomTooltip from "../../../tools/CustomTooltip";
import KeyboardHelper from "../../../../helper/KeyboardHelper";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import ProgressoHelper from "../../../../helper/ProgressoHelper";

export default class PlanoAcaoEtapaCard extends React.Component {
    constructor(props) {
        super(props);
        this.titleHoveredTimeout = null;
    }

    state = {
        loading: false,
        selectOpen: false,
        participantes: this.props.participantes || [],
        prioridade: this.props.etapa?.prioridade ? DataHelper.formatSelectedData(this.props.etapa, 'prioridade', 'prioridade_text') : null,
        selectedUser: this.props.etapa?.colaborador ? DataHelper.formatSelectedData(this.props.etapa?.colaborador, 'id', 'nome') : null,
        status: this.props.etapa?.status_value || 0,
        data_inicio: this.props.etapa?.data_inicio ? moment(this.props.etapa?.data_inicio).toDate() : null,
        data_fim: this.props.etapa?.data_fim ? moment(this.props.etapa?.data_fim).toDate() : null,
        aprovacao_avaliador: this.props.etapa?.aprovacao_avaliador || 0,
        aprovacao_plano: this.props.etapa?.aprovacao_plano || 0,
        titleHovered: false,
        updatingTitle: false,
        editingTitle: false,
        atividade: this.props.etapa?.atividade,
        justificativa: this.props.etapa?.justificativa,
        descricao: this.props.etapa?.descricao,
        prev: {
            atividade: this.props.etapa?.atividade,
            justificativa: this.props.etapa?.justificativa,
            descricao: this.props.etapa?.descricao,
        }
    }

    componentDidUpdate = (prevProps) => {
        const newDataInicio = this.props.etapa?.data_inicio ? moment(this.props.etapa.data_inicio).toDate() : null;
        const newDataFim = this.props.etapa?.data_fim ? moment(this.props.etapa.data_fim).toDate() : null;

        if(prevProps.etapa?.data_inicio != this.props.etapa?.data_inicio) {
            this.setState({
                data_inicio: newDataInicio
            });
        }
        if(prevProps.etapa?.data_fim != this.props.etapa?.data_fim) {
            this.setState({
                data_fim: newDataFim
            });
        }

        if(prevProps.etapa?.status_value != this.props.etapa?.status_value) {
            this.setState({
                status: this.props.etapa?.status_value || 0,
            });
        }

        if(prevProps.detailed != this.props.detailed) {
            this.setState({ [this.state.editingTitle]: this.state.prev[this.state.editingTitle], editingTitle: false });
        }

        if(prevProps.etapa?.prioridade != this.props.etapa?.prioridade) {
            this.setState({
                prioridade: this.props.etapa?.prioridade ? DataHelper.formatSelectedData(this.props.etapa, 'prioridade', 'prioridade_text') : null
            });
        }

        if(prevProps.etapa?.colaborador != this.props.etapa?.colaborador) {
            this.setState({
                selectedUser: this.props.etapa?.colaborador ? DataHelper.formatSelectedData(this.props.etapa?.colaborador, 'id', 'nome') : null
            });
        }

    }

    getUserFromParticipantes = (id) => {
        return this.state.participantes.find(p => p.id == id);
    }

    isWarning = () => {

        let response = false;
        let atividade = false;
        let justificativa = false;
        let descricao = false;

        
        if (!this.state.atividade || DataHelper.removeHtmlTags(this.state.atividade) == '' || DataHelper.removeHtmlTags(this.state.atividade) == 'null') {
            response = true;
            atividade = "'O que?'\n";
        };

        if (!this.state.justificativa || DataHelper.removeHtmlTags(this.state.justificativa) == '' || DataHelper.removeHtmlTags(this.state.justificativa) == 'null') {
            response = true;
            justificativa = "'Por quê?'\n";
        };

        if (!this.state.descricao || DataHelper.removeHtmlTags(this.state.descricao) == '' || DataHelper.removeHtmlTags(this.state.descricao) == 'null') {
            response = true;
            descricao = "'Como?'\n";
        };

        if (response) {
            response = "Preencha os campos de:\n";
            if (atividade) response += atividade;
            if (justificativa) response += justificativa;
            if (descricao) response += descricao;
            return response;
        }

        if (this.state.aprovacao_avaliador == 0 && this.props.hasAvaliador && [1, 2, 3].includes(parseInt(this.state.aprovacao_plano))) {
            response = "Atividade não avaliada, solicite nova Aprovação do Avaliador";
        }

        return response;

    }

    renderStatusSelect() {
        let minWidths = [
            120,
            175,
            100,
            100,
        ]

        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            return (
                <components.DropdownIndicator {...props} >
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        backgroundColor: PlanoAcaoHelper.getEtapaStatus()[this.state.status].backgroundColor,
                        color: PlanoAcaoHelper.getEtapaStatus()[this.state.status].color,
                        fontSize: 12,
                        fontWeight: 600,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                        minWidth: minWidths[this.state.status],
                        borderRadius: 4,
                        cursor: 'pointer'
                    }}>
                        {isMenuOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        <div style={{ marginRight: 8 }} />
                        {this.state.status > -1 ? PlanoAcaoHelper.getEtapaStatus()[this.state.status].label.toUpperCase() : 'Status'}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectStatus = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: 'auto',
                            minWidth: 'max-content',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: '100%',
                            minWidth: '100%',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8,
                            justifyContent: "flex-start"
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            display: 'contents'
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '220px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    value={this.state.status}
                    options={PlanoAcaoHelper.getEtapaStatus()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: PlanoAcaoHelper.getEtapaStatus()[value].backgroundColor,
                        }}>
                            {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            status: value.value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            await Sig.request('POST', 'planoAcao/updateEtapaStatus', {
                                id: this.props.etapa.id,
                                status: value.value
                            });
                            
                            this.props.updateEtapas();
                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    renderAtrasada() {
        if (!this.props.etapa.atrasada || (this.props.etapa.status == 2)) return null;

        if (!this.props.etapa.atrasada || (this.props.etapa.status == 3)) {
            return (
                <CustomTooltip tooltip={'Esta etapa foi concluída com atraso'} placement={'auto'}>
                    <FontAwesomeIcon icon={faClock} className={'icon atrasado-concluido'} />
                </CustomTooltip>
            );
        }

        return (
            <CustomTooltip tooltip={'Esta etapa está atrasada'} placement={'auto'}>
                <FontAwesomeIcon icon={faClock} className={'icon atrasado'} />
            </CustomTooltip>
        );
    }

    renderHasDependencias() {
        if(!this.props.etapa.has_dependencias) return null;

        return (
            <CustomTooltip tooltip={'Esta etapa possui dependências'} placement="right">
                <FontAwesomeIcon icon={faLink} className={'icon dependencias'} />
            </CustomTooltip>
        );
    }

    renderWarning() {
        if (!this.isWarning()) return null;

        let tooltipTitle = this.isWarning();

        return (
            <CustomTooltip tooltip={tooltipTitle} placement="right">
                <FontAwesomeIcon icon={faExclamationTriangle} className={'icon warning'} />
            </CustomTooltip>
        );
    }

    renderDatePicker() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <div style={{
                ...EssentialStyle.rowFlexCenter,
                fontSize: 14,
                color: Colors.dark,
                fontWeight: 'bold',
                cursor: 'pointer',
                backgroundColor: Colors.secondaryButton,
                padding: 1,
                height: '100%',
                width: 170,
                textAlign: 'center',
                borderRadius: 4,
                paddingLeft: 5,
                marginRight: 8
            }}
            >
                <FontAwesomeIcon icon={faCalendar} />
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref => this.periodoPicker = ref}
                    style={{
                        marginLeft: 2,
                        fontSize: 14,
                        color: Colors.dark,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        backgroundColor: Colors.secondaryButton,
                        padding: 1,
                        height: '100%',
                        textAlign: 'center',
                    }}
                    value={value} >
                </Form.Control>
            </div>
        ));

        let dataMoment1 = this.state.data_inicio ? moment(this.state.data_inicio).toDate() : null;
        let dataMoment2 = this.state.data_fim ? moment(this.state.data_fim).toDate() : null;

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <DatePicker
                    wrapperClassName="w-100"
                    portalId="calendar-portal"
                    selected={dataMoment1}
                    startDate={dataMoment1}
                    endDate={dataMoment2}
                    selectsRange
                    onChange={async (dates) => {
                        const [start, end] = dates;
                        this.setState({ data_inicio: start, data_fim: end });
                        
                        if (!start || !end) return;
                        
                        if (this.periodoPicker) this.periodoPicker.blur();
                        
                        await Sig.request('POST', 'planoAcao/updatePeriodoEtapa', {
                            id: this.props.etapa.id,
                            data_inicio: DataHelper.getDefaultDbDateFormat(start),
                            data_fim: DataHelper.getDefaultDbDateFormat(end)
                        });

                        this.props.updateEtapas();
                    }}
                    dateFormat="dd/MM/yy"
                    customInput={<CustomPicker />}
                    disabled={this.props.noEdit}
                    />
            </div>
        );
    }

    renderSliderComplexidade() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceEvenly, width: '100%', padding: '0px 10px', gap: 8  }}>
                <Form.Label style={{ fontWeight: 'bold', marginBottom: 0, width: 50 }}>{Number(this.props.etapa.peso || 0)}%</Form.Label>
                <InputGroup style={{ minWidth: 200, flex: '1' }}>
                    <Form.Control
                        type="range"
                        min={ProgressoHelper.getMinProgresso()}
                        max={ProgressoHelper.getMaxProgresso() - (this.props.totalPeso - Number(this.props.etapa.peso || 0))}
                        step="1"
                        value={Number(this.props.etapa.peso || 0)}
                        onMouseUp={(event) => {
                            this.props.handleSavePesoChange(this.props.etapa.id, event.target.value)
                        }}
                        onTouchEnd={(event) => {
                            this.props.handleSavePesoChange(this.props.etapa.id, event.target.value)
                        }}
                        onChange={(event) => {
                            this.props.handlePesoChange(this.props.etapa.id, event.target.value)
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </InputGroup>
            </div>
        )
    }

    DropdownIndicator = (props) => {
        const { selectProps } = props;
        const isMenuOpen = selectProps.menuIsOpen;

        if (isMenuOpen) return null;

        return (
            <components.DropdownIndicator {...props}>
                <div style={{ cursor: 'pointer' }}>
                    <UserAvatar user={this.getUserFromParticipantes(this.state.selectedUser.value)} />
                </div>
            </components.DropdownIndicator>
        );
    };

    SingleValue = ({
        children,
        ...props
      }) => (
        <components.SingleValue {...props}>
            {children}
        </components.SingleValue>
      );

    renderPeopleSelect() {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectPeople = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '210px' : '70px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.selectedUser}
                    options={DataHelper.formatSelectData(this.state.participantes, 'id', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.getUserFromParticipantes(value)} showNameAdaptative showName />
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator: this.DropdownIndicator, SingleValue: this.SingleValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedUser: value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            await Sig.request('POST', 'planoAcao/updateResponsavelEtapa', {
                                id: this.props.etapa.id,
                                responsavel: value.value
                            });
                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    renderSelectPrioridade() {
        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            if (isMenuOpen) return null;

            return (
                <components.DropdownIndicator {...props}>
                    <div style={{ cursor: 'pointer' }}>
                        {PlanoAcaoHelper.getPrioridadeIcon()[this.state.prioridade.label]}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '140px' : '47px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.prioridade}
                    options={PlanoAcaoHelper.getEtapaPrioridades()}
                    formatOptionLabel={({ label }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <div style={{ marginRight: 8 }}>{PlanoAcaoHelper.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            prioridade: value
                        }, async () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();

                            await Sig.request('POST', 'planoAcao/updatePrioridadeEtapa', {
                                id: this.props.etapa.id,
                                prioridade: value.value
                            });
                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    renderLoading() {
        <div style={{ ...EssentialStyle.card, width: '100%', height: '60vh' }}>
            <DefaultLoader />
        </div>
    }

    renderDetailed() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: "100%"
            }}>
                {this.renderDetailedTitle()}
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderStatusSelect()}
                    {this.renderDatePicker()}
                    {this.renderSelectPrioridade()}
                    {this.renderPeopleSelect()}
                </div>
            </div>
        );
    }

    renderNormal() {

        if (this.props.editPeso) return this.renderEditPeso();

        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
            }}>
                {this.state.editingTitle ? this.renderEditTitle('O que será feito?') : this.renderTitle(this.state.atividade)}
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderStatusSelect()}
                    {this.renderDatePicker()}
                    {this.renderSelectPrioridade()}
                    {this.renderPeopleSelect()}
                </div>
            </div>
        );
    }

    renderEditPeso() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
                padding: 8
            }}>
                {this.state.editingTitle ? this.renderEditTitle('O que será feito?') : this.renderTitle(this.state.atividade)}
                <div style={{ ...EssentialStyle.rowFlexEnd, width: 400 }}>
                    {this.renderSliderComplexidade()}
                </div>
            </div>
        );
    }

    renderResumed() {
        return (
            <div style={{
                ...(EssentialStyle.rowSpaceBetween),
                width: '100%',
                padding: 8
            }}>

                {this.props.dialog ? this.renderResumedTitleDialog(this.state.atividade) : this.renderResumedTitle(this.state.atividade)}

                <div style={{ ...EssentialStyle.rowFlexEnd, width: this.props.orcamento ? 540 : "unset" }}>
                    {this.props.orcamento && this.renderOrcamento()}
                    <div style={{ ...EssentialStyle.rowFlexCenter, width: 60, justifyContent: this.props.orcamento ? "end" : "center" }}>
                        <UserAvatar user={this.getUserFromParticipantes(this.state.selectedUser.value)} />
                    </div>
                </div>
            </div>
        );
    }

    renderDetailedElement(element, title) {

        let placeholder = '';
        switch (element) {
            case 'atividade':
                placeholder = 'O que será feito?';
                break;
            case 'descricao':
                placeholder = 'Como será feito?';
                break;
            case 'justificativa':
                placeholder = 'Por que será feito?';
                break;
        }

        let textWrapperStyle = {
            marginLeft: 6,
            fontSize: 16,
            fontWeight: 500,
            paddingTop: 10,
            marginBottom: 10,
            textDecoration: this.state.titleHovered === element ? 'underline' : 'none',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
        };

        let preTitleStyle = {
            fontSize: 12,
            fontWeight: 800,
            marginRight: 8,
            color: Colors.disabled,
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: '100%'
                }}
                onMouseEnter={() => {if(!this.props.noEdit) this.handleTitleHoverMouseEnter(element)}}
                onMouseLeave={() => {if(!this.props.noEdit) this.handleTitleHoverMouseLeave()}}
                onClick={(e) => {
                    e.stopPropagation();

                    this.setState(
                        { 
                            editingTitle: element, 
                            prev: { 
                                atividade: this.state.atividade, 
                                justificativa: this.state.justificativa, 
                                descricao: this.state.descricao 
                            }
                        }, () => {
                        if (this.editTitleInput) this.editTitleInput.focus();
                        const length = this.editTitleInput.value?.length || 0;
                        this.editTitleInput.editor.setSelection(length, 0);
                    });
                }}
            >
                {
                    this.state.editingTitle === element ?
                        (
                            <span style={{ ...EssentialStyle.rowFlexStart, width: '100%' }}>
                                <span style={preTitleStyle}>{title}</span>
                                {this.renderEditTitle(placeholder, 60)}
                            </span>
                        )
                        :
                        (
                            <span style={{ ...textWrapperStyle }}>
                                <span style={preTitleStyle}>{title}</span>
                                {DataHelper.removeHtmlAndReplaceListItems(this.state[element], true)}
                            </span>
                        )
                }

                {this.state.titleHovered === element && !this.state.editingTitle && (
                    <DefaultButton
                        width={48}
                        className="ms-1"
                        leftIcon={<FontAwesomeIcon icon={faPencil} />}
                        color={'transparent'}
                        textColor={Colors.dark}
                        loading={this.state.updatingTitle}
                    />
                )}
            </div>
        );
    }

    renderDetailedTitle() {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: "auto",
                    flexGrow: 1,
                    overflow: 'hidden',
                }}
            >
                {this.renderAtrasada()}
                {this.renderWarning()}
                <div
                    style={{ ...EssentialStyle.columnStart, maxWidth: '100%', width: '100%' }}
                >
                    {this.renderDetailedElement('atividade', 'O QUÊ?')}
                    {this.renderDetailedElement('descricao', 'COMO?')}
                    {this.renderDetailedElement('justificativa', 'POR QUÊ?')}
                </div>
                {this.renderHasDependencias()}
            </div>

        )
    }

    handleTitleHoverMouseEnter = (prop) => {
        this.titleHoveredTimeout = setTimeout(() => {
            this.setState({ titleHovered: prop });
        }, 400);
    }

    handleTitleHoverMouseLeave = () => {
        if (this.titleHoveredTimeout) clearTimeout(this.titleHoveredTimeout);

        this.setState({ titleHovered: false });
    }

    renderResumedTitle(text) {
        return (
            <div
                style={{ ...EssentialStyle.rowFlexStart, maxWidth: 'calc(100% - 42px)', flex: 1 }}
                onMouseEnter={() => {if(!this.props.noEdit) this.handleTitleHoverMouseEnter('atividade')}}
                onMouseLeave={() => {if(!this.props.noEdit) this.handleTitleHoverMouseLeave()}}
            >
                {this.renderAtrasada()}
                <span
                    style={{
                        marginLeft: 6,
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                    }}
                >
                    {DataHelper.removeHtmlAndReplaceListItems(text)}
                </span>
                {this.state.titleHovered && (
                    <DefaultButton
                        width={48}
                        className="ms-1"
                        leftIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                        tooltip={'Remover vínculo'}
                        loading={this.state.updatingTitle}
                        color={'transparent'}
                        textColor={Colors.dark}
                        onClick={(e) => {
                            e.stopPropagation();

                            this.setState(
                                {
                                    updatingTitle: true
                                },
                                () => {
                                    if(this.props.removeCallback) this.props.removeCallback(this.props.etapa.id, () => { this.setState({ updatingTitle: false }) });
                                }
                            );
                        }}
                    />
                )}
            </div>
        );
    }

    renderResumedTitleDialog(text) {
        return (
            <div
                style={{ ...EssentialStyle.rowFlexStart, maxWidth: this.props.orcamento ? 'calc(100% - 560px)' : 'calc(100% - 80px)', flex: this.props.orcamento ? 1 : "unset", minWidth: this.props.orcamento ? 100 : "unset" }}
            >
                {this.props.hasAvaliador && 
                    <CustomTooltip
                        tooltip={this.state.aprovacao_avaliador == 1 ? 'Etapa Aprovada' : this.state.aprovacao_avaliador == 2 ? 'Etapa Rejeitada' : 'Etapa não Avaliada'}
                        placement="top"
                        style={{...EssentialStyle.rowFlexStart}}
                    >
                        <FontAwesomeIcon 
                            icon={this.state.aprovacao_avaliador == 1 ? faCheckCircle : this.state.aprovacao_avaliador == 2 ? faTimesCircle : faInfoCircle } 
                            style={{marginRight: 10, color: this.state.aprovacao_avaliador == 1 ? Colors.success : this.state.aprovacao_avaliador == 2 ? Colors.error : Colors.primary }}
                        />
                    </CustomTooltip>
                }

                <span
                    style={{
                        marginLeft: 6,
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                    }}
                >
                    {DataHelper.removeHtmlAndReplaceListItems(text)}
                </span>
            </div>
        );
    }

    renderOrcamento () {
        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, gap: 15 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark, marginRight: 8 }}>{this.props.etapa.orcamento_previsto_value}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark, marginRight: 8 }}>{this.props.etapa.orcamento_realizado_value}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.white, backgroundColor: this.props.etapa.orcamento_saldo >= 0 ? Colors.success : Colors.error, marginRight: 8, padding: '3px 5px', borderRadius: 8 }}>{this.props.etapa.orcamento_saldo_value}</span>
                </div>
            </div>
        )
    }

    renderTitle(text) {
        return (
            <div
                style={{ ...EssentialStyle.rowFlexStart, overflow: "hidden" }}
                onMouseEnter={() => {if(!this.props.noEdit) this.handleTitleHoverMouseEnter('atividade')}}
                onMouseLeave={() => {if(!this.props.noEdit) this.handleTitleHoverMouseLeave()}}
            >
                {this.renderAtrasada()}
                {this.renderWarning()}
                <span
                    style={{
                        marginLeft: 6,
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textDecoration: this.state.titleHovered ? 'underline' : 'none',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                    }}
                >
                    {DataHelper.removeHtmlAndReplaceListItems(text)}
                </span>
                {this.renderHasDependencias()}
                {this.state.titleHovered && (
                    <DefaultButton
                        width={48}
                        className="ms-1"
                        leftIcon={<FontAwesomeIcon icon={faPencil} />}
                        color={'transparent'}
                        textColor={Colors.dark}
                        loading={this.state.updatingTitle}
                        onClick={(e) => {
                            e.stopPropagation();

                            this.setState(
                                { 
                                    editingTitle: 'atividade', 
                                    prev: { 
                                        atividade: this.state.atividade, 
                                        justificativa: this.state.justificativa, 
                                        descricao: this.state.descricao 
                                    }
                                }, () => {
                                if (this.editTitleInput) this.editTitleInput.focus();
                                const length = this.editTitleInput.value?.length || 0;
                                this.editTitleInput.editor.setSelection(length, 0);
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    editTitle = async () => {
        if (!this.state.atividade) {
            toast.info('O campo de descrição da atividade não pode estar vazio.');
            return;
        }

        this.setState({ updatingTitle: true });

        await Sig.request('POST', 'planoAcao/updateEtapaText', {
            id: this.props.etapa.id,
            atividade: this.state.atividade,
            justificativa: this.state.justificativa,
            descricao: this.state.descricao
        });

        this.setState({ updatingTitle: false, editingTitle: false });
    }

    renderEditTitle(placeholder, height = 45) {
        return (
            <div key={"etapa-"+this.props.etapa.id} style={{ ...EssentialStyle.rowFlexStart, height: 64, flexGrow: 1, gap: 5 }} onClick={e => e.stopPropagation()}>
                <TextEditor
                    ref={ref => this.editTitleInput = ref}
                    defaultValue={this.state[this.state.editingTitle]}
                    placeholder={placeholder}
                    onChange={(value) => {
                        this.setState({ [this.state.editingTitle]: value })
                    }}
                    height={height}
                    hideToolbar
                    noMargin
                    disabledEnterEvent
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [() => { 
                                if (evt.shiftKey) return;
                                this.editTitle();
                            }, (e) => { 
                                e.preventDefault();
                                this.setState({ [this.state.editingTitle]: this.state.prev[this.state.editingTitle], editingTitle: false });
                            }]
                        )
                    }}
                />
                <DefaultButton
                    width={48}
                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                    color={Colors.dark}
                    loading={this.state.updatingTitle}
                    onClick={(e) => { e.stopPropagation(); this.setState({ [this.state.editingTitle]: this.state.prev[this.state.editingTitle], editingTitle: false }) }}
                />
                <DefaultButton
                    width={48}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                    color={Colors.dark}
                    loading={this.state.updatingTitle}
                    onClick={(e) => { e.stopPropagation(); this.editTitle() }}
                />
            </div>
        );
    }

    onClick = async () => {
        if(this.props.detailsModalCallback)
            this.props.detailsModalCallback(this.props.etapa.id);
        else if(this.props.onClick)
            this.props.onClick(this.props.etapa.id);
    }

    render() {
        let cardStyle = {
            width: '100%',
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: Colors.white,
            border: this.props.noBorder ? "none" : `1px solid ${Colors.border}`,
            borderTop: this.props.noTopBorder || this.props.noBorder ? 'none' : `1px solid ${Colors.border}`,
            borderBottom: this.props.borderBottom ? this.props.borderBottom : 'none',
            cursor: this.props.defaultCursor ? 'default' : 'pointer',
            borderRadius: this.props.softBorders ? 8 : 0,
        }

        return (
            <div style={cardStyle} className={`plano-acao-etapa-card ${this.isWarning() ? 'warning' : ''}`} onClick={this.onClick}>
                {this.props.detailed ? this.renderDetailed() : (this.props.resumed ? this.renderResumed() : this.renderNormal())}
            </div>
        )
    }
}