import React from "react";
import ReactDOM from 'react-dom';
import { forwardRef } from "react";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCog, faChartSimple, faMaximize, faMinimize, faChartPie, faPlus, faEnvelope, faArrowUpRightFromSquare, faChevronRight, faUser, faUserGroup, faCaretUp, faCaretDown, faChevronLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Highcharts from "highcharts/highcharts.js";
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";

import Select from 'react-select';
import { Dropdown, Accordion, Collapse } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { toast } from 'react-toastify';

import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import SessionHelper from "../../../../../helper/SessionHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import DefaultButton from "../../../../tools/DefaultButton";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DataHelper from "../../../../../helper/DataHelper";
import Colors from "../../../../../constants/Colors";
import Constants from "../../../../../constants/Api";
import Sig from "../../../../../api/Sig";
import IndicadorGauge from "./IndicadorGauge";
import CustomTooltip from "../../../../tools/CustomTooltip";
import LayoutHelper from "../../../../../helper/LayoutHelper";

accessibility(Highcharts);

const gateway = Constants.getSigEndPoint();

const indicadoresDistintos = 0;
const periodosDistintos = 1;
export default class Indicadores extends React.Component {
    state = {
        loading: true,
        me: false,
        configuring: false,
        configuringIndicadores: false,
        expanded: false,
        paineisList: [],
        selectedPaineis: [],
        paineisConfig: [],
        selectedPainel: null,
        selectedPainelConfig: {},
        indicadoresPainel: [],
        selectedIndicadores: [],

        indicadorExpanded: false,                   // Indicador expandido
        indicadorExpandedNome: false,               // Nome do indicador expandido
        painelExpanded: false,                      // Painel do indicador expandido
      
        loadingIndicadorExpanded: false,            // Indica se o indicador expandido está carregando
        indicadorExpandedHistory: false,            // Histórico do indicador expandido
        indicadorGraphicOptions: null,              // Opções do gráfico do indicador expandido

        indicadorCompareType: null,                 // Tipo de coparação de indicadores
        selectedPaineisComparar: null,              // Painel selecionado para comparar
        indicadoresPainelComparar: [],              // Indicadores do painel selecionado para comparar
        indicadorComparar: null,                    // Indicador selecionado para comparar
        indicadorExpandedHistoryComparar: false,    // Histórico do indicador selecionado para comparar
        indicadorUnidadeDistintas: false,           // Indica se as unidades dos indicadores são distintas
        indicadorCompararExpandedVisible: true,     // Indica se o indicador expandido está visível

        graphicOptions: [
            {
                value: 'ocultar',
                label: 'Valor Oculto',
            },
            {
                value: 'barra',
                label: 'Barra',
            },
            {
                value: 'linha',
                label: 'Linha',
            },
        ],
        configureGraphic: {
            metaRealizado: "barra",
            performance: "linha",
        },

        configureGraphicComparar: {
            metaRealizado: "barra",
            performance: "linha",
        },

        tendencia: {},
        performanceDataInicio: moment().subtract(1, 'year').startOf('month').toDate(),
        performanceDataFim: moment().subtract(1, 'month').endOf('month').toDate(),

        performanceDataInicioComparar: moment().subtract(2, 'year').startOf('month').toDate(),
        performanceDataFimComparar: moment().subtract(1, 'year').subtract(1, 'month').endOf('month').toDate(),

        indicadoresData: moment().subtract(1, 'month').startOf('month').toDate(),
        acumulado: false,
        hoverAddIndicador: false,
        hoverSplitButton: false,
        indicadoresExpanded: false,

        possuiGMR: true,
        possuiModuloGMR: true,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        if (this.props.userId) {

            this.setState({ me: (this.props.userId === SessionHelper.getData().id_usuario) });

            await this.refreshPaineisPossoVer();
            await this.getConfig(this.selectFirstPainel);
        }
    }

    async componentDidUpdate(prevProps) {
        
        if (this.props.userId && (this.props.userId !== prevProps.userId || this.props.viewUserConfig !== prevProps.viewUserConfig)) {
            
            this.setState({
                loading: true,
                me: (this.props.userId === SessionHelper.getData().id_usuario),
                selectedPainel: null,
                selectedPainelConfig: {},
                selectedPaineis: [],
                paineisConfig: [],
                configuring: false,
                configuringIndicadores: false,

                indicadorExpanded: false,
                indicadorExpandedNome: false,
                painelExpanded: false,
            
                loadingIndicadorExpanded: false,
                indicadorExpandedHistory: false,
                indicadorGraphicOptions: null,

            });

            let { paineis, possuiGMR, possuiModuloGMR } = await Sig.request('GET', 'inicio/getInicioConfig', { id_colaborador: this.props.userId, view_as_colaborador: this.props.viewUserConfig });

            if(paineis?.length === 0) {
                this.setState({ loading: true, paineisConfig: [], selectedPainel: null, selectedPainelConfig: {}});
            } else {
                this.setState({ loading: true, paineisConfig: paineis }, () => { this.selectFirstPainel() });
            }

            this.setState({possuiGMR, possuiModuloGMR },  () => { this.setState({ loading: false }) });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
        this.updateIndicadorGraphicOptionsWidth();
        this.updateTendenciaWidth();
    }

    async laodIndicadorExpanded() {
        this.setState({ loadingIndicadorExpanded: true });
        if (this.state.indicadorExpanded) {
            let data_inicio = moment(this.state.performanceDataInicio).format('YYYY-MM-DD HH:mm:ss');
            let data_fim = moment(this.state.performanceDataFim).format('YYYY-MM-DD HH:mm:ss');
            let data_indicador = moment(this.state.indicadoresData).unix();
            let { indicadorExpandedHistory } = await Sig.request('POST', 'config/colaborador/getIndicadorHistory', {
                id_indicador: this.state.indicadorExpanded,
                id_gmr_painel: this.state.painelExpanded,
                data_inicio,
                data_fim,
                data_indicador,
                acumulado: this.state.acumulado,
            });

            this.setState({ indicadorExpandedHistory }, () => {
                this.calculateGraphic();
            });
        }
    }

    async laodIndicadorExpandedComparar() {

        if ((this.state.indicadorCompareType == indicadoresDistintos && this.state.indicadorComparar ) || this.state.indicadorCompareType == periodosDistintos) {
            let data_inicio = moment(this.state.indicadorCompareType == indicadoresDistintos ? this.state.performanceDataInicio : this.state.performanceDataInicioComparar).format('YYYY-MM-DD HH:mm:ss');
            let data_fim = moment(this.state.indicadorCompareType == indicadoresDistintos ? this.state.performanceDataFim : this.state.performanceDataFimComparar).format('YYYY-MM-DD HH:mm:ss');
            let data_indicador = moment(this.state.indicadoresData).unix();
            let { indicadorExpandedHistory } = await Sig.request('POST', 'config/colaborador/getIndicadorHistory', {
                id_indicador: this.state.indicadorCompareType == indicadoresDistintos ? this.state.indicadorComparar.value : this.state.indicadorExpanded,
                id_gmr_painel: this.state.indicadorCompareType == indicadoresDistintos ?this.state.selectedPaineisComparar.value : this.state.painelExpanded,
                data_inicio,
                data_fim,
                data_indicador,
                acumulado: this.state.acumulado,
            });

            this.setState({ indicadorExpandedHistoryComparar: indicadorExpandedHistory }, () => {
                this.calculateGraphic();
            });
        }
    }

    calculateGraphic() {

        let unidade = this.state.indicadorExpandedHistory?.unidade;
        let moeda = this.state.indicadorExpandedHistory?.moedaSimbolo;
        let hasMetaAjustada = this.state.indicadorExpandedHistory?.hasMetaAjustada;

        let yAxis = [];
        let xAxis = [];
        let type = null;
        let series = [];
        let spacing = [5, 10, -5, 5];
        let tipoPerformance = this.state.configureGraphic.performance;
        let tipoMetaRealizado = this.state.configureGraphic.metaRealizado;

        let tipoPerformanceComparar = this.state.configureGraphicComparar.performance;
        let tipoMetaRealizadoComparar = this.state.configureGraphicComparar.metaRealizado;

        if (this.state.indicadorCompareType == null || this.state.indicadorCompareType == periodosDistintos) {
            
            if ((tipoMetaRealizado == "barra" || tipoPerformance == "barra") && (tipoMetaRealizado == "linha" || tipoPerformance == "linha")) {
                type = 'xy';
                yAxis = [
                    {// Primary yAxis
                        title: {
                            text: tipoMetaRealizado == "barra" ? "Meta e Realizado" : "Performance",
                            style: {
                                color: Colors.dark
                            }
                        },
                        labels: {
                            formatter: function () {
                                if(tipoMetaRealizado == "barra") {
                                    if (unidade == 0 || unidade == 1) {
                                        return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                    } else if (unidade == 2 || unidade == 3) {
                                        return this.value + '%';
                                    } else if (unidade == 6 || unidade == 7) {
                                        return this.value + ' Dias';
                                    } else if (unidade == 8 || unidade == 9) {
                                        return this.value + ' Horas';
                                    } else {
                                        return this.value;
                                    }
                                } else {
                                    return this.value + '%';
                                }
                            },
                            style: {
                                color: Colors.dark
                            }
                        }
                    },
                    { // Secondary yAxis
                        title: {
                            text: tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                            style: {
                                color: Colors.dark
                            }
                        },
                        labels: {
                            formatter: function () {
                                if(tipoMetaRealizado == "linha") {
                                    if (unidade == 0 || unidade == 1) {
                                        return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                    } else if (unidade == 2 || unidade == 3) {
                                        return this.value + '%';
                                    } else if (unidade == 6 || unidade == 7) {
                                        return this.value + ' Dias';
                                    } else if (unidade == 8 || unidade == 9) {
                                        return this.value + ' Horas';
                                    } else {
                                        return this.value;
                                    }
                                } else {
                                    return this.value + '%';
                                }
                            },
                            style: {
                                color: Colors.dark
                            }
                        },
                        opposite: true
                    }]
            } else if ((tipoMetaRealizado == "linha" && tipoPerformance == "linha") || (tipoMetaRealizado == "barra" && tipoPerformance == "barra")) {
                type = tipoMetaRealizado == "linha" ? 'spline' : 'column';
                yAxis = [
                    { // Primary yAxis Meta e Realizado
                        labels: {
                            formatter: function () {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            },
                            style: {
                                color: Colors.dark
                            }
                        },
                        title: {
                            text: "Meta e Realizado",
                            style: {
                                color: Colors.dark
                            }
                        }
                    }, { // Secondary yAxis Performance
                        title: {
                            text: "Performance",
                            style: {
                                color: Colors.dark
                            }
                        },
                        labels: {
                            format: "{value}%",
                            style: {
                                color: Colors.dark
                            }
                        },
                        opposite: true
                    }]
            } else {
                type = tipoMetaRealizado == "linha" || tipoPerformance == "linha" ? 'spline' : 'column';
                yAxis = [{ // Primary yAxis Meta e Realizado
                    labels: {
                        formatter: function () {
                            if(tipoMetaRealizado == "linha" || tipoMetaRealizado == "barra") {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            } else {
                                return this.value + '%';
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    },
                    title: {
                        text: tipoMetaRealizado == "barra" || tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                        style: {
                            color: Colors.dark
                        }
                    }
                }]
            }

            if (tipoMetaRealizado != 'ocultar') {
                series.push(this.getSerieData('Meta', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaPeriodos, 'meta', 'metaValue', 10));
                hasMetaAjustada && series.push(this.getSerieData('Meta Ajustada', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 0));
                series.push(this.getSerieData('Realizado', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 28));

                if (this.state.indicadorCompareType == periodosDistintos){
                    let hasMetaAjustadaComparar = this.state.indicadorExpandedHistoryComparar?.hasMetaAjustada;
                    series.push(this.getSerieData('Meta Comparada', tipoMetaRealizado, 0, 1, this.state.indicadorExpandedHistoryComparar.performanceMetaPeriodos, 'meta', 'metaValue', 3));
                    hasMetaAjustadaComparar && series.push(this.getSerieData('Meta Ajustada Comparada', tipoMetaRealizado, 0, 1, this.state.indicadorExpandedHistoryComparar.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 4));
                    series.push(this.getSerieData('Realizado Comparado', tipoMetaRealizado, 0, 1, this.state.indicadorExpandedHistoryComparar.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 5));
                }
            }

            if (tipoPerformance != 'ocultar') {
                series.push(this.getSerieData('Performance', tipoPerformance, tipoMetaRealizado == 'ocultar' ? 0 : 1, 0, this.state.indicadorExpandedHistory?.performancePeriodos, 'performance', 'performanceValue', 1));

                if (this.state.indicadorCompareType == periodosDistintos){
                    series.push(this.getSerieData('Performance Comparada', tipoPerformance, tipoMetaRealizado == 'ocultar' ? 0 : 1, 1, this.state.indicadorExpandedHistoryComparar.performancePeriodos, 'performance', 'performanceValue', 7));
                }
            }

        } else if (this.state.indicadorCompareType == indicadoresDistintos) {
            let barra = tipoPerformance == 'barra' || tipoMetaRealizado == 'barra' || tipoPerformanceComparar == 'barra' || tipoMetaRealizadoComparar == 'barra';
            let linha = tipoPerformance == 'linha' || tipoMetaRealizado == 'linha' || tipoPerformanceComparar == 'linha' || tipoMetaRealizadoComparar == 'linha';

            if (barra && linha) {
                type ='xy';
            } else if (linha) {
                type = 'spline';
            } else {
                type = 'column';
            }

            if (!this.state.indicadorUnidadeDistintas) {
                if (tipoMetaRealizado != 'ocultar' || tipoMetaRealizadoComparar != 'ocultar') {
                    yAxis.push(
                        {
                            labels: {
                                formatter: function () {
                                    if (unidade == 0 || unidade == 1) {
                                        return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                    } else if (unidade == 2 || unidade == 3) {
                                        return this.value + '%';
                                    } else if (unidade == 6 || unidade == 7) {
                                        return this.value + ' Dias';
                                    } else if (unidade == 8 || unidade == 9) {
                                        return this.value + ' Horas';
                                    } else {
                                        return this.value;
                                    }
                                },
                                style: {
                                    color: Colors.dark
                                }
                            },
                            title: {
                                text: "Meta e Realizado",
                                style: {
                                    color: Colors.dark
                                }
                            }
                        }
                    )

                    if (tipoMetaRealizado != 'ocultar') {
                        series.push(this.getSerieData('Meta', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceMetaPeriodos, 'meta', 'metaValue', 10));
                        hasMetaAjustada && series.push(this.getSerieData('Meta Ajustada', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 0));
                        series.push(this.getSerieData('Realizado', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 28));
                    }

                    if (tipoMetaRealizadoComparar != 'ocultar') {
                        let hasMetaAjustadaComparar = this.state.indicadorExpandedHistoryComparar?.hasMetaAjustada;
                        series.push(this.getSerieData('Meta Comparada', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceMetaPeriodos, 'meta', 'metaValue', 3));
                        hasMetaAjustadaComparar && series.push(this.getSerieData('Meta Ajustada Comparada', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 4));
                        series.push(this.getSerieData('Realizado Comparado', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 5));
                    }
                }
                if (tipoPerformance != 'ocultar' || tipoPerformanceComparar != 'ocultar') {
                    yAxis.push(
                        {
                            labels: {
                                formatter: function () {
                                    return this.value + '%';
                                },
                                style: {
                                    color: Colors.dark
                                }
                            },
                            title: {
                                text: "Performance",
                                style: {
                                    color: Colors.dark
                                }
                            },
                            opposite: tipoMetaRealizado != 'ocultar' || tipoMetaRealizadoComparar != 'ocultar',
                        }
                    )

                    if (tipoPerformance != 'ocultar') {
                        series.push(this.getSerieData('Performance', tipoPerformance, tipoMetaRealizado == 'ocultar' && tipoMetaRealizadoComparar == 'ocultar' ? 0 : 1, null, this.state.indicadorExpandedHistory?.performancePeriodos, 'performance', 'performanceValue', 1));
                    }

                    if (tipoPerformanceComparar != 'ocultar') {
                        series.push(this.getSerieData('Performance Comparada', tipoPerformanceComparar, tipoMetaRealizado == 'ocultar' && tipoMetaRealizadoComparar == 'ocultar' ? 0 : 1, null, this.state.indicadorExpandedHistoryComparar.performancePeriodos, 'performance', 'performanceValue', 7));
                    }
                }
            } else {
                if (tipoMetaRealizado != 'ocultar' || tipoPerformance != 'ocultar') {
                    let unidadeIndicador = this.state.indicadorExpandedHistory?.unidade;
                    yAxis.push(
                        {
                            labels: {
                                formatter: function () {
                                    if (tipoMetaRealizado != 'ocultar') {
                                        if (unidadeIndicador == 0 || unidadeIndicador == 1) {
                                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                        } else if (unidadeIndicador == 2 || unidadeIndicador == 3) {
                                            return this.value + '%';
                                        } else if (unidadeIndicador == 6 || unidadeIndicador == 7) {
                                            return this.value + ' Dias';
                                        } else if (unidadeIndicador == 8 || unidadeIndicador == 9) {
                                            return this.value + ' Horas';
                                        } else {
                                            return this.value;
                                        }
                                    } else {
                                        return this.value + '%';
                                    }
                                },
                                style: {
                                    color: Colors.dark
                                }
                            },
                            title: {
                                text: tipoMetaRealizado != 'ocultar' ? "Meta e Realizado" : 'Performance',
                                style: {
                                    color: Colors.dark
                                }
                            }
                        }
                    )

                    if (tipoMetaRealizado != 'ocultar') {
                        series.push(this.getSerieData('Meta', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceMetaPeriodos, 'meta', 'metaValue', 10));
                        hasMetaAjustada && series.push(this.getSerieData('Meta Ajustada', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 0));
                        series.push(this.getSerieData('Realizado', tipoMetaRealizado, 0, null, this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 28));
                    }

                    if (tipoPerformance != 'ocultar') {
                        series.push(this.getSerieData('Performance', tipoPerformance, tipoMetaRealizado == 'ocultar' ? 0 : 1, null, this.state.indicadorExpandedHistory?.performancePeriodos, 'performance', 'performanceValue', 3));
                    }
                }
                if (tipoMetaRealizadoComparar != 'ocultar' || tipoPerformanceComparar != 'ocultar') {
                    let unidadeIndicadorComparar = this.state.indicadorExpandedHistoryComparar.unidade;

                    yAxis.push(
                        {
                            labels: {
                                formatter: function () {
                                    if (tipoMetaRealizadoComparar != 'ocultar') {
                                        if (unidadeIndicadorComparar == 0 || unidadeIndicadorComparar == 1) {
                                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                        } else if (unidadeIndicadorComparar == 2 || unidadeIndicadorComparar == 3) {
                                            return this.value + '%';
                                        } else if (unidadeIndicadorComparar == 6 || unidadeIndicadorComparar == 7) {
                                            return this.value + ' Dias';
                                        } else if (unidadeIndicadorComparar == 8 || unidadeIndicadorComparar == 9) {
                                            return this.value + ' Horas';
                                        } else {
                                            return this.value;
                                        }
                                    } else {
                                        return this.value + '%';
                                    }
                                },
                                style: {
                                    color: Colors.dark
                                }
                            },
                            title: {
                                text: tipoMetaRealizadoComparar != 'ocultar' ? "Meta e Realizado comparado" : 'Performance Comparada',
                                style: {
                                    color: Colors.dark
                                }
                            },
                            opposite: tipoMetaRealizado != 'ocultar' || tipoPerformance != 'ocultar',
                        }
                    )

                    if (tipoMetaRealizadoComparar != 'ocultar') {
                        let hasMetaAjustadaComparar = this.state.indicadorExpandedHistoryComparar?.hasMetaAjustada;
                        series.push(this.getSerieData('Meta Comparada', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceMetaPeriodos, 'meta', 'metaValue', 4));
                        hasMetaAjustadaComparar && series.push(this.getSerieData('Meta Ajustada Comparada', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 5));
                        series.push(this.getSerieData('Realizado Comparado', tipoMetaRealizadoComparar, 0, null, this.state.indicadorExpandedHistoryComparar.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 1));
                    }

                    if (tipoPerformanceComparar != 'ocultar') {
                        series.push(this.getSerieData('Performance Comparada', tipoPerformanceComparar, tipoMetaRealizado != 'ocultar' || tipoPerformance != 'ocultar' ? 1 : 0, null, this.state.indicadorExpandedHistoryComparar.performancePeriodos, 'performance', 'performanceValue', 7));
                    }
                }
            }
        }

        xAxis.push(
            {
                categories: this.state.indicadorExpandedHistory?.periodo.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            }
        )

        if (this.state.indicadorCompareType == periodosDistintos) {
            xAxis.push(
                {
                    categories: this.state.indicadorExpandedHistoryComparar.periodo.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                    accessibility: {
                        description: 'Períodos do Painel no GMR'
                    },
                    opposite: true,
                }
            )
        }

        let indicadorGraphicOptions = {
            chart: {
                type: type,
                backgroundColor: 'transparent',
                width: ChartHelper.getWidth(50),
                height: null,
                spacing: spacing,
            },
            title: {
                text: '',
                y: 9,
            },
            xAxis: xAxis,
            yAxis: yAxis,
            legend: {
                enabled: true,
            },
            tooltip: {
                shared: this.state.indicadorCompareType == periodosDistintos ? false : true,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                    },
                    lineWidth: 2,
                    dashStyle: 'none'
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        }

        this.setState({ indicadorGraphicOptions });
        this.setState({ loadingIndicadorExpanded: false }, () => { this.updateIndicadorGraphicOptionsWidth() });
    }

    getSerieData = (name, tipoMetaRealizado, yAxis, xAxis, serie, field, fieldValue, idColor) => {

        let serieObj = {
            name,
            type: tipoMetaRealizado == "barra" ? 'column' : 'spline',
            yAxis,
            xAxis,
            data: serie.map((key) => ({
                y: parseFloat(key[field]),
                yFormated: key[fieldValue],
            })),
            color: Colors.graphics[idColor]
        };

        return serieObj;
    }

    selectFirstPainel = () => {
        if (this.state.paineisConfig && this.state.paineisConfig.length > 0) {
            let selectedPainel = DataHelper.formatSelectData([this.state.paineisConfig[0]], 'id_gmr_painel', 'id_gmr_painel_nome')[0];

            this.setState({ selectedPainel }, async () => {
                await this.getSelectedPainel(selectedPainel.value);
            });
        }
    }

    async refreshPaineisPossoVer() {
        let paineisList = [];

        paineisList = await Sig.request('GET', 'inicio/getPaineisPossoVer', { id_colaborador: this.props.userId });

        this.setState({ paineisList });
    }

    async toggleConfiguring() {
        this.setState({ loading: true });

        let selectedPaineis = [];

        if (!this.state.configuring) {
            await this.getConfig();
            await this.refreshPaineisPossoVer();
            selectedPaineis = DataHelper.formatSelectData(this.state.paineisConfig, 'id_gmr_painel', 'id_gmr_painel_nome');
        }

        this.setState({ selectedPaineis }, () => {
            this.setState({ configuring: !this.state.configuring, loading: false });
        });
    }

    async saveConfig() {
        this.setState({ loading: true });

        if (!this.getCanEdit()) {
            this.setState({ configuring: false, loading: false });
            return toast.error('Você não tem permissão para alterar a configuração de outro usuário!');
        }

        let paineis = this.state.selectedPaineis.map(painel => painel.value);

        await Sig.request('POST', 'inicio/setInicioConfigPaineis', { id_colaborador: this.props.userId, paineis });
        await this.getConfig(this.selectFirstPainel);

        this.setState({ configuring: false, loading: false });
    }

    async getConfig(callback = () => { }) {
        this.setState({ loading: true }, async () => {
            let { paineis, possuiGMR, possuiModuloGMR } = await Sig.request('GET', 'inicio/getInicioConfig', { id_colaborador: this.props.userId, view_as_colaborador: this.props.viewUserConfig });

            if(paineis?.length === 0) {
                this.setState({ paineisConfig: [], selectedPainel: null, selectedPainelConfig: {}, possuiGMR, possuiModuloGMR}, () => { this.setState({ loading: false }); callback(); });
                return;
            }

            this.setState({ paineisConfig: paineis, possuiGMR, possuiModuloGMR }, () => { this.setState({ loading: false }); callback(); });
        });
    }

    selectIndicadorExpanded = (indicador, painel) => {
        this.setState({ loadingIndicadorExpanded: true })

        if (this.state.indicadorExpanded === indicador.id) {

            this.setState({
                indicadorExpanded: false, 
                indicadorExpandedNome: false, 
                painelExpanded: false, 
                loadingIndicadorExpanded: false,
                indicadorCompareType: null,
                selectedPaineisComparar: null,
                indicadoresPainelComparar: [],
                indicadorComparar: null,
                indicadorExpandedHistoryComparar: false,
                indicadorUnidadeDistintas: false,
                }, () => {
                this.getSelectedPainel(this.state.selectedPainel.value);
            });
        } else {
            this.setState({ indicadorExpanded: indicador.id, indicadorExpandedNome: indicador.nome, painelExpanded: painel }, () => {
                this.laodIndicadorExpanded();
            });

        }
    }

    selectCompareIndicador(type){

        if (!this.state.expanded){
            this.setState(
                { expanded: !this.state.expanded, loading: true },
                () => {
                    this.props.expandCallbackPageZero(this.state.expanded ? 'indicador' : false);
                    setTimeout(() => {
                        this.setState({ loading: false }, () => {
                            this.updateIndicadorGraphicOptionsWidth();
                        })
                    }, 400);
                });
        }

        if (type == indicadoresDistintos) this.refreshPaineisPossoVer();

        this.setState({ indicadorCompareType: this.state.indicadorCompareType == type ? null : type }, () => { 
            this.state.indicadorCompareType == null ? this.calculateGraphic() : this.laodIndicadorExpandedComparar();
        });
    }

    async getIndicadoresPainel(){
        let indicadoresPainelComparar = await Sig.request('GET', 'inicio/getIndicadoresPainel', { id_gmr_painel: this.state.selectedPaineisComparar.value });
        this.setState({ indicadoresPainelComparar });
    }

    async verificaUnidade(){

        let { unidade: unidadeA } = await Sig.request('GET', 'inicio/getIndicadorUnidade', { id_indicador: this.state.indicadorComparar.value });
        let unidadeIndicadorA = parseInt(unidadeA);

        let { unidade: unidadeB } = await Sig.request('GET', 'inicio/getIndicadorUnidade', { id_indicador: this.state.indicadorExpanded });
        let unidadeIndicadorB = parseInt(unidadeB);


        if(unidadeIndicadorA % 2 !== 0){
            unidadeIndicadorA -= 1;
        }

        if(unidadeIndicadorB % 2 !== 0){
            unidadeIndicadorB -= 1;
        }

        if(unidadeIndicadorA != unidadeIndicadorB){
            this.setState({ indicadorUnidadeDistintas: true }, () => {this.corrigeUnidadeDistinta()});
        } else {
            this.setState({ indicadorUnidadeDistintas: false }, () => {this.laodIndicadorExpandedComparar()});
        }
    }

    corrigeUnidadeDistinta(){
        if (this.state.configureGraphic.metaRealizado != 'ocultar' && this.state.configureGraphic.performance != 'ocultar'){
            this.setState({ configureGraphic: { metaRealizado: 'barra', performance: 'ocultar' } });
        }

        if (this.state.configureGraphicComparar.metaRealizado != 'ocultar' && this.state.configureGraphicComparar.performance != 'ocultar'){
            this.setState({ configureGraphicComparar: { metaRealizado: 'linha', performance: 'ocultar' } });
        }

        this.laodIndicadorExpandedComparar();
    }

    optionGraphicIsNull() {
        let response = this.state.configureGraphic.metaRealizado != 'ocultar' || this.state.configureGraphic.performance != 'ocultar'
        || (this.state.indicadorCompareType == indicadoresDistintos 
            && (this.state.configureGraphicComparar.metaRealizado != 'ocultar' 
            || this.state.configureGraphicComparar.performance != 'ocultar'
            || this.state.configureGraphic.metaRealizado != 'ocultar' 
            || this.state.configureGraphic.performance != 'ocultar')) 
        
        return !response;
    }

    renderChartHeader() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
                paddingBottom: 4,
                height: 43,
                minHeight: 43,
                borderBottom: `1px solid ${Colors.homePage.line}`,
            }}>
                {
                    !this.state.configuring ?
                        <div style={{
                            ...EssentialStyle.rowFlexStart
                        }}>
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                style={{
                                    color: Colors.dark,
                                    marginRight: 10,
                                    marginLeft: 10,
                                    cursor: 'pointer'
                                }}
                                onClick={() => { window.parent.postMessage({ type: '_blank', url: `/acompanhamentoResultados/gerenciar/${this.state.selectedPainel.value}` }, '*'); }}
                            />
                            <Select
                                styles={{ control: (base) => ({ ...base, ...EssentialStyle.titleBoxHomePage, border: 'none' }), singleValue: (provided) => ({ ...provided, color: Colors.homePage.title }) }}
                                value={this.state.selectedPainel}
                                options={DataHelper.formatSelectData(this.state.paineisConfig, 'id_gmr_painel', 'id_gmr_painel_nome')}
                                placeholder={'Selecione o Painel'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                menuPortalTarget={document.body}
                                onChange={(value) => {
                                    this.setState({
                                        indicadorExpanded: false,
                                        indicadorExpandedNome: false,
                                        painelExpanded: false,
                                        loadingIndicadorExpanded: false
                                    }, async () => {
                                        this.setState({ selectedPainel: value }, async () => {
                                            await this.getSelectedPainel(this.state.selectedPainel.value);
                                        });
                                    });
                                }}
                            />
                        </div>
                        : <div />
                }

                <div style={{
                    ...EssentialStyle.rowFlexStart
                }}>
                    {
                        this.state.indicadorExpanded ?
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                                tooltip={'Voltar'}
                                tooltipPlacement={'left'}
                                color={'transparent'}
                                textColor={SessionHelper.getColor()}
                                onClick={() => {
                                    this.selectIndicadorExpanded({ 'id': this.state.indicadorExpanded, nome: this.state.indicadorExpandedNome }, this.state.selectedPainel.value);
                                }}
                            />
                            :
                            null
                    }
                    <DefaultButton
                        leftIcon={!this.state.expanded ? <FontAwesomeIcon icon={faMaximize} /> : <FontAwesomeIcon icon={faMinimize} />}
                        color={'transparent'}
                        textColor={SessionHelper.getColor()}
                        onClick={() => {
                            this.setState(
                                { expanded: !this.state.expanded, loading: true },
                                () => {
                                    this.props.expandCallbackPageZero(this.state.expanded ? 'indicador' : false);
                                    this.setState({ indicadorCompareType: null })
                                    setTimeout(() => {
                                        this.setState({ loading: false }, () => {
                                            this.updateTendenciaWidth();
                                            this.updateIndicadorGraphicOptionsWidth();
                                        })
                                    }, 400);

                                });
                        }}
                    />
                    {this.getCanEdit() &&
                        <DefaultButton
                            leftIcon={!this.state.configuring ? <FontAwesomeIcon icon={faCog} /> : <FontAwesomeIcon icon={faChartSimple} />}
                            color={'transparent'}
                            textColor={SessionHelper.getColor()}
                            onClick={() => { this.toggleConfiguring() }}
                        />
                    }
                </div>

            </div>
        );
    }

    updateIndicadorGraphicOptionsWidth() {

        let indicadorGraphicOptionsContainer = document.getElementById('indicadorGraphicOptions');

        if (indicadorGraphicOptionsContainer) {
            let indicadorGraphicOptions = this.state.indicadorGraphicOptions;
            indicadorGraphicOptions.chart.width = indicadorGraphicOptionsContainer.offsetWidth;
            indicadorGraphicOptions.chart.height = indicadorGraphicOptionsContainer.offsetHeight;

            this.setState({ loading: true });
            this.setState({ indicadorGraphicOptions }, () => { this.setState({ loading: false }) });
        }
    }

    updateTendenciaWidth() {
        let tendenciaContainer = document.getElementById('tendenciaChartContainer');

        if (tendenciaContainer) {
            let tendencia = this.state.tendencia;
            tendencia.chart.width = tendenciaContainer.offsetWidth;

            this.setState({ loading: true });
            this.setState({ tendencia }, () => { this.setState({ loading: false }) });
        }
    }

    async getSelectedPainel(id_gmr_painel = null) {
        this.setState({ loading: true });

        let painel = await Sig.request(
            'POST',
            'inicio/getPerformancePainel',
            {
                id_gmr_painel,
                id_colaborador: this.props.userId,
                dataInicio: moment(this.state.performanceDataInicio).unix(),
                dataFim: moment(this.state.performanceDataFim).unix(),
                view_as_colaborador: this.props.viewUserConfig
            }
        );

        let selectedPainelConfig = {};

        for (let key in this.state.paineisConfig) {
            if (this.state.paineisConfig[key].id_gmr_painel === id_gmr_painel) {
                selectedPainelConfig = this.state.paineisConfig[key];
                break;
            }
        }

        let indicadoresPainel = await Sig.request('GET', 'inicio/getIndicadoresPainel', { id_gmr_painel });

        let periodosValidos = Object.keys(painel.performancePeriodos).map((key) => (painel.performancePeriodos[key].performance));
        periodosValidos = periodosValidos.filter((periodo) => periodo != null);


        let tendencia = {
            chart: {
                type: periodosValidos.length > 1 ? 'spline' : 'column',
                backgroundColor: 'transparent',
                spacing: [7, 10, 0, 5],
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: Object.keys(painel.performancePeriodos).map((key) => (painel.performancePeriodos[key].periodoFormatado)),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Performance'
                },
                labels: {
                    format: '{value}%'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                pointFormatter: function () {
                    return `${this.category}: ${this.y.toFixed(2).replace('.', ',')}%`;
                }
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getSecondaryColor(),
                    },
                    lineColor: SessionHelper.getColor(),
                    lineWidth: 3,
                    dashStyle: 'none'
                }
            },
            series: [{
                name: 'Performance Geral',
                marker: {
                    symbol: 'diamond'
                },
                data: Object.keys(painel.performancePeriodos).map((key) => (painel.performancePeriodos[key].performance))
            }],
            credits: {
                enabled: false
            }
        }

        this.setState({ selectedPainelConfig, selectedIndicadores: [], indicadoresPainel, tendencia, loading: false });
    }

    renderConfiguring() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: 'calc(100% - 43px)'
            }}>
                <div style={(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexStart)}>
                    <img
                        src={`${gateway}/img/undraw/undraw_financial_data.svg`}
                        alt="Pessoa de costas olhando gráficos em um quadro"
                        style={{
                            height: '90%',
                            maxHeight: 180,
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        alignItems: 'flex-start',
                        alignContent: 'flex-start',
                        width: '100%',
                        height: '100%'
                    }}>
                        <span style={{ ...EssentialStyle.titleBoxHomePage, marginBottom: 5 }}>
                            Selecione os <strong>Painéis de Controle</strong> que você quer ver!
                        </span>

                        <Select
                            styles={{ control: (base) => ({ ...base, width: this.state.isSmallScreen ?  '90vw' : '40vw', maxHeight: this.state.expanded ? 250 : 80, overflow: 'auto' }) }}
                            value={this.state.selectedPaineis}
                            options={DataHelper.formatSelectData(this.state.paineisList, 'id', 'nome')}
                            placeholder={'Selecione o Painel'}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable
                            isMulti
                            menuPortalTarget={document.body}
                            onChange={(value) => {
                                this.setState({ selectedPaineis: value });
                            }}
                        />

                        <div style={{
                            ...EssentialStyle.rowFlexEnd,
                            width: '100%',
                            marginTop: 20
                        }}>
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faChartPie} />}
                                title={'Confirmar'}
                                onClick={() => { this.saveConfig() }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderTendency() {
        return (
            <div style={{
                ...EssentialStyle.columnSpaceBetween,
                width: '100%',
                height: '100%',
            }}>
                <div style={{
                    width: '100%'
                }}>
                    {this.renderControlsPerformance()}
                </div>
                <div
                    id="tendenciaChartContainer"
                    style={{
                        flex: 1,
                        display: 'flex',
                        width: '100%',
                        height: 'calc(100% - 29px)'
                    }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                        options={this.state.tendencia}
                    />
                </div>
            </div>
        );
    }

    renderControlsIndicadores() {

        let isSmallScreen = this.state.isSmallScreen;

        let stylesControls = {
            container: {
                ...EssentialStyle.columnStart,
                alignItems: 'flex-start',
                width: '100%',
                borderRadius: 5,
                margin: isSmallScreen ? '3px 0px' : '0px',
                padding: isSmallScreen ? '5px 10px': '5px 10px 0px 10px',
                backgroundColor: isSmallScreen ? Colors.homePage.extraLightGrey : 'transparent',
            },
            font: {
                marginLeft: 5,
                fontSize: 14,
                fontWeight: 'bold',
                width: 100,
                color: Colors.homePage.darkGrey
            }
        }

        return (
            <div style={stylesControls.container}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, width: 180 }}>
                        {this.renderAddIndicador()}
                        <span style={stylesControls.font}>Indicadores:</span>
                    </div>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: 220, height: '25px' }}>
                        <DefaultButton
                            style={{ width: 100, height: 25, padding: 0, marginLeft: 10, fontSize: 12 }}
                            textColor={this.state.acumulado ? Colors.white : Colors.dark}
                            color={this.state.acumulado ? Colors.success : Colors.homePage.lightGrey}
                            title={<b>{!this.state.acumulado ? 'Mensal' : 'Acumulado'}</b>}
                            tooltip={!this.state.acumulado ? 'Alternar para Acumulado' : 'Alternar para Mensal'}
                            onClick={() => { this.setState({ acumulado: !this.state.acumulado }) }}
                        />

                        {this.renderDatePicker('indicadoresData')}
                    </div>
                </div>
            </div>
        );
    }

    renderControlsPerformance() {

        let isSmallScreen = this.state.isSmallScreen;

        let stylesControls = {
            container: {
                ...EssentialStyle.columnStart,
                alignItems: 'flex-start',
                width: '100%',
                borderRadius: 5,
                margin: isSmallScreen ? '3px 0px' : '0px',
                padding: isSmallScreen ? '5px 10px' : '5px 10px 0px 10px',
                backgroundColor: isSmallScreen ? Colors.homePage.extraLightGrey : 'transparent',
                
            },
            font: {
                fontSize: 14,
                fontWeight: 'bold',
                color: Colors.homePage.darkGrey
            }
        }

        return (
            <div style={stylesControls.container}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                    <span style={stylesControls.font}>Performance Geral:</span>
                    <div style={ EssentialStyle.rowSpaceBetween }>
                        {this.renderDatePicker('performanceDataInicio', async () => { await this.getSelectedPainel(this.state.selectedPainel.value); })}
                        {this.renderDatePicker('performanceDataFim', async () => { await this.getSelectedPainel(this.state.selectedPainel.value); })}
                    </div>
                </div>
            </div>
        );
    }

    renderDatePicker(stateProp, callback = () => { }) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ marginLeft: 5, fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 90, textAlign: 'center', zIndex: 1 }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexStart
            }}>
                <DatePicker
                    selected={this.state[stateProp]}
                    type="date"
                    onChange={(event) => {
                        let novoInicio = moment(event);
                        if (stateProp === 'performanceDataInicio' && novoInicio.isAfter(this.state.performanceDataFim)) {
                            return;
                        }
                        if (stateProp === 'performanceDataFim' && novoInicio.isBefore(this.state.performanceDataInicio)) {
                            return;
                        }
                        if (stateProp === 'performanceDataInicioComparar' && novoInicio.isAfter(this.state.performanceDataFimComparar)) {
                            return;
                        }
                        if (stateProp === 'performanceDataFimComparar' && novoInicio.isBefore(this.state.performanceDataInicioComparar)) {
                            return;
                        }
                        if (novoInicio.toDate() !== this.state[stateProp]) {
                            this.setState({ [stateProp]: novoInicio.toDate() }, callback);
                        }
                        if (stateProp == 'performanceDataInicio' && this.state.indicadorCompareType != periodosDistintos) {
                            this.setState({ performanceDataInicioComparar: novoInicio.subtract(1, 'year').toDate() })
                        } else if (stateProp == 'performanceDataFim' && this.state.indicadorCompareType != periodosDistintos) {
                            this.setState({ performanceDataFimComparar: novoInicio.subtract(1, 'year').toDate() })
                        }
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    getCanEdit() {
        return (this.props.viewUserConfig && SessionHelper.getData().id_usuario == this.props.userId)
            || (!this.props.viewUserConfig);
    }

    async saveIndicadoresConfig() {
        this.setState({ loading: true });

        if (!this.getCanEdit()) {
            this.setState({ configuringIndicadores: false, loading: false });
            return toast.error('Você não tem permissão para alterar a configuração de outro usuário!');
        }

        let indicadores = this.state.selectedIndicadores.map(indicador => indicador.value);

        await Sig.request('POST', 'inicio/setInicioConfigIndicadores', { id_colaborador: this.props.userId, id_gmr_painel: this.state.selectedPainel.value, indicadores });

        await this.getConfig(async () => {
            await this.getSelectedPainel(this.state.selectedPainel.value);
        });

        this.setState({ configuringIndicadores: false, loading: false });
    }

    renderEmptyIndicadores() {
        if (!this.getCanEdit()) {
            return (
                <div style={{
                    ...EssentialStyle.rowSpaceEvenly,
                    width: '100%',
                    height: '100%'
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_pie_graph.svg`}
                        alt="Pessoa de tocando em um gráfico, como se adicionasse um elemento a ele"
                        style={{
                            width: '15%'
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        alignItems: 'flex-start',
                        alignContent: 'flex-start',
                        width: "calc(75% - 20px)",
                    }}>
                        <span style={{
                            ...EssentialStyle.titleBoxHomePage,
                            marginBottom: 10
                        }}>Este usuário ainda não configurou os indicadores para este painel.</span>
                    </div>
                </div>
            );
        }

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
            }}>
                <img src={`${gateway}/img/undraw/undraw_pie_graph.svg`} 
                    style={{ 
                        width: '25%'
                    }}
                    alt="Pessoa de tocando em um gráfico, como se adicionasse um elemento a ele"
                />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '75%',
                }}>
                    <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                        Agora vamos selecionar os <strong>Indicadores</strong> que você quer ver!
                    </span>

                    <div style={{display: 'flex', justifyContent: 'space-between', height: "calc(100% - 25px)"}}>
                        
                        <div style={{height: '100%', width: "calc(100% - 135px)"}}>
                            <Select
                                styles={{ control: (base) => ({ ...base, width: '100%', maxHeight: "80px", overflow: 'auto' }) }}
                                value={this.state.selectedIndicadores}
                                options={DataHelper.formatSelectData(this.state.indicadoresPainel, 'id', 'nome')}
                                placeholder={'Selecione os Indicadores'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                isMulti
                                menuPortalTarget={document.body}
                                onChange={(value) => {
                                    this.setState({ selectedIndicadores: value });
                                }}
                            />
                        </div>

                        <div style={{height: '100%', width: 135, ...EssentialStyle.columnEnd}}>
                            <DefaultButton
                                style={{marginLeft: 5}}
                                leftIcon={<FontAwesomeIcon icon={faChartPie} />}
                                title={'Confirmar'}
                                onClick={() => { this.saveIndicadoresConfig() }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    renderIndicadoresGrid() {
        

        let indicadores = this.state.selectedPainelConfig?.indicadores || [];
        let widthBox = ChartHelper.getWidth(this.state.isSmallScreen ? 100 : this.state.indicadoresExpanded ? 100 : 60) - 20;
        let minWidthGauge = this.state.isSmallScreen ? 100 : (this.state.indicadoresExpanded ? 250 : 200);
        let nMaxGaugeLine = Math.ceil(widthBox / minWidthGauge);
        let nLine = Math.ceil(indicadores.length/nMaxGaugeLine);

        // Caso o cartão esteja expandido, define um mínimo de 2 linhas de indicadores caso tenha mais de 2 indicadores
        if (nLine < 2 && indicadores.length > 2 && this.state.expanded){
            nLine = 2;
        }

        let nGaugeLine = Math.ceil(indicadores.length/nLine);

        let indicadoresGroups = [];
        for (let i = 0; i < indicadores.length; i += nGaugeLine) {
            indicadoresGroups.push(indicadores.slice(i, i + nGaugeLine));
        }

        let gaugesGrid = indicadoresGroups.map((group, index) => {
            return (
                <div key={`group-${index}`} style={{
                    ...EssentialStyle.rowSpaceEvenly,
                    width: '100%',
                    height: `${100 / indicadoresGroups.length}%`,
                    minHeight: 80,
                    maxHeight: 300,
                }}>
                    {
                        group.map((indicador, ig_index) => {
                            return (
                                <div key={`ig_index_${ig_index}`} style={{
                                    ...EssentialStyle.rowFlexCenter,
                                    display: 'flex',
                                    width: `${100 / (group.length)}%`,
                                    height: '100%',
                                    padding: 5,
                                }}>
                                    <IndicadorGauge
                                        nome={indicador.nome}
                                        id_indicador={indicador.id}
                                        id_gmr_painel={this.state.selectedPainel.value}
                                        data={this.state.indicadoresData}
                                        acumulado={this.state.acumulado}
                                        gauge={true}
                                        mergulho={false}
                                        onClick={() => { this.selectIndicadorExpanded(indicador, this.state.selectedPainel.value); }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            );
        });

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: nLine == 1 ?'center' : 'default',
                width: '100%',
                height: '100%'
            }}>
                {gaugesGrid}
            </div>
        );
    }

    renderAddIndicador() {
        return (
            <div
                onMouseEnter={() => { this.setState({ hoverAddIndicador: true }) }}
                onMouseLeave={() => { this.setState({ hoverAddIndicador: false }) }}
                onClick={() => {
                    let selectedIndicadores = [];
                    let idSelectedPainel = this.state.selectedPainel.value;
                    let configPainel = this.state.paineisConfig.find(painel => painel.id_gmr_painel === idSelectedPainel);
                    if (configPainel.indicadores.length > 0) selectedIndicadores = DataHelper.formatSelectData(configPainel.indicadores, 'id', 'nome');

                    this.setState({ selectedIndicadores, configuringIndicadores: !this.state.configuringIndicadores });
                }}
                style={{
                    ...EssentialStyle.columnCenter,
                    backgroundColor: Colors.homePage.lightGrey,
                    height: 22,
                    width: 22,
                    borderRadius: '50%',
                    cursor: 'pointer'
                }}>
                <FontAwesomeIcon
                    style={{
                        transform: `scale(${this.state.hoverAddIndicador ? 1.2 : 1}) rotate(${this.state.configuringIndicadores ? this.state.hoverAddIndicador ? '-225deg' : '45deg' : this.state.hoverAddIndicador ? '-180deg' : '0deg'})`,
                        transition: 'transform 0.3s',
                        color: Colors.dark
                    }}
                    icon={faPlus}
                />
            </div>
        );
    }

    renderIndicadores() {
        let indicadores = this.state.selectedPainelConfig?.indicadores || [];

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    width: '100%',
                    height: 40
                }}>
                    {this.renderControlsIndicadores()}
                </div>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: 'calc(100% - 40px)',
                    overflowY: 'auto'
                }}>
                    {indicadores.length > 0 && !this.state.configuringIndicadores ? this.renderIndicadoresGrid() : this.renderEmptyIndicadores()}
                </div>
            </div>
        );
    }

    renderNoSelection() {
        if (!this.getCanEdit()) {
            return (
                <div style={{
                    ...EssentialStyle.rowFlexCenter,
                    width: '100%',
                    height: 'calc(100% - 43px)'
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_online_collaboration.svg`}
                        alt="Pessoa de costas olhando gráficos em um quadro"
                        style={{
                            height: '90%',
                            maxHeight: 200
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '45%',
                        marginLeft: 20
                    }}>
                        <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                            Você está vendo a configuração do usuário, que está vazia, se você quiser editar mude para o modo <FontAwesomeIcon icon={faUserGroup} /> no botão abaixo.
                        </span>

                        <div style={{
                            ...EssentialStyle.rowFlexEnd,
                            width: '100%',
                            marginTop: 20
                        }}>
                            <DefaultButton
                                leftIcon={
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <CustomTooltip
                                            tooltip={'Visualizar a minha configuração para este membro da equipe'}
                                            placement={'top'}
                                        >
                                            <FontAwesomeIcon icon={faUserGroup} />
                                        </CustomTooltip>
                                        <Form.Switch
                                            type="switch"
                                            checked={this.state.viewUserConfig}
                                            style={{ marginLeft: 3, marginRight: 3 }}
                                        />
                                        <CustomTooltip
                                            tooltip={'Visualizar configuração do membro da equipe'}
                                            placement={'bottom'}
                                        >
                                            <FontAwesomeIcon icon={faUser} />
                                        </CustomTooltip>
                                    </div>
                                }
                                color={'transparent'}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={() => {
                                    let renderedBtn = document.getElementById('viewUserConfigSwitch');

                                    if (renderedBtn) {
                                        renderedBtn.click();
                                    }
                                }}
                                style={{ marginRight: 8 }}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{
                    ...EssentialStyle.rowFlexCenter,
                    width: '100%',
                    height: 'calc(100% - 43px)'
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_financial_data.svg`}
                        alt="Pessoa de costas olhando gráficos em um quadro"
                        style={{
                            height: '90%',
                            maxHeight: 200
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '40%'
                    }}>
                        <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                            Ainda não há <strong>Painéis de Controle</strong> selecionados. Selecione os que você deseja ver!
                        </span>

                        <div style={{
                            ...EssentialStyle.rowFlexEnd,
                            width: '100%',
                            marginTop: 20
                        }}>
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faCog} />}
                                title={'Selecionar Painéis'}
                                onClick={() => { 
                                    this.toggleConfiguring();
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderGraficoExpanded() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                {
                    this.optionGraphicIsNull() ?
                    
                    <div style={{
                        ...EssentialStyle.rowSpaceEvenly,
                        width: '100%',
                        height: '100%'
                    }}>
                        <img
                            src={`${gateway}/img/undraw/undraw_setup_analytics.svg`}
                            alt="Pessoa de costas configurando um gráfico em um quadro"
                            style={{
                                height: '90%',
                                maxHeight: 150
                            }}
                        />
                        <div style={{
                            ...EssentialStyle.columnCenter,
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                            width: '40%'
                        }}>
                            <span style={{
                                fontSize: 16,
                                color: Colors.dark,
                                marginBottom: 10
                            }}><strong>Valores ocultos</strong>, reveja suas seleções de dados e tente novamente.</span>
                        </div>
                    </div>
                    :
                    <>
                        <div style={{
                            marginBottom: 5,
                            backgroundColor: 'transparent',
                            height: 25,
                        }}>
                            <strong>{this.state.indicadorExpandedNome}</strong>
                        </div>

                        <div
                            id="indicadorGraphicOptions"
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: 'calc(100% - 25px)',
                            }}
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                containerProps={{ style: { width: '100%', height: '100%' } }}
                                options={this.state.indicadorGraphicOptions}
                            />
                        </div>
                    </>
                }
            </div>
        )
    }

    renderIndicadorInfos(tipo, callback) {
        const portalTarget = document.getElementById('root');
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%' }}>
                
                <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "50%" }}>
                    <span style={{ fontSize: 12, fontWeight: 300, color: Colors.dark }}>Meta e Realizado:</span>

                    <Dropdown style={{ width: '100%' }}>
                        <Dropdown.Toggle
                            style={{
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                backgroundColor: Colors.homePage.lightGrey,
                                color: Colors.dark,
                                padding: '1px 10px',
                                border: 'none',
                            }}
                            disabled={this.state.indicadorCompareType === indicadoresDistintos && !this.state.indicadorComparar}

                        >
                            <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state[tipo].metaRealizado).label}</span>
                        </Dropdown.Toggle>
                        {ReactDOM.createPortal(
                            <Dropdown.Menu>
                                {this.state.graphicOptions.map(option => (
                                    <Dropdown.Item
                                        key={`visualization-opt-${option.value}`}
                                        onClick={() => { 
                                            if (tipo == 'configureGraphic') {
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, metaRealizado: option.value } }), () => { 
                                                    if(this.state.indicadorUnidadeDistintas && this.state.indicadorCompareType == indicadoresDistintos && option.value != 'ocultar'){
                                                        this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, performance: 'ocultar' } }), () => { 
                                                            this.calculateGraphic()
                                                        })
                                                        
                                                    } else {
                                                        this.calculateGraphic()
                                                    }
                                                })
                                                

                                            } else if (tipo == 'configureGraphicComparar') {
                                                this.setState(prevState => ({ configureGraphicComparar: { ...prevState.configureGraphicComparar, metaRealizado: option.value } }), () => { 
                                                    if(this.state.indicadorUnidadeDistintas && this.state.indicadorCompareType == indicadoresDistintos && option.value != 'ocultar'){
                                                        this.setState(prevState => ({ configureGraphicComparar: { ...prevState.configureGraphicComparar, performance: 'ocultar' } }), () => { 
                                                            this.calculateGraphic()
                                                        })
                                                        
                                                    } else {
                                                        this.calculateGraphic()
                                                    }
                                                })
                                            }
                                        }}
                                        style={option.value === this.state[tipo].metaRealizado ? { backgroundColor: Colors.dark } : {}}
                                        active={option.value === this.state[tipo].metaRealizado}
                                    >
                                        <span style={{ fontSize: 12 }}>{option.label}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        , portalTarget)}
                    </Dropdown>
                </div>

                <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "50%", paddingLeft: 5 }}>
                    <span style={{ fontSize: 12, fontWeight: 300, color: Colors.dark, }}>Performance:</span>
                    <Dropdown style={{ width: '100%' }}>
                        <Dropdown.Toggle
                            style={{
                                minWidth: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                backgroundColor: Colors.homePage.lightGrey,
                                color: Colors.dark,
                                padding: '1px 10px',
                                border: 'none',
                            }}
                            disabled={this.state.indicadorCompareType == indicadoresDistintos && !this.state.indicadorComparar}
                        >
                            <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state[tipo].performance).label}</span>
                        </Dropdown.Toggle>
                        {ReactDOM.createPortal(
                            <Dropdown.Menu>
                                {this.state.graphicOptions.map(option => (
                                    <Dropdown.Item
                                        key={`visualization-opt-${option.value}`}
                                        onClick={() => { 
                                            if (tipo == 'configureGraphic') {
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, performance: option.value } }), () => { 
                                                    if(this.state.indicadorUnidadeDistintas && this.state.indicadorCompareType == indicadoresDistintos && option.value != 'ocultar'){
                                                        this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, metaRealizado: 'ocultar' } }), () => { 
                                                            this.calculateGraphic()
                                                        })
                                                        
                                                    } else {
                                                        this.calculateGraphic()
                                                    }
                                                })
                                            } else if (tipo == 'configureGraphicComparar') {
                                                this.setState(prevState => ({ configureGraphicComparar: { ...prevState.configureGraphicComparar, performance: option.value } }), () => { 
                                                    if(this.state.indicadorUnidadeDistintas && this.state.indicadorCompareType == indicadoresDistintos && option.value != 'ocultar'){
                                                        this.setState(prevState => ({ configureGraphicComparar: { ...prevState.configureGraphicComparar, metaRealizado: 'ocultar' } }), () => { 
                                                            this.calculateGraphic()
                                                        })
                                                    } else {
                                                        this.calculateGraphic()
                                                    }
                                                })
                                            }
                                        }}
                                        style={option.value === this.state[tipo].performance ? { backgroundColor: Colors.dark } : {}}
                                        active={option.value === this.state[tipo].performance}
                                    >
                                        <span style={{ fontSize: 12 }}>{option.label}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        , portalTarget)}
                    </Dropdown>
                </div>

            </div>
        )
    }

    renderPeriodoIndicadorExpanded(dataInicio, dataFim) {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                <span style={{ fontSize: 12, fontWeight: 'bold', color: Colors.dark }}>Período:</span>
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderDatePicker(dataInicio, async () => { 
                        this.laodIndicadorExpanded();
                        if((this.state.indicadorCompareType == indicadoresDistintos && this.state.indicadorComparar) || this.state.indicadorCompareType == periodosDistintos){
                            this.laodIndicadorExpandedComparar();
                        }
                    })}
                    {this.renderDatePicker(dataFim, async () => { 
                        this.laodIndicadorExpanded();
                        if((this.state.indicadorCompareType == indicadoresDistintos && this.state.indicadorComparar) || this.state.indicadorCompareType == periodosDistintos){
                            this.laodIndicadorExpandedComparar();
                        }
                    })}
                </div>
            </div>
        )
    }

    renderIndicadorExpanded() {

        let isSmallScreen = this.state.isSmallScreen;

        let styleExpanded = {
            container: {
                ...(isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexEnd),
                width: '100%',
                height: 'calc(100% - 47px)',
            },
            blocoComparativo: {
                container: {
                    ...EssentialStyle.columnStart,
                    width: isSmallScreen ? '100%' : this.state.indicadorCompararExpandedVisible ? '270px' : '0vw',
                    overflow: 'hidden',
                    transition: 'width 0.7s ease',
                    height: isSmallScreen ? '90px' : '100%',
                    borderRadius: 5, 
                },
                indicadorPrincipal: {
                    ...EssentialStyle.columnCenter, 
                    width: '100%',
                    backgroundColor: Colors.homePage.extraLightGrey, 
                    borderRadius: 5, 
                    padding: 8,
                    marginTop: 5,
                    minWidth: 270,
                },
                indicadorPrincipalNome: {
                    ...EssentialStyle.rowFlexCenter,
                    width: 'calc(100% - 16px)',
                    margin: '0px 8px 10px 8px',
                    padding: '0px 8px 8px 8px',
                    borderBottom: `0.5px solid ${Colors.homePage.grey}`,
                    display: isSmallScreen || !this.state.expanded ? 'none' : 'flex',
                },
                accordion: {
                    width: '100%', 
                    minWidth: 270,
                    display: isSmallScreen || !this.state.expanded ? 'none' : '',
                },
                expandComparativo: {
                    ...EssentialStyle.columnCenter,
                    flex: 1,
                    maxHeight: 40,
                    width: '100%',
                    backgroundColor: Colors.homePage.extraLightGrey, 
                    borderRadius: 5, 
                    padding: !this.state.expanded ? '0px 8px' : '3px 8px',
                    marginTop: 5,
                    fontSize: 12,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    minWidth: 270,
                    display: isSmallScreen || this.state.expanded ? 'none' : 'flex',
                }
            },
            blocoSlice: {
                ...EssentialStyle.columnCenter, 
                width: 20,
                height: '100%',
                display: isSmallScreen ? 'none' : 'flex',
            },
            bloboGrafico: {
                ...EssentialStyle.rowFlexCenter, 
                width: isSmallScreen ? "100%" : (this.state.indicadorCompararExpandedVisible ? 'calc(72% - 270px)' : '72%'),
                overflow: "hidden",
                transition: 'width 0.7s ease',
                height: isSmallScreen ? "calc(100% - 90px - 120px)" : "100%", 
                paddingTop: 5,
            },
            blocoIndicador: {
                container: {
                    ...EssentialStyle.columnStart, 
                    width: isSmallScreen ? '100%' : 'calc(28% - 20px)',
                    minWidth: 220,
                    height: isSmallScreen ? '120px' : ' 100%', 
                    custon: 'pointer'
                },
                blocoIndicadorData: {
                    ...EssentialStyle.rowFlexEnd, 
                    width: '100%',
                    marginTop: isSmallScreen ? 5 : 0,
                    padding: isSmallScreen ? '5px 10px 5px 10px' : '5px 10px 0px 0px',
                    backgroundColor: isSmallScreen ? Colors.homePage.extraLightGrey : 'transparent',
                    borderRadius: 5,
                },
                blocoIndicadorGauge: {
                    ...EssentialStyle.columnCenter, 
                    width: '100%', 
                    height: isSmallScreen ? '85px' : '100%', 
                    padding: 5
                }
            },
        }

        return (
            <div style={styleExpanded.container}>    
                <div style={ styleExpanded.blocoComparativo.container }>
                    <div style={ styleExpanded.blocoComparativo.indicadorPrincipal }>
                        <div style={ styleExpanded.blocoComparativo.indicadorPrincipalNome }>
                            <span style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'center', marginRight: 5 }}>
                                {this.state.indicadorExpandedNome}
                            </span>
                        </div>
                        { this.renderPeriodoIndicadorExpanded('performanceDataInicio', 'performanceDataFim') }
                        { this.renderIndicadorInfos( 'configureGraphic' ) }
                    </div>

                    <Accordion style={ styleExpanded.blocoComparativo.accordion }>
                        <div style={{ 
                                width: '100%',
                                marginTop: 5,
                                borderRadius: 5,
                                backgroundColor: Colors.homePage.extraLightGrey,
                            }}
                        >
                            <div 
                                style={{ 
                                    ...EssentialStyle.rowFlexCenter,
                                    width: 'calc(100% - 16px)',
                                    margin: '0px 8px',
                                    padding: 8,
                                    borderBottom: this.state.indicadorCompareType == indicadoresDistintos ? `0.5px solid ${Colors.homePage.grey}` : 'none',
                                    cursor: 'pointer' 
                                }}
                                onClick={() => this.selectCompareIndicador(indicadoresDistintos) } 
                            >
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: 14, 
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    textAlign: 'center',
                                    marginRight: 5,
                                }}>
                                    Comparar com outro Indicador
                                </span>
                                <FontAwesomeIcon icon={this.state.indicadorCompareType == indicadoresDistintos ? faCaretUp : faCaretDown } style={{ marginRight: 5 }} />
                            </div>

                            <Collapse in={this.state.indicadorCompareType == indicadoresDistintos}>
                                <div>
                                    <div style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', width: '100%', height: 43, padding: '15px 8px 0px 8px' }}>
                                        <span style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', fontSize: 12, width: 75, height: 38, fontWeight: 'bold', color: Colors.dark }}>Painel: </span>
                                        <div style={{ width: 'calc(100% - 75px)', height: 38 }}>
                                            <Select
                                                styles={{ 
                                                    control: (base) => ({ 
                                                        ...base,
                                                        fontSize: '12px',
                                                    })
                                                }}
                                                value={this.state.selectedPaineisComparar}
                                                options={DataHelper.formatSelectData(this.state.paineisList, 'id', 'nome')}
                                                placeholder={'Painel ...'}
                                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                                isSearchable
                                                menuPortalTarget={document.body}
                                                onChange={(value) => {
                                                    this.setState({ selectedPaineisComparar: value, indicadorComparar: null }, () => {this.getIndicadoresPainel()});
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', width: '100%', height: 43, padding: '10px 8px 0px 8px' }}>
                                        <span style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', fontSize: 12, width: 75, height: 38, fontWeight: 'bold', color: Colors.dark }}>Indicador: </span>
                                        <div style={{ width: 'calc(100% - 75px)', height: 38 }}>
                                            <Select
                                                styles={{ 
                                                    control: (base) => ({ 
                                                        ...base,
                                                        fontSize: '12px',
                                                    })
                                                }}
                                                value={this.state.indicadorComparar}
                                                options={DataHelper.formatSelectData(this.state.indicadoresPainelComparar, 'id', 'nome')}
                                                placeholder={'Indicador ...'}
                                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                                isSearchable
                                                menuPortalTarget={document.body}
                                                onChange={(value) => {
                                                    this.setState({ indicadorComparar: value }, () => { this.verificaUnidade() });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', width: '100%', padding: '10px 8px 0px 8px' }}>
                                        { this.renderIndicadorInfos( 'configureGraphicComparar' ) }
                                    </div>

                                    <div style={{ ...EssentialStyle.rowFlexEnd, alignItems: 'center', width: '100%', padding: '10px 8px 10px 8px' }}>
                                        
                                        {
                                            this.state.indicadorUnidadeDistintas ? 
                                                <CustomTooltip
                                                    tooltip={"Unidades dos indicadores selecionados são distintas. Assim, algumas funcionalidades vão estar limitadas!"}
                                                    placement={'top'}
                                                    style={{ width: '100%', cursor: 'default'}}
                                                >
                                                    <div style={{ ...EssentialStyle.rowFlexCenter, borderRadius: 5, height: 25, fontSize: 12, backgroundColor: Colors.error, color: 'white', padding: '0px 5px', width: '100%'  }}>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 5 }}/>
                                                        Unidades Distintas
                                                    </div> 
                                                </CustomTooltip>
                                            : 
                                            null
                                        }
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        <div style={{ 
                                width: '100%',
                                marginTop: 5,
                                borderRadius: 5,
                                backgroundColor: Colors.homePage.extraLightGrey,
                            }}
                        >
                            <div 
                                style={{ 
                                    ...EssentialStyle.rowFlexCenter,
                                    width: 'calc(100% - 16px)',
                                    margin: '0px 8px',
                                    padding: 8,
                                    borderBottom: this.state.indicadorCompareType == periodosDistintos ? `0.5px solid ${Colors.homePage.grey}` : 'none',
                                    cursor: 'pointer' 
                                }}
                                onClick={() => this.selectCompareIndicador(periodosDistintos) } 
                            >
                                <span style={{ 
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    textAlign: 'center',
                                    marginRight: 5,
                                }}> 
                                    Comparar com outro Período 
                                </span>
                                <FontAwesomeIcon icon={this.state.indicadorCompareType == periodosDistintos ? faCaretUp : faCaretDown } style={{ marginRight: 5 }} />
                            </div>

                            <Collapse in={this.state.indicadorCompareType == periodosDistintos}>
                                <div>
                                    
                                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', padding: '5px 8px 0px 8px' }}>
                                        <span style={{ fontSize: 14, fontWeight: 'bold', color: Colors.dark, textAlign: 'center' }}>{this.state.indicadorExpandedNome}</span>
                                    </div>

                                    <div style={{ ...EssentialStyle.rowFlexStart, alignItems: 'center', width: '100%', padding: '10px 8px 10px 8px' }}>
                                        {this.renderPeriodoIndicadorExpanded('performanceDataInicioComparar', 'performanceDataFimComparar')}
                                    </div>

                                </div>
                            </Collapse>
                        </div>
                    </Accordion>

                    <div style={ styleExpanded.blocoComparativo.expandComparativo } onClick={() => this.selectCompareIndicador(null) }>
                        + Fazer comparações
                    </div>
                </div>

                <div style={styleExpanded.blocoSlice}>
                    <FontAwesomeIcon style={{ transition: 'transform 0.7s', color: Colors.dark, cursor: 'pointer' }}
                        onClick={() => { 
                            this.setState({ indicadorCompararExpandedVisible: !this.state.indicadorCompararExpandedVisible }, () => { 
                                setTimeout(() => {
                                    this.updateIndicadorGraphicOptionsWidth();
                                }, 1000);
                            }) 
                        }}
                        icon={!this.state.indicadorCompararExpandedVisible ? faChevronRight : faChevronLeft}
                    /> 
                </div>

                <div style={styleExpanded.bloboGrafico} >
                    {this.state.loadingIndicadorExpanded ? <DefaultLoader /> : this.renderGraficoExpanded()}
                </div>

                <div style={styleExpanded.blocoIndicador.container} >
                    <div style={ styleExpanded.blocoIndicador.blocoIndicadorData }>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: 220, height: '25px' }}>
                            <DefaultButton
                                style={{ width: 100, height: 25, padding: 0, marginLeft: 10, fontSize: 12 }}
                                textColor={this.state.acumulado ? Colors.white : Colors.dark}
                                color={this.state.acumulado ? Colors.success : Colors.homePage.lightGrey}
                                title={<b>{!this.state.acumulado ? 'Mensal' : 'Acumulado'}</b>}
                                tooltip={!this.state.acumulado ? 'Alternar para Acumulado' : 'Alternar para Mensal'}
                                tooltipPlacement={'top'}
                                onClick={() => { this.setState({ acumulado: !this.state.acumulado }) }}
                            />
                            {this.renderDatePicker('indicadoresData')}
                        </div>
                    </div>

                    <div style={ styleExpanded.blocoIndicador.blocoIndicadorGauge }>
                        <IndicadorGauge
                            id_indicador={this.state.indicadorExpanded}
                            id_gmr_painel={this.state.selectedPainel.value}
                            data={this.state.indicadoresData}
                            acumulado={this.state.acumulado}
                            desvio={true}
                            saldo={true}
                            gauge={this.state.expanded || isSmallScreen}
                            mergulho={true}
                            onClick={() => { this.selectIndicadorExpanded({ 'id': this.state.indicadorExpanded, nome: this.state.indicadorExpandedNome }, this.state.selectedPainel.value) }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderEmptyGmr() {

        if (!this.state.possuiModuloGMR){
            return (
                <div style={{
                    ...EssentialStyle.rowFlexCenter,
                    width: '100%',
                    height: 'calc(100% - 43px)'
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_secure_login.svg`}
                        alt="Computador com um cadeado de acesso negado"
                        style={{
                            height: '90%',
                            maxHeight: 180
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '40%',
                        marginLeft: 10,
                    }}>
                        <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                            Sua empresa ainda não utiliza o módulo de <strong>Gestão de Metas e Resultados</strong>. Solicite para o seu Consultor!
                        </span>

                        <div style={{
                            ...EssentialStyle.rowFlexEnd,
                            width: '100%',
                            marginTop: 20
                        }}>
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                                title={'Ir ao Suporte'}
                                onClick={() => { window.parent.postMessage({ type: '_blank', url: `/ajuda/geral/suporte#sidebar-suporte` }, '*') }}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{
                    ...EssentialStyle.rowFlexCenter,
                    width: '100%',
                    height: 'calc(100% - 43px)'
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_void.svg`}
                        alt="Pessoa de costas olhando para o vazio"
                        style={{
                            height: '90%',
                            maxHeight: 180
                        }}
                    />
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '40%',
                        marginLeft: 10,
                    }}>
                        <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                            Sua empresa ainda não criou nenhum processo de <strong>Gestão de Metas e Resultados</strong>!
                        </span>

                        <div style={{
                            ...EssentialStyle.rowFlexEnd,
                            width: '100%',
                            marginTop: 20
                        }}>
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                                title={'Iniciar Gestão de Metas e Resultados'}
                                onClick={() => { window.parent.postMessage({ type: '_blank', url: `/gmr` }, '*') }}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderChartBody() {
        if ((!this.state.possuiGMR || !this.state.possuiModuloGMR) && !this.state.selectedPainel) return this.renderEmptyGmr();
        if (this.state.configuring) return this.renderConfiguring();
        if (!this.state.selectedPainel) return this.renderNoSelection();
        if (this.state.indicadorExpanded) return this.renderIndicadorExpanded();

        return (
            <div style={ this.state.isSmallScreen ? layout.bodyColumn : layout.bodyRow }>
                <div style={{
                    ...EssentialStyle.columnStart,
                    width: this.state.isSmallScreen ? '100%' : this.state.indicadoresExpanded ? '0%' : '35%',
                    overflow: this.state.indicadoresExpanded ? 'hidden' : 'visible',
                    height: this.state.isSmallScreen ? '50%' :'100%',
                    transition: 'width 0.7s ease',
                }}>
                    {this.renderTendency()}
                </div>

                {
                    !this.state.isSmallScreen && 
                    <div style={{ 
                        ...EssentialStyle.columnCenter, 
                        width: 20, 
                        height: '100%' 
                    }}>
                        <FontAwesomeIcon
                            style={{
                                transition: 'transform 0.7s',
                                color: Colors.dark,
                                cursor: 'pointer'
                            }}
                            onClick={() => { this.setState({ indicadoresExpanded: !this.state.indicadoresExpanded }) }}
                            icon={!this.state.indicadoresExpanded ? faChevronLeft : faChevronRight}
                        /> 
                    </div>
                }
                
                <div style={{
                    ...EssentialStyle.columnStart,
                    width: this.state.isSmallScreen ? '100%' : this.state.indicadoresExpanded ? 'calc(100% - 20px)' : 'calc(65% - 20px)',
                    height: this.state.isSmallScreen ? '50%' :'100%',
                    transition: 'width 0.7s ease',
                }}>
                    {this.renderIndicadores()}
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    renderChart() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                {this.renderChartHeader()}
                {this.state.loading ? this.renderLoading() : this.renderChartBody()}
            </div>
        );
    }

    render() {
        return this.renderChart();
    }
}

const layout = {

    bodyRow: {
        ...EssentialStyle.rowSpaceBetween,
        width: '100%',
        height: `calc(100% - 47px)`,
        paddingTop: 4
    },
    bodyColumn: {
        ...EssentialStyle.columnStart,
        width: '100%',
        height: `calc(100% - 47px)`,
    } 
}