import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Colors from "../../../constants/Colors";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultButton from "../../tools/DefaultButton";
import SessionHelper from "../../../helper/SessionHelper";
import LayoutHelper from "../../../helper/LayoutHelper";
import Sig from "../../../api/Sig";
import ChartHelper from "../../../helper/inicio/ChartHelper";
import Indicadores from "./charts/indicadores/Indicadores";
import Atividades from "./charts/Atividades";
import MinhaEquipe from "./charts/MinhaEquipe";
import PlanosAcaoMinhaEquipe from "./charts/PlanosAcaoMinhaEquipe";
import GraficosMinhaEquipe from "./charts/GraficosMinhaEquipe";
import MeusPlanosDeAcao from "./charts/meusPlanosAcao/MeusPlanosDeAcao";
import DefaultLoader from "../../tools/DefaultLoader";
import Analytics from "./charts/analytics/Analytics";
import Parceiros from "./charts/parceiros/Parceiros";
import EmptyDashboard from "./EmptyDashboard";

export default class UserDashboard extends React.Component {
    state = {
        me: false,
        page: 0,
        nOfPages: 2,
        loading: true,
        expandedPageZero: false,
        expandedPageOne: "MeusPlanosAcao",
        expandedPageTwo: false,
        expandedPageTwoMinhaEquipe: true,
        expandedPageThre: false,
        opacity: 0,
        transform: 'translateX(+100%)',
        config: {},
        selectedUsersEquipe: [],
        notAtualizeGraphic: 0,
        numberSubordinados: this.props.numberSubordinados || 5,
        viewUserConfig: true,
        reloadEquipe: 0,
        isAdvis: false,
        isAdmin: false,
        pages: [],
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifySmallScreen);
        this.triggerAnimations();
        this.loadData();

    }

    async componentDidUpdate(prevProps) {
        if (this.props.userId && (this.props.userId !== prevProps.userId || this.props.viewUserConfig !== prevProps.viewUserConfig)) {
            this.setState({ me: (this.props.userId === SessionHelper.getData().id_usuario), numberSubordinados: this.props.numberSubordinados, viewUserConfig: this.props.viewUserConfig }, () => {
                this.loadData();
            });
        } else if ((this.props.reloadEquipe !== prevProps.reloadEquipe)) {
            this.reloadEquipe();
        }        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifySmallScreen);
    }

    verifySmallScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData() {
        this.setState({loading: true});

        let selectedUsersEquipe = [];
        let notAtualizeGraphic = this.state.notAtualizeGraphic;
        
        let { config } = await Sig.request('GET', 'inicio/getInicioConfigColaboradoresEquipe', { colaboradorAlvo: this.props.userId, viewUserConfig: this.props.viewUserConfig });
        selectedUsersEquipe = config.map(item => item.id_colaborador);
        notAtualizeGraphic = notAtualizeGraphic + 1

        let { isAdmin, isAdvis, showParceiros } = await Sig.request('GET', 'inicio/getInicioPermission');
        let nOfPages = 0;

        let pagesAvaliable = {
            'resumo': this.renderResumo,
            'meus_planos': this.renderMeusPlanos,
            'minha_equipe': this.renderMinhaEquipe,
            'parceiros': this.renderPlanosParceiros,
            'analytics': this.renderAnalytics
        }

        let pages = [
            pagesAvaliable['resumo'],
            pagesAvaliable['meus_planos'],
            pagesAvaliable['minha_equipe']
        ];

        if(showParceiros == true) pages.push(pagesAvaliable['parceiros']);
        if(isAdmin == true) pages.push(pagesAvaliable['analytics']);

        nOfPages = pages.length - 1;

        this.setState({ pages, nOfPages, selectedUsersEquipe, isAdmin, isAdvis }, () => {
            this.setState({loading: false});
        });
    }

    reloadEquipe() {
        this.setState({ reloadEquipe: this.state.reloadEquipe + 1 });
    }

    fadeAnimations(swipeRight = true) {
        this.setState({ opacity: 0, transform: `translateX(${swipeRight ? `-100%` : `+100%`})` });
    }

    triggerAnimations() {
        this.setState({ opacity: 1, transform: 'translateX(0)' });
    }

    selectedUsersEquipe(users) {
        this.setState({ selectedUsersEquipe: users });
    }

    renderAtividades() {
        return (
            <div style={{
                ...EssentialStyle.card,
                width: 'calc(50% - 4px)',
                height: '95%'
            }}>
            </div>
        );
    }

    renderComparativo() {
        return (
            <div style={{
                ...EssentialStyle.card,
                width: 'calc(50% - 4px)',
                height: '95%'
            }}>
            </div>
        );
    }

    getPageOneStyles(){
        if(this.state.isSmallScreen) {
            return {
                ...EssentialStyle.columnSpaceBetween,
                position: "relative",
                width: '100%',
                height: "calc(100% - 30px)",
                overflow: 'auto',
                gap: "8px"
            }
        }
        else {
            return {
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
                height: 'calc(100% - 30px)',
                overflow: 'hidden'
            }
        }
    }

    renderResumo = () => {

        let stylePage = {
            page: {
                ...this.getPageAnimations(),
                ...EssentialStyle.columnSpaceBetween,
                width: '100%',
                height: this.state.isSmallScreen ? '' : 'calc(100% - 28px)',
                minHeight: 420,
            },
            rowIndicador: {
                transition: 'width 0.5s ease, height 0.5s ease-in-out',
                marginBottom: 10,
                ...EssentialStyle.rowFlexStart,
                ...EssentialStyle.card,
                width: '100%',
                height: this.state.isSmallScreen ? 
                    (this.state.expandedPageZero === 'indicador' ? (ChartHelper.getHeight(100) - ChartHelper.getHeaderHeight()) : (this.state.expandedPageZero === false ? (ChartHelper.getHeight(100) - ChartHelper.getHeaderHeight() - 60 ) : 0)) 
                    : 
                    (this.state.expandedPageZero === 'indicador' ? ('100%') : (this.state.expandedPageZero === false ? 'calc(50% - 10px)' : '0%')),
                minHeight: this.state.expandedPageZero === 'indicador' ? '100%' : (this.state.expandedPageZero === false) ? 'calc(50% - 5px)' : '0%',
                padding: (this.state.expandedPageZero === 'indicador' || this.state.expandedPageZero === false) ? 4 : 0
            },
            rowAtividades: {
                transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out',
                ...EssentialStyle.columnStart,
                ...EssentialStyle.card,
                height: this.state.isSmallScreen ? 
                    (this.state.expandedPageZero === 'atividade' ? "" : (this.state.expandedPageZero === false ? ChartHelper.getHeight(70) : '0%')) 
                    : 
                    (this.state.expandedPageZero === 'atividade' ? ('100%') : (this.state.expandedPageZero === false ? 'calc(50% - 10px)' : '0%')),
                width: '100%',
                marginBottom: this.state.isSmallScreen ? 10 : 0,
                padding: (this.state.expandedPageZero === 'atividade' || this.state.expandedPageZero === false) ? 4 : 0,
            }

        }

        return (
            <div style={stylePage.page}>

                <div style={stylePage.rowIndicador}>
                    {(this.state.expandedPageZero === 'indicador' || this.state.expandedPageZero === false) && 
                        <Indicadores viewUserConfig={this.state.viewUserConfig} userId={this.props.userId} expandCallbackPageZero={this.expandCallbackPageZero} />
                    }
                </div>

                <div style={stylePage.rowAtividades}>
                    {(this.state.expandedPageZero === 'atividade' || this.state.expandedPageZero === false) && 
                        <Atividades viewUserConfig={this.state.viewUserConfig} userId={this.props.userId}  expandCallbackPageZero={this.expandCallbackPageZero} />
                    }
                </div>

            </div>
        );
    }

    renderMeusPlanos = () => {
        return(
            <div style={{
                ...this.getPageAnimations(),
                ...this.getPageOneStyles(),
            }}>
                <div style={{
                    transition: 'width 0.5s ease',
                    ...EssentialStyle.rowFlexStart,
                    ...EssentialStyle.card,
                    width: this.state.expandedPageOne === 'MeusPlanosAcao' || this.state.isSmallScreen ? 'calc(100% - 4px)' : (this.state.expandedPageOne === false) ? 'calc(50% - 4px)' : '0%',
                    minHeight: `${this.state.isSmallScreen ? "100%": 420}`,
                    height: '100%',
                    position: "relative",
                    overflowX: "auto",
                    overflowY: `${this.state.isSmallScreen ? "hidden":"auto"}`,
                }}>
                    <MeusPlanosDeAcao 
                        userId={this.props.userId}
                        expandCallbackPageOne={this.expandCallbackPageOne}
                        expandedPageOne={this.state.expandedPageOne}
                    />
                </div>
                
                {/* Descomentar para adicionar o segundo bloco de conteúdo na página 1
                
                <div style={{
                    transition: 'width 0.5s ease',
                    ...EssentialStyle.rowFlexStart,
                    ...EssentialStyle.card,
                    width: this.state.expandedPageOne === 'ProjetosPlanejamentoEstrategico' || this.state.isSmallScreen ? 'calc(100% - 4px)' : (this.state.expandedPageOne === false) ? 'calc(50% - 4px)' : '0%',
                    height: '100%',
                    minHeight: `${this.state.isSmallScreen ? "600px":"none"}`,
                }}>
                </div> */}
            </div>
        );
    }

    renderMinhaEquipe = () => {
        return (
            <div style={{
                ...this.getPageAnimations(),
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween),
                width: '100%',
                height: 'calc(100% - 28px)',
                minHeight: 420,
            }}>
                <div style={{
                    ...EssentialStyle.rowFlexStart,
                    width: this.state.isSmallScreen ? '100%' : (this.state.expandedPageTwoMinhaEquipe ? '30%' : '90px'),
                    minWidth: this.state.expandedPageTwoMinhaEquipe ? 215 : 90,
                    height: this.state.isSmallScreen ? ChartHelper.getHeight(40) : '100%',
                    marginBottom: this.state.isSmallScreen ? 8 : 0,
                    transition: 'width 0.5s ease',
                    overflow: 'hidden'
                }}>
                    <MinhaEquipe 
                        userId={this.props.userId}
                        viewUserConfig={this.state.viewUserConfig}
                        numberSubordinados={this.state.numberSubordinados} 
                        sectedUserEquipe={ (users) => {this.selectedUsersEquipe(users)} }
                        expandedPageTwoMinhaEquipe={this.state.expandedPageTwoMinhaEquipe}
                        expandCallbackPageTwoMinhaEquipe={this.expandCallbackPageTwoMinhaEquipe}
                        reloadEquipe={this.state.reloadEquipe}
                    />
                </div>

                <div style={{
                    ...EssentialStyle.columnSpaceBetween,
                    flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'column' ,
                    width: this.state.isSmallScreen ? '100%' : (this.state.expandedPageTwoMinhaEquipe ? '70%' : 'calc(100% - 90px)'),
                    height: this.state.isSmallScreen ? '' : '100%',
                    transition: 'width 0.5s ease',
                }}>
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        ...EssentialStyle.card,
                        width: '100%',
                        height: this.state.expandedPageTwo === 'PlanoAcaoMinhaEquipe' ? 'calc(100% - 58px)' : (this.state.expandedPageTwo === false) ? 'calc(50% - 4px)' : '50px',
                        transition: 'height 0.5s ease',
                        marginBottom: this.state.isSmallScreen ? 60 : 0,
                    }}>
                        <PlanosAcaoMinhaEquipe 
                            userId={this.props.userId}
                            viewUserConfig={this.state.viewUserConfig}
                            numberSubordinados={this.state.numberSubordinados}
                            expandCallbackPageTwo={this.expandCallbackPageTwo}
                            expandedPageTwo={this.state.expandedPageTwo}
                            reloadEquipe={this.state.reloadEquipe}
                        />
                    </div>

                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        ...EssentialStyle.card,
                        width: '100%',
                        height: this.state.expandedPageTwo === 'GraficosMinhaEquipe' ? 'calc(100% - 58px)' : (this.state.expandedPageTwo === false) ? 'calc(50% - 4px)' : '50px',
                        transition: 'height 0.5s ease',
                        marginBottom: this.state.isSmallScreen ? 8 : 0,
                    }}>
                        <GraficosMinhaEquipe
                            userId={this.props.userId}
                            viewUserConfig={this.state.viewUserConfig}
                            numberSubordinados={this.state.numberSubordinados}
                            expandCallbackPageTwo={this.expandCallbackPageTwo}
                            expandedPageTwo={this.state.expandedPageTwo}
                            selectedUsersEquipe={this.state.selectedUsersEquipe}
                            notAtualizeGraphic={this.state.notAtualizeGraphic}
                            reloadEquipe={this.state.reloadEquipe}
                            atualizarTamanho={this.state.expandedPageTwoMinhaEquipe}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderAnalytics = () => {
        return(
            <div className={"userDashboard"} style={{
                ...this.getPageAnimations(),
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween),
                width: '100%',
                height: this.state.isSmallScreen ? `` : 'calc(100% - 28px)',
                minHeight: this.state.isSmallScreen ? `calc(100vh - ${ChartHelper.getHeaderHeight()}px - 60px)` : 0,
            }}>
                <Analytics
                    userId={this.props.userId}
                    isAdvis={this.state.isAdvis}
                />
            </div>
        );
    }

    renderPlanosParceiros = () => {
        return(
            <div className={"userDashboard"} style={{
                ...this.getPageAnimations(),
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween),
                width: '100%',
                height: this.state.isSmallScreen ? `` : 'calc(100% - 28px)',
                minHeight: this.state.isSmallScreen ? `calc(100vh - ${ChartHelper.getHeaderHeight()}px - 60px)` : 0,
            }}>
                <Parceiros
                    userId={this.props.userId}
                    isAdvis={this.state.isAdvis}
                />
            </div>
        );
    }

    getPageAnimations() {
        return {
            opacity: this.state.opacity,
            transform: this.state.transform,
            transition: 'opacity .3s ease-in-out, transform .6s ease-in-out'
        }
    }

    expandCallbackPageZero = (expandedPageZero) => {
        this.setState({ expandedPageZero });
    }

    expandCallbackPageOne = (expandedPageOne) => {
        this.setState({ expandedPageOne });
    }

    expandCallbackPageTwo = (expandedPageTwo) => {
        this.setState({ expandedPageTwo });
    }

    expandCallbackPageTwoMinhaEquipe = (expandedPageTwoMinhaEquipe) => {
        this.setState({ expandedPageTwoMinhaEquipe });
    }

    expandCallbackPageThre = (expandedPageThre) => {
        this.setState({ expandedPageThre });
    }

    renderExpanded() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    ...EssentialStyle.rowFlexStart
                }}>
                    <div style={{
                        ...EssentialStyle.columnStart,
                        width: '100%',
                        height: '100%'
                    }}>
                    </div>
                </div>

            </div>
        );
    }

    renderPage() {
        if (this.state.loading) {
            return (
                <div
                    style={{
                        height: this.state.isSmallScreen ? `` : `calc(50vh - ${ChartHelper.getHeaderHeight()}px + 80px)`,
                        paddingTop: this.state.isSmallScreen ? `calc(50vh - ${ChartHelper.getHeaderHeight()}px - 52px)` : ``
                    }}>
                    <DefaultLoader />
                </div>
            );
        }
        
        const currentPageFunction = this.state.pages[this.state.page];

        if (currentPageFunction) {
            return currentPageFunction();
        } else {
            return <EmptyDashboard />;
        }
    }

    changePage(increase = true, pageNumber = null) {
        let page = this.state.page;
        if (increase) {
            page = page + 1;
        } else {
            page = page - 1;
        }

        if(pageNumber !== null) {
            page = pageNumber;
            increase = pageNumber > this.state.page;
        } 

        if(page === this.state.page) return;

        if (page < 0) {
            page = this.state.nOfPages;
        } else if (page > this.state.nOfPages) {
            page = 0;
        }

        this.fadeAnimations(increase);

        setTimeout(() => {
            this.setState({ page }, () => {
                this.triggerAnimations();
            });
        }, 400);
    }

    renderControls() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
                height: 18,
                marginBottom: 10
            }}>
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} style={{ color: SessionHelper.getColor() }} />}
                    color={'transparent'}
                    onClick={() => { this.changePage(false) }}
                />
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    width: (this.state.nOfPages + 1) * 16
                }}>
                    {
                        Array.from({ length: this.state.nOfPages + 1 }, (_, i) => (
                            <div
                                style={{
                                    width: i === this.state.page ? 10 : 8,
                                    height: i === this.state.page ? 10 : 8,
                                    borderRadius: `50%`,
                                    backgroundColor: i === this.state.page ? SessionHelper.getColor() : Colors.notSelected,
                                    cursor:  i === this.state.page ? 'not-allowed' : 'pointer',
                                    margin: '0 2px'
                                }}
                                onClick={() => { this.changePage(false, i) }}
                                key={`dot-page-${i}`}
                            />
                        ))
                    }
                </div>

                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronRight} style={{ color: SessionHelper.getColor() }} />}
                    color={'transparent'}
                    onClick={() => { this.changePage(true) }}
                />
            </div>
        );
    }

    renderPagination() {
        return (
            <div style={{
                ...EssentialStyle.columnSpaceBetween,
                width: '100%',
                paddingTop: 10,
                height: this.state.isSmallScreen ? '': `calc(100vh - ${ChartHelper.getHeaderHeight()}px)`,
                overflowX: 'hidden',
            }}>
                {this.renderControls()}
                {this.renderPage()}
            </div>
        );
    }

    render() {
        return this.renderPagination();
    }
}