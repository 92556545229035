import React from "react";

import TooManyAvatars from "../../../../tools/TooManyAvatars";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DataHelper from "../../../../../helper/DataHelper";
import LoadingPage from "../../../../../pages/LoadingPage";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import Select from 'react-select';
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faArrowRight, faPrint, faPaperPlane, faPlusSquare, faTimes, faBars, faColumns } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

import './Relatorio.css';
import DefaultLoader from "../../../../tools/DefaultLoader";
import RelatorioGrafico from "../RelatorioGrafico/RelatorioGrafico";
import moment from "moment";

import 'moment/locale/es';
import 'moment/locale/pt';
import UnderConstruction from "../../../../tools/UnderConstruction";
import EssentialStyle from "../../../../../style/EssentialStyle";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

export default class Relatorio extends React.Component {

    state = {
        construction: false,
        loading: true,

        idRelatorio: this.props.id,
        relatorio: {},
        lang: this.props.lang || IdiomaHelper.getIdioma(),

        loadAddRecomendacaoSelect: false,
        loadAddRecomendacao: true,
        loadRecomendacao: false,
        addingRecomendation: false,

        colunas: [],
        colunaSelected: null,
        nodes: [],
        nodeSelected: null,
        description: '',

        ColumnRecomendacoes: 2,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        await this.loadData();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    async loadData() {
        this.setState({ loading: true });

        await this.loadRelatorio();

        this.setState({ loading: false }, () => {
            this.loadColunas()
        });
    }

    async loadRelatorio() {

        let relatorio = await DiagnosticoHelper.getRelatorio(this.state.idRelatorio, 1, this.state.lang);
        this.setState({ relatorio });
    }

    async loadColunas() {
        this.setState({ loadAddRecomendacao: true });
        let colunas = await DiagnosticoHelper.getColunasQuestionario(this.state.relatorio.diagnosticoPeriodo.id, this.state.lang);
        this.setState({ colunas, loadAddRecomendacao: false });
    }

    async loadLang() {
        this.setState({ colunas: [], colunaSelected: null, nodes: [], nodeSelected: null });
        await this.loadData();
    }

    async setNodeColuna(idColuna) {
        this.setState({ loadAddRecomendacaoSelect: true });
        let nodesColuna = idColuna ? await DiagnosticoHelper.getItensColuna(idColuna, this.state.lang) : [];
        let nodeSelected = [];

        this.setState({ colunaSelected: idColuna, nodes: nodesColuna, nodeSelected, loadAddRecomendacaoSelect: false });
    }

    async saveRecomendacao() {
        let message = IdiomaHelper.getStruct({
            'pt': {
                error: 'Preencha todos os campos'
            },
            'en': {
                error: 'Fill in all fields'
            },
            'es': {
                error: 'Rellene todos los campos'
            }
        });

        if (!this.state.idRelatorio || !this.state.colunaSelected || !this.state.nodeSelected || !this.state.description || this.state.description == '' || DataHelper.removeHtmlTags(this.state.description) == '') {

            toast.error(message[this.props.lang].error);
            return;
        }

        let recomendacao = {
            idRelatorio: this.state.idRelatorio,
            idColuna: this.state.colunaSelected,
            idNode: this.state.nodeSelected,
            descricao: this.state.description
        }

        this.setState({ loadAddRecomendacao: true });

        if (!await DiagnosticoHelper.saveRecomendacao(recomendacao)) {
            toast.error('Erro ao salvar recomendação');
            this.setState({ loadAddRecomendacao: false, addingRecomendation: false });
            return;
        }

        this.setState({ loadAddRecomendacao: false, addingRecomendation: false, colunaSelected: null, nodeSelected: null, description: '' }, () => {
            this.loadRelatorio();
        });
    }

    async deleteRecomendacao(idRecomendacao) {
        this.setState({ loadAddRecomendacao: true });

        if (!DiagnosticoHelper.deleteRecomendacao(idRecomendacao)) {
            toast.error('Erro ao excluir recomendação');
            this.setState({ loadAddRecomendacao: false });
            return;
        }

        this.setState({ loadAddRecomendacao: false }, () => {
            this.loadRelatorio();
        });
    }

    async publishRelatorio() {

        if (!await DiagnosticoHelper.publishRelatorio(this.state.idRelatorio)) {
            toast.error('Erro ao publicar relatório');
            return;
        }

        this.loadData();
    }

    handleView() {

        let ColumnRecomendacoes = this.state.ColumnRecomendacoes;
        ColumnRecomendacoes = ColumnRecomendacoes === 1 ? 2 : 1;

        this.setState({ ColumnRecomendacoes });
    }

    renderSwitch() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faBars} />
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.ColumnRecomendacoes === 1 ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <FontAwesomeIcon icon={faColumns} />
            </div>
        )
    }

    renderPage() {
        return (
            <div className="relatorio">
                {this.renderHeader()}
                {this.renderGraficos()}
                {this.renderRecomendacoes()}
            </div>
        );
    }

    getGraficoWidthClass(grafico) {
        let references = {
            1: 4,
            2: 6,
            3: 12
        };

        return this.state.isSmallScreen ? 'w-100' : references[grafico.largura] || 'w-100';
    }

    renderGraficos() {
        return (
            <div className="relatorio-grafico-grid">
                <Form style={{ width: '100%', ...EssentialStyle.columnCenter }}>
                    <Row className="g-2" style={{ width: '100%' }}>
                        {this.state.relatorio?.graficos?.map((grafico) => (
                            <Col key={grafico.id} md={this.getGraficoWidthClass(grafico)}>
                                <Form.Group>
                                    <RelatorioGrafico key={`relatorio-grafico-${grafico.id}`} grafico={grafico} lang={this.state.lang} />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </div>
        );
    }

    getLocaleDate(date) {
        return moment(date).locale(this.state.lang).format('L LT');
    }

    renderHeader() {
        let message = IdiomaHelper.getStruct({
            'pt': {
                back: 'Voltar', print: 'Imprimir', process: 'Processo', period: 'Período', evaluators: 'Avaliadores', status: 'Status', companyEvaluator: 'Empresa Avaliadora', companyEvaluated: 'Empresa Avaliada'
            },
            'en': {
                back: 'Back', print: 'Print', process: 'Process', period: 'Period', evaluators: 'Evaluators', status: 'Status', companyEvaluator: 'Company Evaluator', companyEvaluated: 'Company Evaluated'
            },
            'es': {
                back: 'Volver', print: 'Imprimir', process: 'Proceso', period: 'Período', evaluators: 'Evaluadores', status: 'Estado', companyEvaluator: 'Empresa Evaluadora', companyEvaluated: 'Empresa Evaluada'
            }
        });

        return (
            <div className="header">
                <div className="line">
                    <DefaultButton
                        tooltip={message[this.state.lang].back}
                        tooltipPlacement={'bottom'}
                        leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => { this.props.closeRelatorio() }}
                    />

                    <div style={{ flex: 1, textAlign: 'center', padding: '0px 20px 0px 20px' }}>
                        <div className="nomeRelatorio">{this.state.relatorio.nome}</div>
                    </div>

                    <DefaultButton
                        tooltip={message[this.state.lang].print}
                        tooltipPlacement={'bottom'}
                        leftIcon={<FontAwesomeIcon icon={faPrint} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => { toast.info('Função em desenvolvimento - Function under development - Función en desarrollo') }}
                    />
                </div>

                <div className="line">
                    <div className="subTitle">
                        <div>{message[this.state.lang].process}: {this.state.relatorio.diagnosticoProcesso.nome} | {message[this.state.lang].period}: {this.state.relatorio.diagnosticoPeriodo.nome}</div>
                    </div>
                </div>

                <div className="line">
                    <div className="infos">
                        <div className="info" style={{ marginRight: 10 }}><span style={{ marginRight: 10 }}>{message[this.state.lang].evaluators}:</span> <TooManyAvatars participantsTotal={this.state.relatorio.diagnosticoAvaliacaoAvaliadores.length} participantsVisiveis={10} arrayParticipants={this.state.relatorio.diagnosticoAvaliacaoAvaliadores} /></div>
                        <div className="info" style={{ marginRight: 10 }}><span style={{ marginRight: 10 }}>{message[this.state.lang].status}: </span> {this.state.relatorio.status == 0 ? message[this.state.lang].desenvolvimento : message[this.state.lang].publicado} | {this.state.relatorio.status == 0 ? this.getLocaleDate(this.state.relatorio.data_cadastro) : this.getLocaleDate(this.state.relatorio.data_cadastro)}</div>
                    </div>
                </div>

                <div className="line">
                    <div className="infos" style={{ justifyContent: 'start', gap: 10 }}>
                        <div className="info">{message[this.state.lang].companyEvaluator}: {this.state.relatorio.avaliador.nome_fantasia}</div>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <div className="info">{message[this.state.lang].companyEvaluated}: {this.state.relatorio.avaliado.nome_fantasia}</div>
                    </div>
                </div>

                <div className="line">
                    <div className="infos">
                        <Select
                            styles={{ control: (base) => ({ ...base, width: '9rem' }) }}
                            options={IdiomaHelper.getIdiomasSelect()}
                            value={IdiomaHelper.getIdiomasSelect().find(option => option.value === this.state.lang)}
                            onChange={(valueSelected) => { this.setState({ lang: valueSelected.value }, () => { this.loadLang(); IdiomaHelper.setIdioma(valueSelected.value); }) }}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable
                            menuPortalTarget={document.body}
                        />

                        {this.state.relatorio.canPublish &&
                            <DefaultButton
                                title={message[this.state.lang].publish}
                                leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                color={Colors.success}
                                textColor={Colors.white}
                                loading={this.state.loading}
                                onClick={() => { this.publishRelatorio() }}
                            />}
                    </div>
                </div>
            </div>
        );
    }

    renderRecomendacoes() {
        let message = IdiomaHelper.getStruct({
            'pt': {
                title: 'Recomendações',
                add: 'Adicionar Recomendação',
                back: 'Voltar',
                nome: 'Nome',
                selectColuna: 'Selecione a Coluna',
                selectItens: 'Selecione os Itens',
                recomendacaoPlaceholder: 'Escreva sua recomendação',
                adicionar: 'Adicionar',
                addPa: 'Plano Ação',
                addPaTooltip: 'Criar Plano de Ação vinculado com Recomendação',
                delete: 'Excluir',
                switchTable: 'Visualizar em Tabela',
                switchPanel: 'Visualizar em Painel',
            },
            'en': {
                title: 'Recommendations',
                add: 'Add Recommendation',
                back: 'Back',
                nome: 'Name',
                selectColuna: 'Select Column',
                selectItens: 'Select Items',
                recomendacaoPlaceholder: 'Write your recommendation',
                adicionar: 'Add',
                addPa: 'Action Plan',
                addPaTooltip: 'Create Action Plan linked to Recommendation',
                delete: 'Delete',
                switchTable: 'View in Table',
                switchPanel: 'View in Panel',
            },
            'es': {
                title: 'Recomendaciones',
                add: 'Agregar Recomendación',
                back: 'Volver',
                nome: 'Nombre',
                selectColuna: 'Seleccione la Columna',
                selectItens: 'Seleccione los Elementos',
                recomendacaoPlaceholder: 'Escribe tu recomendación',
                adicionar: 'Agregar',
                addPa: 'Plan de Acción',
                addPaTooltip: 'Crear un Plan de Acción vinculado a la Recomendación',
                delete: 'Eliminar',
                switchTable: 'Ver en Tabla',
                switchPanel: 'Ver en Panel',
            }
        });

        const renderRecomendacoes = () => {

            if (!this.state.relatorio.recomendacoes || this.state.relatorio.recomendacoes.length == 0) return <></>
            return (
                <div className="contentRecomendacoes">
                    <Form style={{ width: '100%', padding: '0px 0px 0px 10px' }}>
                        <Row >
                            {this.state.relatorio.recomendacoes.map((recomendacao, index) => {
                                return (
                                    <Form.Group key={recomendacao.id} as={Col} md={12 / this.state.ColumnRecomendacoes} style={{ margin: 0, padding: '0px 10px 10px 0px' }}>
                                        <div className="recomendacao" key={recomendacao.id}>

                                            {recomendacao.nodes.tree.map((item, index) => {
                                                return (
                                                    <div key={`item-tree-${item.id}`} style={{ fontWeight: 'bold', marginLeft: `${index * 15}px` }}><FontAwesomeIcon icon={faChevronRight} style={{ margin: '0 5px' }} />{item.descricao}</div>
                                                )
                                            })}

                                            <div className={"descricao"}>
                                                <div dangerouslySetInnerHTML={{ __html: recomendacao.descricao }} />
                                            </div>
                                            <div className="actions">
                                                {this.state.relatorio.canEdit &&
                                                    <DefaultButton
                                                        title={message[this.state.lang].delete}
                                                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                                        color={Colors.error}
                                                        textColor={Colors.white}
                                                        loading={this.state.loading}
                                                        onClick={() => { this.deleteRecomendacao(recomendacao.id) }}
                                                    />
                                                }

                                                {this.state.relatorio.canCreatePlanoAcao &&
                                                    <DefaultButton
                                                        title={message[this.state.lang].addPa}
                                                        tooltip={message[this.state.lang].addPaTooltip}
                                                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                                                        color={Colors.success}
                                                        textColor={Colors.white}
                                                        loading={this.state.loading}
                                                        onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/planoAcao?tipo=novo&modulo=8&recomendacao=${recomendacao.id}` }, '*'); }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </Form.Group>
                                )
                            })}
                        </Row>
                    </Form>
                </div>
            )
        }

        const renderAddRecomendacao = () => {
            return (
                <div className="content">
                    <div className="node">
                        <DefaultButton
                            leftIcon={this.state.addingRecomendation ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            tooltip={this.state.addingRecomendation ? message[this.state.lang].back : message[this.state.lang].add}
                            color={Colors.success}
                            onClick={() => { this.setState({ addingRecomendation: this.state.addingRecomendation ? false : true }) }}
                        />

                        {this.state.addingRecomendation &&
                            <Select
                                options={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao')}
                                value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                onChange={(e) => { this.setNodeColuna(e.value) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '18rem', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message[this.state.lang].selectColuna}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }

                        {this.state.addingRecomendation && !this.state.loadAddRecomendacaoSelect &&
                            <Select
                                options={DataHelper.formatSelectData(this.state.nodes, 'id', 'descricao')}
                                value={DataHelper.formatSelectData(this.state.nodes, 'id', 'descricao').find((node) => node.value == this.state.nodeSelected)}
                                onChange={(e) => { this.setState({ nodeSelected: e.value }) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: 'calc(100vw - 18rem - 98px)', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message[this.state.lang].selectItens}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }
                    </div>

                    <div className="description">
                        {this.state.addingRecomendation &&
                            <div style={{ width: '100%' }}>
                                <TextEditor
                                    defaultValue={this.state.description || ""}
                                    onChange={(value) => { this.setState({ description: value }) }}

                                    placeholder={message[this.state.lang].recomendacaoPlaceholder}
                                />
                            </div>
                        }
                    </div>

                    <div className="description" style={{ justifyContent: 'end', marginTop: -15 }}>
                        {this.state.addingRecomendation &&
                            <DefaultButton
                                title={message[this.state.lang].adicionar}
                                color={Colors.success}
                                onClick={() => { this.saveRecomendacao() }}
                            />
                        }
                    </div>
                </div>
            )
        }

        if (this.state.loadRecomendacao) {
            return (
                <div className="recomendacoes" style={{ boxShadow: Colors.boxShadow }}>
                    <div className="titleRecomendacoes">{message[this.state.lang].title}</div>
                    <DefaultLoader />
                </div>
            )
        }

        return (
            <div className="recomendacoes" style={{ boxShadow: Colors.boxShadow }}>
                <div className="titleRecomendacoesLine">
                    <div style={{ width: 102 }}></div>
                    <div className="titleRecomendacoes">
                        {message[this.state.lang].title}
                    </div>
                    <div style={{ width: 102 }}>
                        <DefaultButton tooltip={this.state.view === 'normal' ? message[this.state.lang].switchTable : message[this.state.lang].switchPanel} tooltipPlacement={'left'} leftIcon={this.renderSwitch()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.handleView() }} style={{ marginRight: 8 }} />
                    </div>
                </div>
                {!this.state.loadAddRecomendacao && renderRecomendacoes()}
                {this.state.relatorio.canEdit && !this.state.loadAddRecomendacao && renderAddRecomendacao()}
            </div>
        );
    }

    render() {
        if (this.state.loading) return <LoadingPage />;
        if (this.state.construction) return <UnderConstruction height={'100vh'} />

        return this.renderPage();
    }
}